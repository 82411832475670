import React from "react"

const Car = () => (
  <div style={{
    width: "70px",
    height: "70px",
    marginLeft: "30px",
    marginTop: "40px"
  }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 55"
      width="100%"
      height="100%"
    >
      <path
        fill="#ffffff"
        d="M8 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm9 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM5.04 8.23A7 7 0 0 1 10.94 5H11a8 8 0 0 1 7.9 6.84l.1.16h1a2 2 0 0 1 2 2v3a1 1 0 0 1-1 1h-1a3 3 0 0 0-6 0h-3a3 3 0 0 0-6 0H4a1 1 0 0 1-1-1v-3c0-2.04.77-4.12 2.04-5.77zM16.9 12a6 6 0 0 0-11.82 0h11.82z"
      />
    </svg>
  </div>
)

export default Car
