export function Chevron({ size = 18, className = "" }) {
  return (
    <svg
      fill="#467EFF"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={className}
      strokeWidth="2" /* Adjust the stroke width to make it bolder */
      strokeLinecap="round" /* Optional: Set linecap to round for a smoother look */
      strokeLinejoin="round" /* Optional: Set linejoin to round for a smoother look */
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 10.414 8.5 9l3.5 3.5L15.5 9l1.5 1.414-5 5.086-5-5.086Z"
        fill="#467EFF"
      />
    </svg>
  );
}