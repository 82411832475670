import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Colors } from "@flow/style";

const CheckboxExcluded = ({ isActive, disabled, checked, onClick, option }) => {
  return (
    <CheckboxFrame
      isActive={isActive}
      color={checked ? "red" : undefined}
      onClick={isActive && !disabled ? () => onClick({
        ...option,
        isRequired: false,
        isExcluded: true
      }) : () => {}}
    >
      <InnerCheckbox color={checked ? Colors.Ferrari : undefined}>
        <div style={{ width: 4, height: 4, borderRadius: 2, backgroundColor: disabled ? "grey" : "white" }} />
      </InnerCheckbox>
    </CheckboxFrame>
  );
};

CheckboxExcluded.propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  checked: PropTypes.bool,
  id: PropTypes.number,
  price: PropTypes.number,
  basePrice: PropTypes.number,
  name: PropTypes.string,
  excludes: PropTypes.array,
  includes: PropTypes.array,
  requires: PropTypes.array
};

CheckboxExcluded.defaultProps = {
  onClick: null,
  isActive: false,
  checked: false,
  id: 0,
  price: 0,
  basePrice: 0,
  name: "",
  excludes: [],
  includes: [],
  requires: []
};

export default CheckboxExcluded;

const InnerCheckbox = styled.div`
  height: 14px;
  width: 14px;
  background-color: ${props => (props.color ? props.color : "white")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-out;
`;

const CheckboxFrame = styled.div`
  height: 16px;
  width: 16px;
  border: ${props => (props.isActive ? `2px solid ${Colors.Ferrari}` : `2px solid ${Colors.Grey3}`)};
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-out;
`;
