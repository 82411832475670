import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "@flow/style";
import { Accessories, Body, DisplayCarConfig, Engine, Make, Model, NavigationMenu } from "./index";
import ContextBasic from "../car-configuration/components/ContextBasic";
import RadioSlide from "./components/RadioSlide"

export const stepMap = {
  VEHICLE_TYPE: 1,
  CAR_MAKE: 2,
  CAR_MODEL: 3,
  CAR_BODY: 4,
  CAR_ENGINE: 5,
  CAR_ACCESSORIES: 6
}

export const stepMapDescription = {
  1: "Type",
  2: "Merke",
  3: "Modell",
  4: "Karosseri",
  5: "Motor",
  6: "Tilleggsutstyr"
}

const initialCarConfig = {
  make: "",
  model: "",
  body: "",
  bodyName: "",
  vehicleUid: "",
  engineName: "",
  carYear: "",
  drivenWheels: "",
  numberOfDoors: "",
  accessoriesList: [],
  imageUrl: "",
  imageSendUrl: "",
  vehicleType: "",
  fuelType: "",
  firstRegistrationFee: "",
  vatPrice: "",
  carFilterName: "",
  step: 1
}

const { featureToggles } = window.stacc_env
const bodyCommonOptionsFilteringKeysArray = featureToggles.filter(f => f.startsWith("filterKeys") && f.split(":")[1] === "body").map(f => {
  const splat = f.split(":")
  if (splat.length > 2) {
    return splat[2]
  }
  return null
}).filter(nonNull => nonNull)

export default ({ carFilterId, data, createCarFilterHandler, saveCarFilterHandler }) => {
  const existingConfiguration = data ? data.configuration : null
  const label = data ? data.label : ""

  const [carConfig, carConfigSet] = useState(existingConfiguration ? existingConfiguration : initialCarConfig)
  const [carFilterName, carFilterNameSet] = useState(label)
  const [isGenericFilter, isGenericFilterSet] = useState(existingConfiguration ? existingConfiguration.isGenericFilter || false : false)
  const [optionsOnlyByChassis, optionsOnlyByChassisSet] = useState(false)

  const getEmptyJatoCacheModel = () => ({
    makes: [],
    models: [],
    bodies: [],
    vehicles: [],
    vehiclesbuilder: []
  })

  const [step, stepSet] = useState(existingConfiguration ? existingConfiguration.step : 1)
  const [totalPrice, totalPriceSet] = useState(existingConfiguration ? existingConfiguration.totalPrice : 0)
  const [accessoriesPrice, accessoriesPriceSet] = useState(existingConfiguration ? existingConfiguration.accessoriesPrice : 0)
  const [retailPrice, retailPriceSet] = useState(existingConfiguration ? existingConfiguration.retailPrice : 0)
  const [basePrice, basePriceSet] = useState(existingConfiguration ? existingConfiguration.basePrice : 0)
  const [jatoData, jatoDataSet] = useState(getEmptyJatoCacheModel())
  const [co2NotFound, co2NotFoundSet] = useState(existingConfiguration ? existingConfiguration.co2NotFound : 0)
  const [disabledCompleteButton, disabledCompleteButtonSet] = useState(false)

  const onSelectVehicleType = (type) => {
    carConfigSet({ ...carConfig, vehicleType: type })
    stepSet(stepMap["CAR_MAKE"])
    jatoDataSet(getEmptyJatoCacheModel())
  }

  const getCurrentComponent = () => {
    switch (step) {
      case 1:
        return (
          <SelectVehicleType>
            <RadioSlide
              key={'toggle-generic-filter'}
              toggle={() =>
                isGenericFilterSet(
                  !isGenericFilter
                )
              }
              isActive={isGenericFilter}
            >
              <span>Hent utstyr basert på alle tilgjengelige motorer for et valgt karosseri?</span>
            </RadioSlide>
            <div>
            <VehicleButton onClick={() => onSelectVehicleType("car")}>Personbil</VehicleButton>
            <VehicleButton
              onClick={() => onSelectVehicleType("lightCommercialVehicle")}>Nyttekjøretøy</VehicleButton>
            </div>
          </SelectVehicleType>
        );
      case 2:
        return (
          <Make
            onClick={data => setMake(data)}
            cacheJatoData={({ jatoData, vehicleType }) => {
              jatoDataSet(jatoData)
              carConfigSet({ ...carConfig, vehicleType })
            }}
            jatoData={jatoData}
            vehicleType={carConfig.vehicleType}
          />
        );
      case 3:
        return (
          <Model
            carConfig={carConfig}
            onSelectModel={setModel}
            cacheJatoData={({ jatoData, vehicleType }) => {
              jatoDataSet(jatoData)
              carConfigSet({ ...carConfig, vehicleType })
            }}
            jatoData={jatoData}
            vehicleType={carConfig.vehicleType}
          />
        );
      case 4:
        return (
          <Body
            carConfig={carConfig}
            onSelectBody={setBody}
            cacheJatoData={({ jatoData, vehicleType }) => {
              jatoDataSet(jatoData)
              carConfigSet({ ...carConfig, vehicleType })
            }}
            jatoData={jatoData}
            vehicleType={carConfig.vehicleType}
            isGenericFilter={isGenericFilter}
          />
        );
      case 5:
        return (
          <Engine
            carConfig={carConfig}
            onSelectEngine={setEngine}
            cacheJatoData={({ jatoData, vehicleType }) => {
              jatoDataSet(jatoData)
              carConfigSet({ ...carConfig, vehicleType })
            }}
            jatoData={jatoData}
            vehicleType={carConfig.vehicleType}
          />
        );
      case (6 || (5 && isGenericFilter)):
        return (
          <Accessories
            carConfig={carConfig}
            onSelectAccessories={setAccessories}
            onSetStart={setStart}
            updateCo2={updateCo2}
            co2NotFound={co2NotFound}
            isGenericFilter={isGenericFilter}
            optionsOnlyByChassis={optionsOnlyByChassis}
          />
        );
      default:
        break;
    }
  }

  const setStart = () => {
    stepSet(stepMap["VEHICLE_TYPE"])
    carConfigSet(initialCarConfig)
    updatePrice(0, 0, 0)
    jatoDataSet(getEmptyJatoCacheModel())
  }

  const setVehicleType = (vehicleType) => {
    stepSet(stepMap["CAR_MAKE"])
    carConfigSet(prevState => ({
      ...prevState,
      ...initialCarConfig,
      vehicleType,
      make: "",
      model: "",
      body: "",
      bodyName: "",
      vehicleId: null,
      vehicleUid: "",
      engineName: "",
      carYear: "",
      drivenWheels: "",
      numberOfDoors: "",
      accessoriesList: [],
      imageUrl: "",
      fuelType: "",
      basePrice: 0,
      accessoriesRetailPrice: 0,
      totalPrice: 0,
      step
    }))
    jatoDataSet({ ...jatoData, models: [], bodies: [], vehicles: [], vehiclesbuilder: [] })
    updatePrice(0, 0, 0)
  }

  const setMake = (make, vehicleId) => {
    stepSet(stepMap["CAR_MODEL"])
    carConfigSet(prevState => ({
      ...prevState,
      make: make,
      model: "",
      body: "",
      bodyName: "",
      vehicleId,
      vehicleUid: "",
      engineName: "",
      carYear: "",
      drivenWheels: "",
      numberOfDoors: "",
      accessoriesList: [],
      imageUrl: "",
      fuelType: "",
      step
    }))
    jatoDataSet({ ...jatoData, bodies: [], vehicles: [], vehiclesbuilder: [] })
    updatePrice(0, 0, 0)
  }

  const setModel = (model, year, vehicleId) => {
    stepSet(stepMap["CAR_BODY"])
    carConfigSet(prevState => ({
      ...prevState,
      model: model,
      carYear: year,
      body: "",
      bodyName: "",
      vehicleId,
      vehicleUid: "",
      engineName: "",
      drivenWheels: "",
      numberOfDoors: "",
      accessoriesList: [],
      imageUrl: "",
      fuelType: "",
      step
    }))
    jatoDataSet({ ...jatoData, vehicles: [], vehiclesbuilder: [] })
    updatePrice(0, 0, 0)
  }

  const setBody = (bodyCode, bodyName, numberOfDoors, drivenWheels, imageUrl, vehicleId) => {
    const stepToSet = isGenericFilter ? stepMap["CAR_ACCESSORIES"] : stepMap["CAR_ENGINE"]
    stepSet(stepToSet)
    carConfigSet(prevState => ({
      ...prevState,
      body: bodyCode,
      bodyName: bodyName,
      numberOfDoors,
      drivenWheels,
      imageUrl,
      vehicleId,
      vehicleUid: "",
      engineName: "",
      accessoriesList: [],
      fuelType: "",
      step: stepToSet
    }))
    jatoDataSet({ ...jatoData, vehiclesbuilder: [] })
    updatePrice(0, 0, 0)
  }

  const setEngine = (vehicleId, vehicleUid, engineName, price, fuelType, basePrice, transmission) => {
    stepSet(stepMap["CAR_ACCESSORIES"])
    carConfigSet(prevState => ({
      ...prevState,
      vehicleId,
      vehicleUid,
      engineName: engineName,
      transmission: transmission,
      accessoriesList: [],
      fuelType,
      co2Emission: undefined,
      step
    }))
    jatoDataSet({ ...jatoData, vehiclesbuilder: [] })
    co2NotFoundSet(false)

    updatePrice(price, undefined, basePrice);
  }

  const setAccessories = (totalPrice, list, co2Emission, mainServiceDistKm, mainServicePeriodMonth, interServiceDistKm, interServicePeriodMonth, noxEmission, fuelConsumption, imageSendUrl, firstRegistrationFee, vatPrice) => {
    carConfigSet(prevState => ({
      ...prevState,
      accessoriesList: list,
      co2Emission,
      mainServiceDistKm,
      mainServicePeriodMonth,
      interServiceDistKm,
      interServicePeriodMonth,
      noxEmission,
      fuelConsumption,
      imageUrl: imageSendUrl,
      imageSendUrl,
      firstRegistrationFee,
      vatPrice,
      step
    }))
    updatePrice(undefined, totalPrice)
  }

  const handleSaveCarFilter = () => {
    // must at a minimum select vehicle type for it to be a valid filter
    const isValidFilter = step !== stepMap["VEHICLE_TYPE"]

    const payload = {
      ...carConfig,
      accessoriesList: carConfig.accessoriesList.map(acc => ({
        optionName: acc.optionName,
        optionId: acc.optionId,
        isRequired: acc.isRequired || false,
        isExcluded: 'isExcluded' in acc ? acc.isExcluded : false,
        includes: acc.includes,
        excludes: acc.excludes,
        optionTypeName: acc.optionTypeName,
        translatedCategoryName: acc.translatedCategoryName,
        ...(isGenericFilter ?
            bodyCommonOptionsFilteringKeysArray.reduce((filterProps, curr) => ({ ...filterProps, [curr]: acc[curr] }), {})
          : {}
        )
      })),
      accessoriesPrice: accessoriesPrice,
      totalPrice: totalPrice,
      retailPrice: retailPrice,
      isValidFilter,
      step,
      ...(isGenericFilter ? {
        isGenericFilter,
        filteredByKeys: bodyCommonOptionsFilteringKeysArray
      } : {})
    }

    saveCarFilterHandler({ label: carFilterName, configuration: payload, id: carFilterId, type: data.type })
    createCarFilterHandler({})
  }

  /**
   * This function update the state of co2Emission to be 0 when the checkbox in Accessories.js is being triggered.
   * Core expects data to be type of number, hence the co2Emission must be 0 and not null or undefined.
   * The param is a boolean, when false the completeButton "Overfør data to Core" is disabled.
   * The param must be true in order to complete the transfer of data.
   * @param {*} ignoreWarningMessage update Co2 function with param ignoreWarning message and send data to Core anyways.
   */
  const updateCo2 = (ignoreWarningMessage) => {
    carConfigSet({ ...carConfig, co2Emission: 0 })
    disabledCompleteButtonSet(!ignoreWarningMessage)
  }

  const updatePrice = (
    price = retailPrice,
    accPrice = accessoriesPrice,
    bPrice = basePrice
  ) => {
    let parsedPrice = price
    if (!(typeof price === "number")) {
      const filterNumbers = /[0-9]/g;

      const priceWithOnlyNumbers = price.match(filterNumbers);
      const priceString = priceWithOnlyNumbers.join();
      parsedPrice = parseInt(priceString.replace(/,/g, ""));
    }

    totalPriceSet(parsedPrice + accPrice)
    retailPriceSet(parsedPrice)
    accessoriesPriceSet(accPrice)
    basePriceSet(bPrice)
  }

  let currentComponent = getCurrentComponent()

  const accessories = carConfig && carConfig.accessoriesList && carConfig.accessoriesList;

  return (
    <Wrapper className="modalFrame">
      <Left>
        <NavigationMenu
          step={step}
          onTypeClick={() => setStart()}
          onMakeClick={() => setVehicleType(carConfig.vehicleType)}
          onModelClick={() => setMake(carConfig.make)}
          onBodyClick={() => setModel(carConfig.model, carConfig.carYear)}
          onEngineClick={() =>
            setBody(
              carConfig.body,
              carConfig.bodyName,
              carConfig.numberOfDoors,
              carConfig.drivenWheels,
              carConfig.imageUrl
            )
          }
        />
        {currentComponent}
      </Left>
      <Right>
        <InfoArea>
          {isGenericFilter && <span>Dette filteret er generisk.</span>}
          {isGenericFilter && <RadioSlide
            toggle={() => {
              optionsOnlyByChassisSet(!optionsOnlyByChassis)
            }}
            isActive={optionsOnlyByChassis}
          >
            Hent basert på bare karosseri?
          </RadioSlide>}
          <DisplayCarConfig
            make={carConfig.make}
            model={carConfig.model}
            year={carConfig.carYear}
            engine={carConfig.engineName}
            body={carConfig.bodyName}
            accessories={accessories}
          />
        </InfoArea>
        <PictureArea>
          {carConfig.imageUrl ? <img src={carConfig.imageUrl} alt="Car"/> : <div/>}
        </PictureArea>
        <PriceArea>
          <ContextBasic title="Grunnpris bil" value={retailPrice + " kr"} size="small"
                        input={false}/>
          <ContextBasic
            title="Tilleggsutstyr"
            value={accessoriesPrice + " kr"}
            size="small"
            input={false}
          />
          <ContextBasic title="Totalpris" value={totalPrice + " kr"} size="big" input={false}/>
        </PriceArea>
        <ButtonArea>
          <label htmlFor="filter-name">
            <LabelText>Filternavn</LabelText>
            <StyledInput
              name="filter-name"
              type="text"
              placeholder="Beskrivelse av bilfilter"
              onChange={e => carFilterNameSet(e.target.value)}
              value={carFilterName}
            />
          </label>
          <ButtonGroup>
            <SaveButton onClick={() => handleSaveCarFilter()}>{carFilterId ? "Oppdater bilfilter" : "Lagre bilfilter"}</SaveButton>
            <CancelButton onClick={() => createCarFilterHandler({})}>Avbryt</CancelButton>
          </ButtonGroup>
        </ButtonArea>
      </Right>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const Left = styled.div`
  width: 70%;
  overflow-y: auto;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  border-left: 1px solid ${Colors.Grey3};
`;

const SelectVehicleType = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;
  height: calc(100% - 115px);
`;

const InfoArea = styled.div`
  height: auto;
  padding-top: 20px;
  margin-top: 30px;
  padding-left: 30px;
  flex-grow: 2;
  overflow: auto;
  border-bottom: 1px solid ${Colors.Grey3};
`;

const PictureArea = styled.div`
  display: flex;
  justify-content: center;
  margin: 10%;

  img {
    max-width: 90%;
  }
`;

const PriceArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 15px;
  display: flex;
`;

const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.Grey4};
  border-top: 1px solid ${Colors.Grey3};
  padding: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

export const DefaultButton = styled.button`
  width: 120px;
  height: 40px;
  line-height: 15px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  margin: 0px 4px 0px 4px;
  padding: 0px 10px 0px 10px;
`;

const SaveButton = styled(DefaultButton)`
  color: ${Colors.Sea};

  &:hover  {
    cursor: pointer;
  }

  background-color: ${Colors.Grey4};
  border: 2px solid ${Colors.SeaLight};
`;

const CancelButton = styled(DefaultButton)`
  color: ${Colors.redtone2};
  background-color: ${Colors.Grey4};

  &:hover  {
    cursor: pointer;
  }

  border: 2px solid rgb(212, 66, 53, 0.3);
`;

const VehicleButton = styled(DefaultButton)`
  color: ${Colors.Sea};
  width: 130px;
  background-color: ${Colors.Grey4};
  border: 2px solid ${Colors.SeaLight};
  cursor: pointer;

  &:hover {
    background-color: ${Colors.SeaLight};
  }
`;

const StyledInput = styled.input`
  height: 36px;
  width: 100%;
  border: 2px solid ${Colors.Grey3};
  border-radius: 4px;
  font-size: 14px;
`


const LabelText = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.Grey1};
`
