import React, { useEffect } from "react";
import axios from "axios";

function getUrlVars() {
  var vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
    vars[key] = value;
  });
  return vars;
}

function getUrlParam(parameter) {
  var urlparameter = undefined;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  return urlparameter;
}

const CustomRouteCreateNewCarConfiguration = ({ user }) => {
  const createProcess = async () => {
    const inboxQueueUrl = "/inbox/car-configuration/car-configuration";
    const { data } = await axios.get("api/flow/flows?flowDefinitionId=car-configuration&&status=active", {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      }
    });

    let activeFlow = [];
    if (data.length > 0) {
      activeFlow = data.filter(el => el.data.budgetId === getUrlParam("budgetNo"));
    }

    if (activeFlow.length > 0) {
      //send user to allready existing flow
      const flowId = activeFlow[0].flowId;
      window.location.href = `${inboxQueueUrl}/${flowId}`;
    } else {
      let processBody = {};
      const budgetNo = getUrlParam("budgetNo");
      let vehicleUid = getUrlParam("vehicleUid");
      if (vehicleUid) {
        vehicleUid = parseInt(vehicleUid);
        processBody = {
          budgetId: budgetNo,
          carConfiguration: {
            vehicleUid: vehicleUid,
          },
        };
      } else {
        processBody = {
          budgetId: budgetNo,
        };
      }

      const res = await axios
        .post("/api/flow/flow-definitions/car-configuration", processBody)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          return error;
        });
      window.location.href = `${inboxQueueUrl}/${res.flowId}`;
    }
  };

  useEffect(() => {
    if (getUrlParam("budgetNo") !== undefined) {
      createProcess();
    }
  }, []);

  return <div />;
};

export default CustomRouteCreateNewCarConfiguration;
