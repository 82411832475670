import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getMatrixModels, getModels } from "./requests";
import { Spinner } from "@flow/icons";
import { Header, HeaderItem, SelectionBox } from "./Styles";
import ModelRow from "./components/ModelRow";
import { LoadingBox } from "./components/LoadingBox";

const getVehicleType = t => {
  switch (t.toUpperCase()) {
    case "VARE<3500":
      return "LIGHTCOMMERCIALVEHICLE"
    case "PERSONBIL":
      return "CAR"
    default:
      return ""
  }
}

export default ({ jatoData, vehicleType, carConfig, cacheJatoData, onSelectModel }) => {
  const [_, vehicleTypeSet] = useState("")
  const [models, modelsSet] = useState([])
  const [uniqueModelTypes, uniqueModelTypesSet] = useState([])
  const [uniqueModelYears, uniqueModelYearsSet] = useState([])
  const [selectedFilter, selectedFilterSet] = useState({
    models: [],
    year: [],
    priceLow: 0,
    priceHigh: 10000000
  })
  const [loading, loadingSet] = useState(true)

  useEffect(() => {
    if (jatoData.models && !jatoData.models.length > 0) {
      getModels(carConfig.make, vehicleType, async models => {
        // Filter out models not available in restverdimatrise
        const matrixModels = await getMatrixModels(carConfig.make);
        models = models.filter(model => matrixModels.some(({ Model, VehicleCategory }) => {
          const type = getVehicleType(VehicleCategory);
          return (Model.toUpperCase() === model.modelKey.toUpperCase() && (!type || type === vehicleType.toUpperCase()))
        }))

        const modelTypes = models.map(el => el.modelNameToDisplay);
        const uniqueModelTypes = Array.from(new Set(modelTypes));

        const modelYears = models.map(el => el.modelYear);
        const uniqueModelYears = Array.from(new Set(modelYears));

        modelsSet(models)
        uniqueModelTypesSet(uniqueModelTypes)
        uniqueModelYearsSet(uniqueModelYears)
        loadingSet(false)
        vehicleTypeSet(vehicleType)
        cacheJatoData({ jatoData: { ...jatoData, models }, vehicleType })
      })
    } else {
      const models = jatoData.models;
      const modelTypes = models.map(el => el.modelNameToDisplay);
      const uniqueModelTypes = Array.from(new Set(modelTypes));

      const modelYears = models.map(el => el.modelYear);
      const uniqueModelYears = Array.from(new Set(modelYears));

      modelsSet(models)
      uniqueModelTypesSet(uniqueModelTypes)
      uniqueModelYearsSet(uniqueModelYears)
      loadingSet(false)
    }
  }, [])

  const updateFilter = (type, value) => {
    let sFilter = {};
    switch (type) {
      case "models":
        if (value === "Alle") {
          sFilter = { ...selectedFilter, models: [] };
        } else if (selectedFilter.models.some(el => el === value)) {
          sFilter = { ...selectedFilter, models: selectedFilter.models.filter(el => el !== value) };
        } else {
          sFilter = { ...selectedFilter, models: [...selectedFilter.models, value] };
        }
        break;
      case "year":
        if (value === "Alle") {
          sFilter = { ...selectedFilter, year: [] };
        } else if (selectedFilter.year.some(el => el === value)) {
          sFilter = { ...selectedFilter, year: selectedFilter.year.filter(el => el !== value) };
        } else {
          sFilter = { ...selectedFilter, year: [...selectedFilter.year, value] };
        }
        break;
      case "priceLow":
        if (value === "") {
          sFilter = { ...selectedFilter, priceLow: 0 };
        } else {
          sFilter = { ...selectedFilter, priceLow: parseInt(value) };
        }
        break;
      case "priceHigh":
        if (value === "") {
          sFilter = { ...selectedFilter, priceHigh: 10000000 };
        } else {
          sFilter = { ...selectedFilter, priceHigh: parseInt(value) };
        }
        break;
    }

    selectedFilterSet(sFilter)
  }

  const modelList = models.reduce((acc, car) => {
    const canBeFromModel =
      selectedFilter.models.length === 0 ||
      selectedFilter.models.some(el => el === car.modelNameToDisplay);
    const canBeFromYear =
      selectedFilter.year.length === 0 || selectedFilter.year.some(el => el === car.modelYear);
    const canBeFromPrice =
      car.minPrice >= selectedFilter.priceLow && car.maxPrice <= selectedFilter.priceHigh;

    if (canBeFromModel && canBeFromYear && canBeFromPrice) {
      return [
        ...acc,
        <ModelRow
          key={car.modelKey}
          model={car.modelNameToDisplay}
          modelKey={car.modelKey}
          imageUrl={car.photoUrl}
          year={car.modelYear}
          priceFrom={car.minPriceToDisplay}
          priceTo={car.maxPriceToDisplay}
          vehicleId={car.vehicleId}
          onClick={onSelectModel}
        />
      ];
    }
    return acc;
  }, []);

  const modelSelectionList = uniqueModelTypes.map(modelType => {
    return (
      <SelectionBox
        key={modelType}
        onClick={() => updateFilter("models", modelType)}
        selected={selectedFilter.models.some(el => el === modelType)}
      >
        {modelType}
      </SelectionBox>
    );
  });

  const yearSelectionList = uniqueModelYears.map(modelYear => {
    return (
      <SelectionBox
        key={modelYear}
        selected={selectedFilter.year.some(el => el === modelYear)}
        onClick={() => updateFilter("year", modelYear)}
      >
        {modelYear}
      </SelectionBox>
    );
  });
  return (
    <ModelSection>
      <PriceAndYearFilter>
        <PriceFilter>
          <PriceFilterLabel>Angi pris</PriceFilterLabel>
          <PriceRangeFilter>
            <PriceRange type="text" placeholder="Fra" onChange={event => updateFilter("priceLow", event.target.value)}/>
            <PriceRange type="text" placeholder="Til"
                        onChange={event => updateFilter("priceHigh", event.target.value)}/>
          </PriceRangeFilter>
        </PriceFilter>
        <YearFilter>
          <YearFilterLabel>Årsmodell</YearFilterLabel>
          <YearSelection>
            <SelectionBox
              selected={selectedFilter.year.length === 0}
              onClick={() => updateFilter("year", "Alle")}
            >
              Alle
            </SelectionBox>
            {yearSelectionList}
          </YearSelection>
        </YearFilter>
      </PriceAndYearFilter>
      <ModelFilter>
        <ModelFilterLabel>Velg modell</ModelFilterLabel>
        <ModelSelection>
          <SelectionBox
            selected={selectedFilter.models.length === 0}
            onClick={() => updateFilter("models", "Alle")}
          >
            Alle
          </SelectionBox>
          {modelSelectionList}
        </ModelSelection>
      </ModelFilter>
      <Header>
        <HeaderItem/>
        <HeaderItem>MODELL</HeaderItem>
        <HeaderItem>ÅRSMODELL</HeaderItem>
        <HeaderItem>PRIS KR</HeaderItem>
      </Header>
      <ModelList loading={loading}>
        {loading ? (
          <LoadingBox>
            <Spinner/>
            <p>Laster bilmodeller fra Jato</p>
          </LoadingBox>
        ) : (
          modelList
        )}
      </ModelList>
    </ModelSection>
  );
}

const PriceRange = styled.input`
  background-color: transparent;
  color: black;
`;

export const ModelSection = styled.div`
  margin-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
`;

export const ModelLabel = styled.div`
  font-size: 12px;
  text-align: left;
  line-height: 16px;
  color: #6c6c6c;
`;

export const ModelList = styled.div`
  margin: 0 10;
  height: 80%;
  display: ${props => (props.loading ? "flex" : "block")};
  flex-direction: ${props => (props.loading ? "row" : "unset")};
  justify-content: ${props => (props.loading ? "center" : "unset")};
  align-items: ${props => (props.loading ? "center" : "baseline")};
`;

export const PriceAndYearFilter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  padding-top: 27px;
  padding-bottom: 20px;
`;

export const PriceFilter = styled.div``;

export const PriceFilterLabel = styled.div`
  text-align: left;
  font-size: 12px;
  color: #6c6c6c;
  padding-bottom: 5px;
`;

export const PriceRangeFilter = styled.div`
  display: grid;
  grid-template-columns: 160px 160px;
  column-gap: 10px;

  input[type="text"] {
    width: 150px;
    padding: 10px;
    border: 1px solid #b8b8b8;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const YearFilter = styled.div``;

export const YearSelection = styled.div`
  display: block;
  text-align: left;
`;

export const YearFilterLabel = styled.div`
  text-align: left;
  font-size: 12px;
  color: #6c6c6c;
  padding-bottom: 5px;
`;

export const ModelFilter = styled.div``;

export const ModelFilterLabel = styled.div`
  text-align: left;
  font-size: 12px;
  color: #6c6c6c;
  padding-bottom: 5px;
`;

export const ModelSelection = styled.div`
  display: inline-block;
  text-align: left;
  padding-bottom: 20px;
`;
