import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Checkbox from "./Checkbox";
import { Colors } from "@flow/style";
import Tooltip from "./Tooltip";

const PacketRow = ({ isActive, selected, id, name, price, basePrice, includes, excludes, requires, onSelect, attributes }) => {
  return (
    <Row isActive={isActive}>
      <Checkbox
        isActive={isActive}
        checked={selected}
        id={id}
        name={name}
        price={price}
        basePrice={basePrice}
        includes={includes}
        excludes={excludes}
        requires={requires}
        attributes={attributes}
        onClick={onSelect}
      />
      <PacketName isActive={isActive}>{name}</PacketName>
      <Tooltip text={attributes} />
      <Price>{price} kr</Price>
    </Row>
  );
};

PacketRow.propTypes = {
  isActive: PropTypes.bool,
  selected: PropTypes.bool,
  id: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.number,
  basePrice: PropTypes.number,
  includes: PropTypes.array,
  excludes: PropTypes.array,
  requires: PropTypes.array,
  onClick: PropTypes.func
};

PacketRow.defaultProps = {
  isActive: false,
  selected: false,
  id: 0,
  name: "",
  price: 0,
  basePrice: 0,
  includes: [],
  excludes: [],
  requires: [],
  onClick: null
};

export default PacketRow;

export const Row = styled.div`
  display: flex;
  margin: 0 10;
  border-bottom: 1px solid #e4e2e2;
  padding: 10px 0px 10px 10px;
  line-height: 22px;
  font-size: 14px;
  cursor: ${props => (props.isActive ? "pointer" : "not-allowed")};
  display: grid;
  grid-template-columns: 25px auto 50px 80px;
`;

export const PacketName = styled.div`
  padding-left: 12px;
  padding-right: 15px;
  text-align: left;
  text-transform: capitalize;
  color: ${props => (props.isActive ? Colors.Grey1 : Colors.Grey3)};
  width: 460px;
`;

export const Price = styled.div`
  flex-grow: 2;
  text-align: right;
`;
