import React from "react";
import styled from "styled-components";
import { Colors } from "@flow/style";

const CheckboxExcluded = ({ checked, onClick, type }) => {
  const color = type === "exclude" ? "#D44235" : "#0600ff"
  const text = type === "exclude" ? "Utilgjengelig" : "Påkrevd"
  return (
    <ButtonFrame
      borderColor={color}
      color={checked ? color : undefined}
      onClick={() => onClick()}
    >
      <StyledButton
        isActive={checked}
      >
        {text}
      </StyledButton>
    </ButtonFrame>
  );
};

export default CheckboxExcluded;

const ButtonFrame = styled.div`
  height: 25px;
  width: auto;
  > button {
    border: ${props => (`2px solid ${props.borderColor}`)};
  }
  background-color: white;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-out;
  font-size: 14px;
`;

const StyledButton = styled.button`
  border: 1px solid ${Colors.Sea};
  border-radius: 4px;
  background: ${Colors.Snow};
  max-width: 90px;
  cursor: pointer;
  opacity: ${props => props.isActive ? 1 : 0.6};;
  background-color: ${props => props.isActive ? `${Colors.SeaLighter}` : "initial"};
`
