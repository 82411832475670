import React, { Component } from "react";
import styled from "styled-components";
import { Spinner } from "@flow/icons";
import { getEngines } from "./Request";
import EngineRow from "./components/EngineRow";
import { SelectionBox, Header, HeaderItem } from "./Styles";
import { LoadingBox } from "./components/LoadingBox";

export default class Engine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicleType: "",
      engines: [],
      fuelTypes: [],
      seatTypes: [],
      transmissionTypes: [],
      filteredList: [],
      selectedFilter: {
        fuel: [],
        transmission: [],
        seat: []
      },
      loading: true
    };

    this.updateFilter = this.updateFilter.bind(this);
  }

  componentDidMount() {
    const { carConfig, jatoData, vehicleType } = this.props;

    if (jatoData.vehicles && !jatoData.vehicles.length > 0 && vehicleType) {
      getEngines(
        carConfig.carMake,
        carConfig.carModel,
        carConfig.carYear,
        carConfig.carBody,
        carConfig.numberOfDoors,
        carConfig.drivenWheels,
        vehicleType,
        (engines) => {
          const fuelTypes = engines.map((el) => el.fuelType);
          const uniqueFuelTypes = Array.from(new Set(fuelTypes));

          const seatTypes = engines.map((el) => el.seats);
          const uniqueSeatTypes = Array.from(new Set(seatTypes));

          const transmissionTypes = engines.map((el) => el.transmissionToDisplay);
          const uniqueTransmissionTypes = Array.from(new Set(transmissionTypes));

          this.setState({
            engines: engines,
            filteredList: engines,
            fuelTypes: uniqueFuelTypes,
            seatTypes: uniqueSeatTypes,
            transmissionTypes: uniqueTransmissionTypes,
            loading: false
          });
          this.props.cacheJatoData({ jatoData: { ...jatoData, vehicles: engines }, vehicleType });
        }
      );
    } else {
      const engines = this.props.jatoData.vehicles;
      this.setState({ engines: engines, filteredList: engines, loading: false });
    }
  }

  updateFilter(type, value) {
    let selectedFilter = {};
    switch (type) {
      case "fuel":
        if (value === "Alle") {
          selectedFilter = { ...this.state.selectedFilter, fuel: [] };
        } else if (this.state.selectedFilter.fuel.some((el) => el === value)) {
          selectedFilter = { ...this.state.selectedFilter, fuel: this.state.selectedFilter.fuel.filter((el) => el !== value) };
        } else {
          selectedFilter = { ...this.state.selectedFilter, fuel: [...this.state.selectedFilter.fuel, value] };
        }
        break;
      case "transmission":
        if (value === "Alle") {
          selectedFilter = { ...this.state.selectedFilter, transmission: [] };
        } else if (this.state.selectedFilter.transmission.some((el) => el === value)) {
          selectedFilter = {
            ...this.state.selectedFilter,
            transmission: this.state.selectedFilter.transmission.filter((el) => el !== value)
          };
        } else {
          selectedFilter = { ...this.state.selectedFilter, transmission: [...this.state.selectedFilter.transmission, value] };
        }
        break;
      case "seat":
        if (value === "Alle") {
          selectedFilter = { ...this.state.selectedFilter, seat: [] };
        } else if (this.state.selectedFilter.seat.some((el) => el === value)) {
          selectedFilter = { ...this.state.selectedFilter, seat: this.state.selectedFilter.seat.filter((el) => el !== value) };
        } else {
          selectedFilter = { ...this.state.selectedFilter, seat: [...this.state.selectedFilter.seat, value] };
        }
        break;
      default:
        selectedFilter = this.state.selectedFilter;
        break;
    }

    this.setState({ selectedFilter });
  }

  render() {
    const engineList = this.state.engines
      .sort((a, b) => a.derivativeLocal.localeCompare(b.derivativeLocal))
      .reduce((acc, engine) => {
        const canBeFromFuel =
          this.state.selectedFilter.fuel.length === 0 || this.state.selectedFilter.fuel.some((el) => el === engine.fuelType);
        const canBeFromGearBox =
          this.state.selectedFilter.transmission.length === 0 ||
          this.state.selectedFilter.transmission.some((el) => el === engine.transmissionToDisplay);
        const canBeFromSeats =
          this.state.selectedFilter.seat.length === 0 || this.state.selectedFilter.seat.some((el) => el === engine.seats);

        if (canBeFromFuel && canBeFromGearBox && canBeFromSeats) {
          let fuelType = engine.fuelType;
          if (engine.secondaryFuelType && engine.secondaryFuelType !== "-")
            fuelType = `${engine.fuelType}${engine.secondaryFuelType}`;
          return [
            ...acc,
            <EngineRow
              key={engine.vehicleUid}
              engineName={engine.derivativeLocal}
              vehicleId={engine.vehicleId}
              vehicleUid={engine.vehicleUid}
              drivenWheels={engine.drivenWheels}
              numberOfDoors={engine.numberOfDoors}
              seats={engine.seats}
              maximumPowerhpPs={engine.maximumPowerhpPs}
              price={engine.priceToDisplay}
              basePrice={engine.basePrice}
              fuelType={fuelType}
              transmission={engine.transmissionToDisplay}
              onClick={this.props.onSelectEngine}
            />
          ];
        }
        return acc;
      }, []);

    const fuelSelectionList = this.state.fuelTypes.map((fuel) => {
      return (
        <SelectionBox
          key={fuel}
          onClick={() => this.updateFilter("fuel", fuel)}
          selected={this.state.selectedFilter.fuel.some((el) => el === fuel)}
        >
          {fuel}
        </SelectionBox>
      );
    });

    const seatSelectionList = this.state.seatTypes.map((seat) => {
      return (
        <SelectionBox
          key={seat}
          onClick={() => this.updateFilter("seat", seat)}
          selected={this.state.selectedFilter.seat.some((el) => el === seat)}
        >
          {seat}
        </SelectionBox>
      );
    });

    const transmissionSelectionList = this.state.transmissionTypes.map((transmission) => {
      return (
        <SelectionBox
          key={transmission}
          onClick={() => this.updateFilter("transmission", transmission)}
          selected={this.state.selectedFilter.transmission.some((el) => el === transmission)}
        >
          {transmission}
        </SelectionBox>
      );
    });

    return (
      <EngineSection>
        <FilterSection>
          <Fuel>
            <EngineLabel>Drivstoff</EngineLabel>
            <EngineSelectionList>
              <SelectionBox
                onClick={() => this.updateFilter("fuel", "Alle")}
                selected={this.state.selectedFilter.fuel.length === 0}
              >
                Alle
              </SelectionBox>
              {fuelSelectionList}
            </EngineSelectionList>
          </Fuel>
          <Transmission>
            <EngineLabel>Girkasse</EngineLabel>
            <EngineSelectionList>
              <SelectionBox
                onClick={() => this.updateFilter("transmission", "Alle")}
                selected={this.state.selectedFilter.transmission.length === 0}
              >
                Alle
              </SelectionBox>
              {transmissionSelectionList}
            </EngineSelectionList>
          </Transmission>
          <Seats>
            <EngineLabel>Seter</EngineLabel>
            <EngineSelectionList>
              <SelectionBox
                onClick={() => this.updateFilter("seat", "Alle")}
                selected={this.state.selectedFilter.seat.length === 0}
              >
                Alle
              </SelectionBox>
              {seatSelectionList}
            </EngineSelectionList>
          </Seats>
        </FilterSection>
        <WideHeader>
          <HeaderItemNoPadding>TYPE</HeaderItemNoPadding>
          <HeaderItemCenter>DRIFT</HeaderItemCenter>
          <HeaderItemCenter>DØRER</HeaderItemCenter>
          <HeaderItemCenter>SETER</HeaderItemCenter>
          <HeaderItemCenter>HK</HeaderItemCenter>
          <HeaderItemCenter>PRIS KR</HeaderItemCenter>
        </WideHeader>
        <EngineList loading={this.state.loading}>
          {this.state.loading ? (
            <LoadingBox>
              <Spinner />
              <p>Laster motorer fra Jato</p>
            </LoadingBox>
          ) : (
            engineList
          )}
        </EngineList>
      </EngineSection>
    );
  }
}

export const EngineLabel = styled.div`
  font-size: 12px;
  text-align: left;
  line-height: 16px;
  color: #6c6c6c;
`;
export const FilterSection = styled.div`
  display: flex;
`;
export const Fuel = styled.div`
  flex-grow: 1;
`;

export const Transmission = styled.div`
  flex-grow: 1;
`;

export const Seats = styled.div`
  flex-grow: 1;
`;

export const EngineSection = styled.div`
  height: calc(100% - 80px);
  margin-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-top: 27px;
  width: -webkit-fill-available;
  width: -moz-available;
`;

export const EngineSelectionList = styled.div`
  text-align: left;
  padding-bottom: 20px;
`;

export const EngineList = styled.div`
  height: calc(100% - 145px);
  display: ${(props) => (props.loading ? "flex" : "block")};
  flex-direction: ${(props) => (props.loading ? "row" : "unset")};
  justify-content: ${(props) => (props.loading ? "center" : "unset")};
  align-items: ${(props) => (props.loading ? "center" : "baseline")};
`;

const WideHeader = styled(Header)`
  grid-template-columns: 300px 50px 50px 50px 100px 100px;
  padding-left: 10px;
`;

const HeaderItemCenter = styled(HeaderItem)`
  text-align: center;
  padding-left: 0px;
`;

const HeaderItemNoPadding = styled(HeaderItem)`
  padding-left: 0px;
`;
