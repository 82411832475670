import React from 'react'
import styled from 'styled-components'

export default ({ children, text }) => (
  <StyledDescription>
    {text}
    {children}
  </StyledDescription>
)

const StyledDescription = styled.p`
  line-height: 1.5em;
  font-size: 16px;
`
