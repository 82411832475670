import React from "react";
import styled, { css } from "styled-components";

const RadioSlide = ({ key, isActive, toggle, children }) => {
  return (
    <RadioSlideWrapepr>
      <RadioLabel htmlFor={key} active={isActive}>
        <RadioInput
          onClick={() => toggle(!isActive)}
          id={key}
          active={isActive}
        />
      </RadioLabel>
      {children}
    </RadioSlideWrapepr>
  );
};

const RadioSlideWrapepr = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`

const RadioInput = styled.input.attrs({ type: 'radio' })`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  border: 1px transparent;
  vertical-align: middle;
  position: relative;
  ${(props) =>
    props.active
      ? css`
          left: 50%;
          background-color: white;
        `
      : css`
          left: 0;
          background-color: grey;
        `};

  transition: 0.3s;
`;

const RadioLabel = styled.label`
  display: inline-block;
  border-radius: 15px;
  width: 70px;
  height: 30px;
  background-color: blue;
  ${(props) =>
    props.active
      ? css`
          background-color: #2a6df4;
          border: 1px solid #2a6df4;
        `
      : css`
          background-color: white;
          border: 1px solid grey;
        `};

  transition: 0.3s ease-in-out;
`;

export default RadioSlide;
