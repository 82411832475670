import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const BrandBox = ({ onClick, make, makeKey, imageUrl, vehicleId }) => {
  return (
    <Box>
      <div onClick={() => onClick(makeKey, vehicleId)}>
        <img src={imageUrl} alt="brandimage" />
      </div>
      <div>{make}</div>
    </Box>
  );
};

BrandBox.propTypes = {
  onClick: PropTypes.func,
  make: PropTypes.string,
  makeKey: PropTypes.string,
  imageUrl: PropTypes.string
};

BrandBox.defaultProps = {
  onClick: null,
  make: "",
  makeKey: "",
  imageUrl: ""
};

export default BrandBox;

export const Box = styled.div`
  width: 122px;
  height: 70px;
  border: 1px solid #b8b8b8;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
