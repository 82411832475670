import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Colors, FontStyles } from "@flow/style";
import formatDate from "../utils/dateFormatter";

const ItemNavLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid ${Colors.Grey3};
  ${FontStyles.Small};
  padding: 10px;

  &.active {
    background-color: ${Colors.SeaLighter};
    pointer-events: none;
  }
`;

const ProcessType = styled.p``;
const Tasks = styled.p`
  padding-top: 5px;
`;

const Title = styled.p`
  ${FontStyles.LargeStrong};
  padding-top: 10px;
`;

const Left = styled.div`
  padding-top: 10px;
`;
const Right = styled.p`
  padding-top: 10px;
`;

const QueueItem = ({ path, flow, t }) => {
  const { flowDefinitionId, flowId, data, flowNumber, createdAt } = flow;
  const { budgetId, signingType = '' } = data;
  const title = budgetId || `${flowDefinitionId.slice(0, 2).toUpperCase()}-${flowNumber}`;
  const createdAtDate = formatDate(new Date(createdAt));

  const signingTypeIfSigningElseEmptyString = flowDefinitionId === "signing" && signingType ? ` - ${t(signingType)}` : ''

  return (
    <ItemNavLink activeClassName="active" to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}>
      <Left>
        <ProcessType>{t(flowDefinitionId)}</ProcessType>
        <Title>{title}{t(signingTypeIfSigningElseEmptyString)}</Title>
      </Left>
      <Right>{createdAtDate}</Right>
    </ItemNavLink>
  );
};

QueueItem.propTypes = {
  path: PropTypes.string.isRequired,
  flow: PropTypes.shape({}),
  t: PropTypes.func.isRequired
};

QueueItem.defaultProps = {
  flow: {}
};

export default QueueItem;
