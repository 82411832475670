import React, { Component } from "react";
import styled from "styled-components";
import { Spinner } from "@flow/icons";
import { getBodies } from "./Request";
import BodyRow from "./components/BodyRow";
import { SelectionBox, Header, HeaderItem } from "./Styles";
import { LoadingBox } from "./components/LoadingBox";

export default class Body extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bodies: [],
      uniqueBodyTypes: [],
      vehicleType: "",
      selectedBody: [],
      loading: true
    };

    this.updateFilter = this.updateFilter.bind(this);
  }

  componentDidMount() {
    const { carConfig, jatoData, vehicleType } = this.props;

    if (jatoData.bodies && !jatoData.bodies.length > 0) {
      getBodies(carConfig.carMake, carConfig.carModel, carConfig.carYear, vehicleType, bodies => {
        const bodyTypes = bodies.map(body => body.shortBodyName);
        const uniqueBodyTypes = Array.from(new Set(bodyTypes));

        this.setState({ bodies: bodies, uniqueBodyTypes, loading: false, vehicleType });
        this.props.cacheJatoData({ jatoData: { ...jatoData, bodies }, vehicleType });
      });
    } else {
      const bodies = jatoData.bodies;
      const bodyTypes = bodies.map(body => body.shortBodyName);
      const uniqueBodyTypes = Array.from(new Set(bodyTypes));

      this.setState({ bodies: bodies, uniqueBodyTypes, loading: false });
    }
  }

  updateFilter(filter) {
    let selectedFilter = [];
    if (filter === "Alle") {
      selectedFilter = [];
    } else if (this.state.selectedBody.some(el => el === filter)) {
      selectedFilter = this.state.selectedBody.filter(el => el !== filter);
    } else {
      selectedFilter = [...this.state.selectedBody, filter];
    }

    this.setState({ selectedBody: selectedFilter });
  }

  render() {
    const bodyList = this.state.bodies.reduce((acc, body) => {
      const canBeFromBody = this.state.selectedBody.length === 0 || this.state.selectedBody.some(el => el === body.shortBodyName);

      if (canBeFromBody) {
        return [
          ...acc,
          <BodyRow
            key={body.bodyCode + "-" + body.drivenWheels}
            bodyName={body.shortBodyName}
            body={body}
            imageUrl={body.photoUrl}
            year={body.modelYear}
            drivenWheels={body.drivenWheels}
            numberOfDoors={body.numberOfDoors}
            priceFrom={body.minPriceToDisplay}
            priceTo={body.maxPriceToDisplay}
            onClick={this.props.onSelectBody}
          />
        ];
      }
      return acc;
    }, []);

    const bodyTypeSelectionList = this.state.uniqueBodyTypes.map(bodyType => {
      return (
        <SelectionBox
          key={bodyType}
          onClick={() => this.updateFilter(bodyType)}
          selected={this.state.selectedBody.some(el => el === bodyType)}
        >
          {bodyType}
        </SelectionBox>
      );
    });
    return (
      <BodySection>
        <BodyLabel>Vis type</BodyLabel>
        <BodyFilters>
          <SelectionBox onClick={() => this.updateFilter("Alle")} selected={this.state.selectedBody.length === 0}>
            Alle
          </SelectionBox>
          {bodyTypeSelectionList}
        </BodyFilters>
        <BodyHeader>
          <HeaderItem />
          <HeaderItem>MODELL</HeaderItem>
          <HeaderItem>ÅRSMODELL</HeaderItem>
          <HeaderItem>DRIFT</HeaderItem>
          <HeaderItem>DØRER</HeaderItem>
          <HeaderItem>PRIS KR</HeaderItem>
        </BodyHeader>
        <BodyList loading={this.state.loading}>
          {this.state.loading ? (
            <LoadingBox>
              <Spinner />
              <p>Laster karosserityper fra Jato</p>
            </LoadingBox>
          ) : (
              bodyList
            )}
        </BodyList>
      </BodySection>
    );
  }
}

export const BodyLabel = styled.div`
  font-size: 12px;
  text-align: left;
  line-height: 16px;
  color: #6c6c6c;
`;

export const BodySection = styled.div`
  height: calc(100% - 80px);
  margin-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-top: 27px;
  width: -webkit-fill-available;
  width: -moz-available;
`;

export const BodyFilters = styled.div`
  text-align: left;
  padding-bottom: 20px;
`;

export const BodyList = styled.div`
  height: calc(100% - 145px);
  display: ${props => (props.loading ? "flex" : "block")};
  flex-direction: ${props => (props.loading ? "row" : "unset")};
  justify-content: ${props => (props.loading ? "center" : "unset")};
  align-items: ${props => (props.loading ? "center" : "baseline")};
`;

export const BodyHeader = styled(Header)`
  grid-template-columns: 136px 150px 100px 100px 100px 1fr;
`;
