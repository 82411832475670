import React, { Component } from "react";
import styled from "styled-components";
import { Colors } from "@flow/style";
import { Make, Model, Body, Engine, Accessories, NavigationMenu, DisplayCarConfig } from "./car-configuration";
import ContextBasic from "./car-configuration/components/ContextBasic";
import StandardEquipment from "./car-configuration/StandardEquipment";

export default class CreateCarConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showStandardEquipment: false,
      vehicleTypeSelected: false,
      steps: [false, false, false, false, false, false],
      carConfig: {
        carMake: "",
        carModel: "",
        carBody: "",
        carBodyName: "",
        vehicleUid: "",
        carEngineName: "",
        carYear: "",
        drivenWheels: "",
        numberOfDoors: "",
        accessoriesList: [],
        imageUrl: "",
        imageSendUrl: "",
        vehicleType: "",
        fuelType: "",
        firstRegistrationFee: "",
        vatPrice: "",
        chargable: "",
        EUClass: "",
      },
      prices: { retailPrice: 0, sumAccessoriesRetailPrice: 0, totalPrice: 0 },
      jatoData: this.getEmptyJatoCacheModel(),
      co2NotFound: false,
      disableCompleteButton: false
    };

    this.setStandardEquipment = this.setStandardEquipment.bind(this);
    this.renderCurrentCompoent = this.renderCurrentCompoent.bind(this);
    this.setStart = this.setStart.bind(this);
    this.setMake = this.setMake.bind(this);
    this.setModel = this.setModel.bind(this);
    this.setBody = this.setBody.bind(this);
    this.setEngine = this.setEngine.bind(this);
    this.setAccessories = this.setAccessories.bind(this);
    this.updateCo2 = this.updateCo2.bind(this);
  }

  calculateCurrentStep(carConfig) {
    let steps = [false, false, false, false, false, false];
    if (carConfig.vehicleType === "car" || carConfig.vehicleType === "lightCommercialVehicle") {
      steps = [true, false, false, false, false, false];
    }
    if (carConfig.carMake !== "") {
      steps = [true, true, false, false, false, false];
    }
    if (carConfig.carModel !== "") {
      steps = [true, true, true, false, false, false];
    }
    if (carConfig.carBody !== "") {
      steps = [true, true, true, true, false, false];
    }
    if (carConfig.vehicleUid !== "") {
      steps = [true, true, true, true, true, false];
    }
    if (carConfig.accessoriesList && carConfig.accessoriesList.length > 0) {
      steps = [true, true, true, true, true, true];
    }
    return steps;
  }

  getEmptyJatoCacheModel() {
    return {
      makes: [],
      models: [],
      bodies: [],
      vehicles: [],
      vehiclesbuilder: []
    };
  }

  setStandardEquipment(StandardEquipment) {
    this.setState({ standardEquipment: StandardEquipment })
  }


  componentDidMount() {
    let { carConfig } = this.state;
    if (this.props.task && this.props.task.data) {
      carConfig = this.props.task.data;
    } else if (this.props.flow && this.props.flow.data && this.props.flow.data.carConfiguration) {
      carConfig = this.props.flow.data.carConfiguration;
    }

    const steps = this.calculateCurrentStep(carConfig);

    const vType = carConfig.vehicleType;
    const vehicleTypeSelected = vType === "car" || vType === "lightCommercialVehicle";

    let totalPrice = 0;
    const basePrice = carConfig.basePrice ? carConfig.basePrice : 0;
    const retailPrice = carConfig.retailPrice ? carConfig.retailPrice : 0;
    const sumAccessoriesRetailPrice = carConfig.sumAccessoriesRetailPrice ? carConfig.sumAccessoriesRetailPrice : 0;
    if (retailPrice !== 0 || sumAccessoriesRetailPrice !== 0) {
      totalPrice = retailPrice + sumAccessoriesRetailPrice;
    }

    this.setState({
      carConfig,
      vehicleTypeSelected,
      prices: {
        retailPrice,
        basePrice,
        sumAccessoriesRetailPrice,
        totalPrice
      },
      steps
    });
  }

  onSelectVehicleType(type) {
    this.setState({
      carConfig: { ...this.state.carConfig, vehicleType: type },
      vehicleTypeSelected: true,
      steps: [true, false, false, false, false, false],
      jatoData: this.getEmptyJatoCacheModel()
    });
  }

  renderCurrentCompoent() {
    if (this.state.steps[0] === false) {
      return (
        <SelectVehicleType>
          <VehicleButton onClick={() => this.onSelectVehicleType("car")}>Personbil</VehicleButton>
          <VehicleButton onClick={() => this.onSelectVehicleType("lightCommercialVehicle")}>Nyttekjøretøy</VehicleButton>
        </SelectVehicleType>
      );
    } else if (this.state.steps[0] === true && this.state.steps[1] === false) {
      return (
        <Make
          onClick={data => this.setMake(data)}
          cacheJatoData={({ jatoData, vehicleType }) =>
            this.setState({ jatoData, carConfig: { ...this.state.carConfig, vehicleType } })
          }
          jatoData={this.state.jatoData}
          vehicleType={this.state.carConfig.vehicleType}
        />
      );
    } else if (this.state.steps[0] === true && this.state.steps[1] === true && this.state.steps[2] === false) {
      return (
        <Model
          carConfig={this.state.carConfig}
          onSelectModel={this.setModel}
          cacheJatoData={({ jatoData, vehicleType }) =>
            this.setState({ jatoData, carConfig: { ...this.state.carConfig, vehicleType } })
          }
          jatoData={this.state.jatoData}
          vehicleType={this.state.carConfig.vehicleType}
        />
      );
    } else if (
      this.state.steps[0] === true &&
      this.state.steps[1] === true &&
      this.state.steps[2] === true &&
      this.state.steps[3] === false
    ) {
      return (
        <Body
          carConfig={this.state.carConfig}
          onSelectBody={this.setBody}
          cacheJatoData={({ jatoData, vehicleType }) =>
            this.setState({ jatoData, carConfig: { ...this.state.carConfig, vehicleType } })
          }
          jatoData={this.state.jatoData}
          vehicleType={this.state.carConfig.vehicleType}
        />
      );
    } else if (
      this.state.steps[0] === true &&
      this.state.steps[1] === true &&
      this.state.steps[2] === true &&
      this.state.steps[3] === true &&
      this.state.steps[4] === false
    ) {
      return (
        <Engine
          carConfig={this.state.carConfig}
          onSelectEngine={this.setEngine}
          cacheJatoData={({ jatoData, vehicleType }) =>
            this.setState({ jatoData, carConfig: { ...this.state.carConfig, vehicleType } })
          }
          jatoData={this.state.jatoData}
          vehicleType={this.state.carConfig.vehicleType}
        />
      );
    } else if (
      this.state.steps[0] === true &&
      this.state.steps[1] === true &&
      this.state.steps[2] === true &&
      this.state.steps[3] === true &&
      this.state.steps[4] === true
    ) {
      return (
        <Accessories carConfig={this.state.carConfig} setStandardEquipment={this.setStandardEquipment} onSelectAccessories={this.setAccessories} onSetStart={this.setStart} updateCo2={this.updateCo2} co2NotFound={this.state.co2NotFound} />
      );
    }
  }

  setStart() {
    this.setState({
      steps: [false, false, false, false, false, false],
      carConfig: {
        carMake: "",
        carModel: "",
        carBody: "",
        carBodyName: "",
        vehicleUid: "",
        carEngineName: "",
        carYear: "",
        drivenWheels: "",
        numberOfDoors: "",
        accessoriesList: [],
        imageUrl: "",
        fuelType: ""
      },
      prices: { retailPrice: 0, basePrice: 0, sumAccessoriesRetailPrice: 0, totalPrice: 0 },
      jatoData: this.getEmptyJatoCacheModel()
    });
  }

  setVehicleType(vehicleType) {
    this.setState({
      steps: [true, false, false, false, false, false],
      carConfig: {
        carMake: "",
        carModel: "",
        carBody: "",
        carBodyName: "",
        vehicleUid: "",
        carEngineName: "",
        carYear: "",
        drivenWheels: "",
        numberOfDoors: "",
        accessoriesList: [],
        imageUrl: "",
        fuelType: "",
        vehicleType
      },
      prices: { retailPrice: 0, basePrice: 0, sumAccessoriesRetailPrice: 0, totalPrice: 0 },
      jatoData: {
        ...this.state.jatoData,
        models: [],
        bodies: [],
        vehicles: [],
        vehiclesbuilder: []
      }
    });
    this.updatePrice(0, 0, 0);
  }

  setMake(make) {
    let newCarConfig = {
      ...this.state.carConfig,
      carMake: make,
      carModel: "",
      carBody: "",
      carBodyName: "",
      vehicleUid: "",
      carEngineName: "",
      carYear: "",
      drivenWheels: "",
      numberOfDoors: "",
      accessoriesList: [],
      imageUrl: "",
      fuelType: ""
    };

    this.setState({
      carConfig: newCarConfig,
      steps: [true, true, false, false, false, false],
      jatoData: {
        ...this.state.jatoData,
        bodies: [],
        vehicles: [],
        vehiclesbuilder: []
      }
    });
    this.updatePrice(0, 0, 0);
  }

  setModel(model, year) {
    let newCarConfig = {
      ...this.state.carConfig,
      carModel: model,
      carYear: year,
      carBody: "",
      carBodyName: "",
      vehicleUid: "",
      carEngineName: "",
      drivenWheels: "",
      numberOfDoors: "",
      accessoriesList: [],
      imageUrl: "",
      fuelType: ""
    };
    this.setState({
      carConfig: newCarConfig,
      steps: [true, true, true, false, false, false],
      jatoData: {
        ...this.state.jatoData,
        vehicles: [],
        vehiclesbuilder: []
      }
    });
    this.updatePrice(0, 0, 0);
  }

  setBody(bodyCode, bodyName, numberOfDoors, drivenWheels, imageUrl) {
    const newCarConfig = {
      ...this.state.carConfig,
      carBody: bodyCode,
      carBodyName: bodyName,
      numberOfDoors: numberOfDoors,
      drivenWheels: drivenWheels,
      imageUrl: imageUrl,
      vehicleUid: "",
      carEngineName: "",
      accessoriesList: [],
      fuelType: ""
    };
    this.setState({
      carConfig: newCarConfig,
      steps: [true, true, true, true, false, false],
      jatoData: {
        ...this.state.jatoData,
        vehiclesbuilder: []
      }
    });
    this.updatePrice(0, 0, 0);
  }

  setEngine(vehicleId, vehicleUid, engineName, price, fuelType, basePrice, transmission) {
    const newCarConfig = {
      ...this.state.carConfig,
      vehicleId,
      vehicleUid,
      carEngineName: engineName,
      transmission: transmission,
      accessoriesList: [],
      fuelType,
      co2Emission: undefined
    };

    this.setState({ carConfig: newCarConfig, steps: [true, true, true, true, true, false], co2NotFound: false });
    this.updatePrice(price, undefined, basePrice);
  }

  setAccessories(totalPrice, list, co2Emission, mainServiceDistKm, mainServicePeriodMonth, interServiceDistKm, interServicePeriodMonth, noxEmission, fuelConsumption, imageSendUrl, firstRegistrationFee, vatPrice, chargable, EUClass) {
    const newCarConfig = {
      ...this.state.carConfig,
      accessoriesList: list,
      co2Emission,
      mainServiceDistKm,
      mainServicePeriodMonth,
      interServiceDistKm,
      interServicePeriodMonth,
      noxEmission,
      fuelConsumption,
      imageUrl: imageSendUrl,
      imageSendUrl,
      firstRegistrationFee,
      vatPrice,
      chargable,
      EUClass,
    };
    this.setState({ carConfig: newCarConfig });
    this.updatePrice(undefined, totalPrice);
  }

  onSaveTask() {
    const { carConfig, prices } = this.state;
    this.props.onSave(
      this.props.task.taskId,
      {
        ...carConfig,
        sumAccessoriesRetailPrice: prices.sumAccessoriesRetailPrice,
        basePrice: prices.basePrice,
        retailPrice: prices.retailPrice
      },
      () => {
        this.props.updateCase();
        this.props.close();
      }
    );
  }

  /**
   * This function update the state of co2Emission to be 0 when the checkbox in Accessories.js is being triggered.
   * Core expects data to be type of number, hence the co2Emission must be 0 and not null or undefined.
   * The param is a boolean, when false the completeButton "Overfør data to Core" is disabled.
   * The param must be true in order to complete the transfer of data.
   * @param {*} ignoreWarningMessage update Co2 function with param ignoreWarning message and send data to Core anyways.
   */
  updateCo2(ignoreWarningMessage) {
    this.setState({ carConfig: { ...this.state.carConfig, co2Emission: 0 }, disableCompleteButton: !ignoreWarningMessage });
  }

  onCompleteTask() {
    const { carConfig, prices, standardEquipment } = this.state;
    const { co2Emission, accessoriesList } = carConfig;

    if (co2Emission === undefined || co2Emission === null) {
      return this.setState({ co2NotFound: true, disableCompleteButton: true });
    }


    const optionsList = standardEquipment.map(el => {
      const name = el.content

      if (name.length > 240) {
        let lastWhiteSpaceIndex = name.substring(0, 240).lastIndexOf(" ");
        const name1 = name.substring(0, lastWhiteSpaceIndex);
        const name2 = name.substring(lastWhiteSpaceIndex, name.length)
        accessoriesList.push({
          optionId: `${el.schemaId}-1`,
          price: "0",
          name: name1,
          basePrice: "0",
          accessoriesWithoutDiscount: "FALSE",
          standardEquipment: "TRUE"
        },
          {
            optionId: `${el.schemaId}-2`,
            price: "0",
            name: name2,
            basePrice: "0",
            accessoriesWithoutDiscount: "FALSE",
            standardEquipment: "TRUE"
          }
        )
      } else {
        accessoriesList.push({
          optionId: el.schemaId,
          price: "0",
          name: name,
          basePrice: "0",
          accessoriesWithoutDiscount: "FALSE",
          standardEquipment: "TRUE"
        })
      }
    });


    this.setState({ steps: [true, true, true, true, true, true], co2NotFound: false });
    this.props.onComplete(
      this.props.task.taskId,
      {
        ...carConfig,
        sumAccessoriesRetailPrice: prices.sumAccessoriesRetailPrice,
        basePrice: prices.basePrice,
        retailPrice: prices.retailPrice
      },
      () => {
        this.props.updateCase();
        this.props.close();
      }
    );
  }

  updatePrice(
    price = this.state.prices.retailPrice,
    sumAccessoriesRetailPrice = this.state.prices.sumAccessoriesRetailPrice,
    basePrice = this.state.prices.basePrice
  ) {
    let parsedPrice = price;
    if (!(typeof price === "number")) {
      const filterNumbers = /[0-9]/g;

      const priceWithOnlyNumbers = price.match(filterNumbers);
      const priceString = priceWithOnlyNumbers.join();
      parsedPrice = parseInt(priceString.replace(/,/g, ""));
    }
    let total = parsedPrice + sumAccessoriesRetailPrice;

    this.setState({
      prices: {
        retailPrice: parsedPrice,
        basePrice: basePrice,
        totalPrice: total,
        sumAccessoriesRetailPrice: sumAccessoriesRetailPrice
      }
    });
  }

  // Function used to toggle standard accessories window.
  toggleStandardEquipmentWindows() {
    this.setState({ showStandardEquipment: !this.state.showStandardEquipment })
  }

  render() {

    let currentComponent = this.renderCurrentCompoent();
    const { carConfig } = this.state;
    const { showStandardEquipment } = this.state;

    const accessories = carConfig && carConfig.accessoriesList && carConfig.accessoriesList.map(a => a.name);

    if (showStandardEquipment) {
      return (
        <>
          <BackButton onClick={(e) => this.toggleStandardEquipmentWindows()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g stroke="#467EFF" stroke-width="2" fill="none"><path d="M17 7l-10 10M7 7l10 10"></path></g></svg>
          </BackButton>
          <StandardEquipment standardEquipment={this.state.standardEquipment} />
        </>
      )
    } else {
      return (
        <ModalContentFrame className="modalFrame">
          <ModalLeft>
            <NavigationMenu
              steps={this.state.steps}
              onTypeClick={() => this.setStart()}
              onMakeClick={() => this.setVehicleType(this.state.carConfig.vehicleType)}
              onModelClick={() => this.setMake(this.state.carConfig.carMake)}
              onBodyClick={() => this.setModel(this.state.carConfig.carModel, this.state.carConfig.carYear)}
              onEngineClick={() =>
                this.setBody(
                  this.state.carConfig.carBody,
                  this.state.carConfig.carBodyName,
                  this.state.carConfig.numberOfDoors,
                  this.state.carConfig.drivenWheels,
                  this.state.carConfig.imageUrl
                )
              }
            />
            {currentComponent}
          </ModalLeft>
          <ModalRight>
            <InfoArea>
              <StandardEquipmentButton
                disabled={carConfig.vehicleUid === ""}
                onClick={(e) => this.setState({ showStandardEquipment: !this.showStandardEquipment })}>
                Se Standardutstyr
              </StandardEquipmentButton>
              <DisplayCarConfig
                make={carConfig.carMake}
                model={carConfig.carModel}
                year={carConfig.carYear}
                engine={carConfig.carEngineName}
                body={carConfig.carBodyName}
                accessories={accessories}
              />
            </InfoArea>
            <PictureArea>
              {this.state.carConfig.imageUrl ? <img src={this.state.carConfig.imageUrl} alt="Car" /> : <div />}
            </PictureArea>
            <PriceArea>
              <ContextBasic title="Grunnpris bil" value={this.state.prices.retailPrice + " kr"} size="small" input={false} />
              <ContextBasic
                title="Tilleggsutstyr"
                value={this.state.prices.sumAccessoriesRetailPrice + " kr"}
                size="small"
                input={false}
              />
              <ContextBasic title="Totalpris" value={this.state.prices.totalPrice + " kr"} size="big" input={false} />
            </PriceArea>
            <ButtonArea>
              <ButtonGroup>
                <SaveButton onClick={() => this.onSaveTask()}>Lagre</SaveButton>
                <CancelButton onClick={this.props.close}>Avbryt</CancelButton>
              </ButtonGroup>
              {this.state.steps[4] === true ? (
                <ButtonGroup>
                  <CompleteButton disabled={this.state.disableCompleteButton} onClick={() => this.onCompleteTask()}>Overfør bil til Core</CompleteButton>
                </ButtonGroup>
              ) : null}
            </ButtonArea>
          </ModalRight>
        </ModalContentFrame>
      );
    }
  }
}

const ModalContentFrame = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const ModalLeft = styled.div`
  width: 70%;
  overflow-y: auto;
`;

const ModalRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  border-left: 1px solid ${Colors.Grey3};
`;

const SelectVehicleType = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(100% - 115px);
`;

const InfoArea = styled.div`
  height: auto;
  padding-top: 20px;
  margin-top: 30px;
  padding-left: 30px;
  flex-grow: 2;
  overflow: auto;
  border-bottom: 1px solid ${Colors.Grey3};
`;

const PictureArea = styled.div`
  display: flex;
  justify-content: center;
  margin: 10%;

  img {
    max-width: 90%;
  }
`;

const PriceArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 15px;
  display: flex;
`;

const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.Grey4};
  border-top: 1px solid ${Colors.Grey3};
  padding: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

const DefaultButton = styled.button`
  width: 120px;
  height: 40px;
  line-height: 15px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  margin: 0px 4px 0px 4px;
  padding: 0px 10px 0px 10px;
`;

const StandardEquipmentButton = styled(DefaultButton)`
  color: ${Colors.Sea};
  &:hover {
    cursor: pointer;
  }
  background-color: ${Colors.Snow};
  border: 2px solid ${Colors.SeaLight};
  width: 40%;
  font-weight: 600;
  border-radius: 4px;
  margin: 0px 4px 40px 4px;
  padding: 0px 0px 0px 0px;
  display: ${props => props.disabled ? "none" : "block"};
`;

const BackButton = styled(DefaultButton)`
  position: absolute;
  top: 15px;
  right: 0;
  border: none;
  width: 67px;
  background: none;
`;

const SaveButton = styled(DefaultButton)`
  color: ${Colors.Sea};
  &:hover  {
    cursor: pointer;
  }
  background-color: ${Colors.Grey4};
  border: 2px solid ${Colors.SeaLight};
`;

const CompleteButton = styled(DefaultButton)`
  color: ${Colors.Snow};
  &:hover {
    cursor: pointer;
  }
  background-color: ${Colors.Sea};
  width: 240px;
  &:disabled {
    background-color: ${Colors.Grey1};
  }
`;

const CancelButton = styled(DefaultButton)`
  color: ${Colors.redtone2};
  background-color: ${Colors.Grey4};
  &:hover  {
    cursor: pointer;
  }
  border: 2px solid rgb(212, 66, 53, 0.3);
`;

const VehicleButton = styled(DefaultButton)`
  color: ${Colors.Sea};
  width: 130px;
  background-color: ${Colors.Grey4};
  border: 2px solid ${Colors.SeaLight};
  cursor: pointer;

  &:hover {
    background-color: ${Colors.SeaLight};
  }
`;
