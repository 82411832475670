import styled from "styled-components";

const LoadingBox = styled.div`
  /* Tooltip container */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  p {
    padding-top: 15px;
  }
`;

export { LoadingBox };
