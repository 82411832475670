import React, { useEffect, useState } from "react";
import styled from "styled-components";
import lodash from "lodash";
import { getGenericOptionsByBody, getVehiclesBuilder } from "./requests"
import { Colors } from "@flow/style";
import { Spinner } from "@flow/icons";
import PacketRow from "./components/PacketRow";
import OptionsRow from "./components/OptionsRow";
import { SelectionBox } from "./Styles";
import { LoadingBox } from "./components/LoadingBox";
import { Checkbox } from "@flow/forms";
import SelectAll from "./components/SelectAll";

export default ({ carConfig, onSelectAccessories, updateCo2, co2NotFound, onSetStart, isGenericFilter,
                  optionsOnlyByChassis }) => {
  const [state, stateSet] = useState({
    accessories: { packages: [], options: {} },
    excludeList: [],
    selectedOptions: [],
    accessoriesPrice: 0,
    optionGroups: [],
    packetGroups: [],
    selectedFilter: {
      options: [],
      packets: []
    },
    requires: {},
    optionNames: {},
    loading: true,
    co2Emission: 0,
    mainServiceDistKm: 0,
    mainServicePeriodMonth: 0,
    interServiceDistKm: 0,
    interServicePeriodMonth: 0,
    noxEmission: 0,
    fuelConsumption: 0,
    imageUrl: "",
    firstRegistrationFee: 0,
    vatPrice: 0,
    carNotFound: false,
    accessoriesNotFound: false,
    warningCheck: false,
  })

  const { vehicleId, accessoriesList, vehicleType } = carConfig;

  // todo find a way to set initial states to these
  const [allRequiredCheckedSelections, allRequiredCheckedSelectionsSet] = useState([])
  const [allExcludedCheckedSelections, allExcludedCheckedSelectionsSet] = useState([])

  useEffect(() => {
    const { vehicleId, accessoriesList, vehicleType } = carConfig
    getAccessoriesFromJato(vehicleId, vehicleType, accessoriesList)
  }, [])

  const parseOptions = accessories => {
    if (accessories === null) {
      return stateSet({ ...state, loading: false, accessoriesNotFound: true })
    }

    if (Object.keys(accessories).length === 0) {
      return stateSet({ ...state, carNotfound: true, loading: false, accessoriesNotFound: false })
    }

    const { options, packages, vehiclePerformance, vehiclePhotos, vehicleEbrochurePage } = accessories;
    let optionGroups = [];
    let packetGroups = [];
    let optionNames = {};

    lodash.forEach(options, (option, key) => {
      optionGroups = [...optionGroups, key];
      option.forEach(opt => {
        optionNames[opt.optionId] = opt.optionName;
      });
    });

    const uniquePacket = lodash.uniqBy(packages, packet => packet.categoryName);

    lodash.forEach(uniquePacket, packet => {
      packetGroups = [...packetGroups, packet.categoryName];
      optionNames[packet.optionId] = packet.optionName;
    });

    let co2Emission = null;
    let mainServiceDistKm = 0;
    let mainServicePeriodMonth = 0;
    let interServiceDistKm = 0;
    let interServicePeriodMonth = 0;
    let noxEmission = 0;
    let fuelConsumption = 0;
    let firstRegistrationFee = 0;
    let vatPrice = 0;
    let imageUrl = "";

    if (vehicleEbrochurePage) {
      const { pageItem } = vehicleEbrochurePage;
      const co2Item = pageItem.find(item => item.schemaId === 62203 && typeof item.dataValue === "string" && item.dataValue.length > 0);
      const mainServiceDistKmItem = pageItem.find(item => item.schemaId === 38802 && item.dataValue.length > 0);
      const mainServicePeriodMonthItem = pageItem.find(item => item.schemaId === 38803 && item.dataValue.length > 0);
      const interServiceDistKmItem = pageItem.find(item => item.schemaId === 38702 && item.dataValue.length > 0);
      const interServicePeriodMonthItem = pageItem.find(item => item.schemaId === 38703 && item.dataValue.length > 0);

      if (co2Item) {
        co2Emission = Number(co2Item.dataValue);


      }
      if (mainServiceDistKmItem) {
        mainServiceDistKm = Number(mainServiceDistKmItem.dataValue);
      }
      if (mainServicePeriodMonthItem) {
        mainServicePeriodMonth = Number(mainServicePeriodMonthItem.dataValue);
      }
      if (interServiceDistKmItem) {
        interServiceDistKm = Number(interServiceDistKmItem.dataValue);
      }
      if (interServicePeriodMonthItem) {
        interServicePeriodMonth = Number(interServicePeriodMonthItem.dataValue);
      }
    }

    if (vehiclePerformance) {
      noxEmission = vehiclePerformance.noxEmission;
      // co2Emission = vehiclePerformance.co2LevelgKm7603Value;
      fuelConsumption = vehiclePerformance.fuelConsumptionKm42005Value === null ? 0 : vehiclePerformance.fuelConsumptionKm42005Value;
      firstRegistrationFee = vehiclePerformance.firstRegistrationFee;
      vatPrice = vehiclePerformance.vatPrice;
    }
    if (vehiclePhotos) imageUrl = vehiclePhotos[0];

    if (!accessoriesList || accessoriesList.length < 1) {
      onSelectAccessories(
        0,
        [],
        co2Emission,
        mainServiceDistKm,
        mainServicePeriodMonth,
        interServiceDistKm,
        interServicePeriodMonth,
        noxEmission,
        fuelConsumption,
        imageUrl,
        firstRegistrationFee,
        vatPrice
      );
      return stateSet(prevState => ({
        ...prevState,
        accessories,
        optionGroups,
        packetGroups,
        optionNames,
        loading: false,
        co2Emission,
        mainServiceDistKm,
        mainServicePeriodMonth,
        interServiceDistKm,
        interServicePeriodMonth,
        noxEmission,
        fuelConsumption,
        imageUrl,
        firstRegistrationFee,
        vatPrice,
        accessoriesNotFound: false
      }))
    }

    const optionsDuplicate = lodash.reject(lodash.cloneDeep(options), lodash.isEmpty);
    //const mergedList = packages.concat(optionsDuplicate);

    let newList = [];
    let totalPrice = 0;
    packages.forEach(packet => {
      if (Array.isArray(packet)) {
        const optionAccessory = accessoriesList.filter(acc => packet.some(el => acc.optionId === el.optionId))
        if (optionAccessory.length > 0) {
          lodash.forEach(optionAccessory, o => {
            totalPrice += o.retailPrice902 || o.price;
            newList = [...newList, o];
          });
        }
      } else {
        const found = accessoriesList.find(acc => (acc.optionId ? parseInt(acc.optionId) === packet.optionId : acc === packet.optionId))
        if (!found) {
          return
        }

        totalPrice += found.retailPrice902 || found.price;
        newList = [...newList, found];

        //Add elements in attributes if it is not spesifed in includes
        let extraOptions = [];
        if (packet.attributes && packet.attributes.length > 0) {
          let modifiedAttributes = packet.attributes.filter(atName => !atName.includes("Includes") && !atName.includes("Inkluderer") && !atName.includes("And") && !atName.includes("Og"));

          //Removed requires/krever and the elements below
          //This is a bad solution since we can't be sure it will always come last in the list
          if (modifiedAttributes.includes("Requires:#-#Krever:")) {
            const requiresIndex = modifiedAttributes.indexOf("Requires:#-#Krever:");
            modifiedAttributes = modifiedAttributes.slice(0, requiresIndex);
          }

          //Split name if length is longer than 240 characters
          modifiedAttributes.forEach((atName, key) => {
            if (atName.length > 240) {
              let lastWhiteSpaceIndex = atName.substring(0, 240).lastIndexOf(" ");
              modifiedAttributes[key] = atName.substring(0, lastWhiteSpaceIndex);
              modifiedAttributes.splice(key + 1, 0, atName.substring(lastWhiteSpaceIndex, atName.length));
            }
          });

          const foundOption = findOptionAnywhere(packet.optionId)
          if (foundOption && foundOption.includes.length > 0) {
            modifiedAttributes.forEach((atName, key) => {
              extraOptions.push(...modifiedAttributes.includes.map(o => findOptionAnywhere(o)))
            });
          }
        }
        if (extraOptions.length > 0) newList = newList.concat(extraOptions);
      }
    });

    optionsDuplicate.forEach(option => {
      if (Array.isArray(option)) {
        const optionAccessory = accessoriesList.filter(acc => option.find(el => (acc.optionId ? parseInt(acc.optionId) === el.optionId : acc === el.optionId)))
        if (optionAccessory.length >= 1) {
          lodash.forEach(optionAccessory, o => {
            totalPrice += o.retailPrice902 || o.price;
            newList = [...newList, o];
          });
        }
      } else {
        const found = accessoriesList.find(acc => (acc.optionId ? parseInt(acc.optionId) === option.optionId : acc === option.optionId))
        if (found) {
          totalPrice += found.retailPrice902 || found.price;
          newList = [...newList, option];
        }
      }
    });

    const excludeList = newList.map(el => !el.isExcluded ? el.excludes : []).flat();
    const optionsList = newList.map(el => ({
      ...el,
      accessoriesWithoutDiscount: el.optionName.toUpperCase().includes("LOY") ? "TRUE" : "FALSE"
    }));

    onSelectAccessories(
      totalPrice,
      optionsList,
      co2Emission,
      mainServiceDistKm,
      mainServicePeriodMonth,
      interServiceDistKm,
      interServicePeriodMonth,
      noxEmission,
      fuelConsumption,
      imageUrl,
      firstRegistrationFee,
      vatPrice
    );
    stateSet(prevState => ({
      ...prevState,
      accessories,
      selectedOptions: newList,
      excludeList,
      accessoriesPrice: totalPrice,
      optionGroups,
      packetGroups,
      optionNames,
      loading: false,
      co2Emission,
      mainServiceDistKm,
      mainServicePeriodMonth,
      interServiceDistKm,
      interServicePeriodMonth,
      noxEmission,
      fuelConsumption,
      imageUrl,
      firstRegistrationFee,
      vatPrice,
      accessoriesNotFound: false
    }))
  }

  const getAccessoriesFromJato = (vehicleId, vehicleType, accessoriesList) => {
    stateSet({ ...state, loading: true })
    if (isGenericFilter) {
      getGenericOptionsByBody({
        body: carConfig.body,
        doors: carConfig.numberOfDoors,
        drivenWheels: carConfig.drivenWheels,
        make: carConfig.make,
        model: carConfig.model,
        modelYear: carConfig.carYear,
        vehicleType,
        bodyVehicleId: carConfig.vehicleId,
        settings: {
          fetchBaseVehicleInfo: true,
          fetchOnlyByBaseVehicle: optionsOnlyByChassis
        }
      })
        .then(accessories => parseOptions(accessories))
        .catch(err => stateSet({ ...state, loading: false }))
    } else {
      getVehiclesBuilder(vehicleId, vehicleType)
        .then(accessories => parseOptions(accessories))
        .catch(err => stateSet({ ...state, loading: false }))
    }
  }

  useEffect(() => {
    if (state.loading) {
      return
    }

    stateSet({ ...state, loading: true })
    const isGeneric = {
      body: carConfig.body,
      doors: carConfig.numberOfDoors,
      drivenWheels: carConfig.drivenWheels,
      make: carConfig.make,
      model: carConfig.model,
      modelYear: carConfig.carYear,
      vehicleType,
      bodyVehicleId: carConfig.vehicleId,
    }
    if (isGenericFilter) {
      getGenericOptionsByBody({ ...isGeneric, settings: {
          fetchOnlyByBaseVehicle: optionsOnlyByChassis,
          fetchBaseVehicleInfo: true
        }})
        .then(accessories => parseOptions(accessories))
        .catch(err => stateSet({ ...state, loading: false }))
    } else {
      getVehiclesBuilder(vehicleId, vehicleType)
        .then(accessories => parseOptions(accessories))
        .catch(err => stateSet({ ...state, loading: false }))
    }
  }, [optionsOnlyByChassis])

  const updateFilter = (type, value) => {
    let sFilter = {};
    switch (type) {
      case "option":
        if (value === "Alle") {
          sFilter = { ...state.selectedFilter, options: [] };
        } else if (state.selectedFilter.options.some(el => el === value)) {
          sFilter = {
            ...state.selectedFilter,
            options: state.selectedFilter.options.filter(el => el !== value)
          };
        } else {
          sFilter = { ...state.selectedFilter, options: [...state.selectedFilter.options, value] };
        }
        break;
      case "packet":
        if (value === "Alle") {
          sFilter = { ...state.selectedFilter, packets: [] };
        } else if (state.selectedFilter.packets.some(el => el === value)) {
          sFilter = {
            ...state.selectedFilter,
            packets: state.selectedFilter.packets.filter(el => el !== value)
          };
        } else {
          sFilter = { ...state.selectedFilter, packets: [...state.selectedFilter.packets, value] };
        }
        break;
      default:
        sFilter = state.selectedFilter;
        break;
    }

    stateSet({
      ...state,
      selectedFilter: sFilter
    })
  }

  const findOptionAnywhere = id => {
    const foundOption = Object.keys(state.accessories.options).reduce((acc, key) => {
      const found = state.accessories.options[key].filter(acc => acc.optionId === id)
      acc.push(...found)
      return acc
    }, [])
    if (foundOption.length === 0) {
      const foundPackage = state.accessories.packages.filter(acc => acc.optionId === id)
      if (foundPackage.length > 0) {
        return foundPackage[0]
      }
    } else {
      return foundOption[0]
    }
  }

  const onSelectAll = ({ type, optionsType, optionsCategory }) => {
    let newOptionsList;

    const isRequireSelected = allRequiredCheckedSelections.find(o => o === optionsCategory)
    const isExcludeSelected = allExcludedCheckedSelections.find(o => o === optionsCategory)

    const getRelevantOptions = () => {
      if (optionsType === "packages") {
        return state.accessories.packages
      }
      if (optionsType === "options" && optionsCategory) {
        return state.accessories.options[optionsCategory]
      }
    }

    if (type === "require" && !isRequireSelected) {
      newOptionsList = [
        ...getRelevantOptions()
          .filter(f => !state.selectedOptions.some(o => f.optionId === o.optionId))
          .map(el => ({
            ...el,
            isRequired: true,
            accessoriesWithoutDiscount: el.optionName.toUpperCase().includes("LOY") ? "TRUE" : "FALSE"
          })),
        ...state.selectedOptions
      ]
      allRequiredCheckedSelectionsSet([...allRequiredCheckedSelections, optionsCategory || optionsType])
    } else if (type === "require" && isRequireSelected) {
      newOptionsList = state.selectedOptions.filter(o => getRelevantOptions().find(o2 => o.optionId === o2.optionId) ? !o.isRequired : true)
      allRequiredCheckedSelectionsSet(allRequiredCheckedSelections.filter(s => s !== isRequireSelected))
    } else if (type === "exclude" && !isExcludeSelected) {
      newOptionsList = [
        ...getRelevantOptions()
          .filter(f => !state.selectedOptions.some(o => f.optionId === o.optionId))
          .map(el => ({
            ...el,
            isExcluded: true,
            accessoriesWithoutDiscount: el.optionName.toUpperCase().includes("LOY") ? "TRUE" : "FALSE"
          })),
        ...state.selectedOptions
      ]
      allExcludedCheckedSelectionsSet([...allExcludedCheckedSelections, optionsCategory || optionsType])
    } else if (type === "exclude" && isExcludeSelected) {
      newOptionsList = state.selectedOptions.filter(o => getRelevantOptions().find(o2 => o.optionId === o2.optionId) ? !o.isExcluded : true)
      allExcludedCheckedSelectionsSet(allExcludedCheckedSelections.filter(s => s !== isExcludeSelected))
    }

    const totalPrice = newOptionsList.filter(opt => !opt.isExcluded).reduce((acc, next) => acc + (next.retailPrice902 || 0), 0)

    onSelectAccessories(
      totalPrice,
      newOptionsList,
      state.co2Emission,
      state.mainServiceDistKm,
      state.mainServicePeriodMonth,
      state.interServiceDistKm,
      state.interServicePeriodMonth,
      state.noxEmission,
      state.fuelConsumption,
      state.imageUrl,
      state.firstRegistrationFee,
      state.vatPrice
    )
    stateSet(prevState => ({
      ...prevState,
      selectedOptions: newOptionsList,
      accessoriesPrice: totalPrice
    }))
  }

  const onSelectOption = ({
    optionId,
    retailPrice902,
    optionName,
    excludes,
    includes = [],
    requires = [],
    basePrice903,
    attributes = [],
    isRequired,
    isExcluded,
    optionTypeName,
    translatedCategoryName,
  }) => {
    let newList;

    const selectedOption = { optionId, retailPrice902, basePrice903, optionName, excludes, includes, requires, isRequired, isExcluded, optionTypeName, translatedCategoryName };

    if (state.selectedOptions.some(el => el.optionId === optionId)) {
      newList = state.selectedOptions.filter(el => el.optionId !== optionId);
      //Remove elements that were in the attributes
      newList = newList.filter(acc => !includes.includes(acc.optionId))

      if (state.requires[optionId]) {
        delete state.requires[optionId];
      }

      Object.keys(state.requires).forEach(key => {
        state.requires[key].requires.forEach(required => {
          if (required.isIncluded) {
            let isStillIncluded = false;
            required.requireOne.forEach(requiredOption => {
              if (requiredOption.isIncluded) {
                if (optionId === requiredOption.optionId) {
                  requiredOption.isIncluded = false;
                } else {
                  isStillIncluded = true;
                }
              }
            });

            if (!isStillIncluded) {
              required.isIncluded = false;
            }
          }
        });
      });
    } else {
      newList = [...state.selectedOptions, selectedOption];

      if (!isExcluded) {
        const modifiedAttributes = findOptionAnywhere(optionId)
        if (modifiedAttributes.includes.length > 0) {
          newList.push(...modifiedAttributes.includes.map(o => findOptionAnywhere(o)))
        }
      }

      const newRequires = [];
      requires.forEach(required => {
        const newRequired = { requireOne: [], isIncluded: false };
        required.optionId.forEach(requiredOption => {
          const newRequiredOption = {
            optionId: requiredOption,
            isIncluded: false
          };

          newRequired.requireOne.push(newRequiredOption);
          state.selectedOptions.some(selectedOption => {
            if (selectedOption.optionId === requiredOption) {
              newRequired.isIncluded = true;
              newRequiredOption.isIncluded = true;
              return true;
            }
          });
        });
        newRequires.push(newRequired);
      });

      Object.keys(state.requires).forEach(key => {
        state.requires[key].requires.forEach(required => {
          required.requireOne.forEach(requiredOption => {
            if (requiredOption.optionId === optionId) {
              requiredOption.isIncluded = true;
              if (!required.isIncluded) {
                required.isIncluded = true;
              }
            }
          });
        });
      });

      const updatedRequires = { ...state.requires, [optionId]: { requires: newRequires, optionName } };
      stateSet({
        ...state,
        requires: updatedRequires
      })
    }

    const optionsList = newList.map(el => ({
      ...el,
      accessoriesWithoutDiscount: el.optionName.toUpperCase().includes("LOY") ? "TRUE" : "FALSE"
    }));

    const excludeListPre = newList.map(el => !isExcluded ? el.excludes : []);
    const excludeList = lodash.flatten(excludeListPre);

    const totalPrice = newList.reduce((acc, next) => !next.isExcluded ? acc + (next.retailPrice902 || 0) : acc, 0)

    onSelectAccessories(
      totalPrice,
      optionsList,
      state.co2Emission,
      state.mainServiceDistKm,
      state.mainServicePeriodMonth,
      state.interServiceDistKm,
      state.interServicePeriodMonth,
      state.noxEmission,
      state.fuelConsumption,
      state.imageUrl,
      state.firstRegistrationFee,
      state.vatPrice
    );
    stateSet({
      ...state,
      selectedOptions: newList, excludeList, accessoriesPrice: totalPrice
    })
  }
    const generateRequires = () => {
    const items = [];
    Object.keys(state.requires).forEach(key => {
      state.requires[key].requires.forEach(required => {
        if (!required.isIncluded) {
          const divList = [];

          for (let i = 0; i < required.requireOne.length; i++) {
            if (i === 0) {
              divList.push(<RequiresSpan>{state.optionNames[required.requireOne[i].optionId]}</RequiresSpan>);
            } else {
              divList.push(" eller ");
              divList.push(<RequiresSpan>{state.optionNames[required.requireOne[i].optionId]}</RequiresSpan>);
            }
          }

          divList.push(` er påkrevd av ${state.requires[key].name}`);

          items.push(<div>{divList}</div>);
        }
      });
    });

    return items;
  }

  /**
   * This event is triggered everytime the checkbox is being checked. The const is a boolean and 
   * is passed further to the createCarConfiguration.js as a param to handle disabledCompleteButton.
   * @param {*} event checkbox event
   */
  const handleCo2EmissionChange = (event) => {
    const ignoreWarningMessage = event.target.checked;
    updateCo2(ignoreWarningMessage);
  }

  if (!state.carNotFound) {
    const packetList = state.accessories.packages.reduce((acc, packet) => {
      const canBeFromPackets =
        state.selectedFilter.packets.length === 0 ||
        state.selectedFilter.packets.some(el => el === packet.categoryName);

      if (canBeFromPackets) {
        const selected = state.selectedOptions.find(el => el.optionId === packet.optionId);
        const isActive = !state.excludeList.some(el => el === packet.optionId);
        return [
          ...acc,
          <PacketRow
            key={packet.optionId}
            option={selected ? selected : packet}
            selected={!!selected}
            isActive={isActive}
            onSelect={onSelectOption}
          />
        ];
      }
      return acc;
    }, []);

    const optionsList = [];
    const { selectedOptions } = state;
    const { selectedFilter } = state;
    const { excludeList } = state;
    lodash.forEach(state.accessories.options, (option, key) => {
      const canBeFromOptions =
        selectedFilter.options.length === 0 || selectedFilter.options.some(el => lodash.lowerFirst(el) === key);

      if (canBeFromOptions) {
        let subOptionList = [];
        if (option.length > 0) {
          subOptionList = option.map(o => {
            const selectedOption =
              selectedOptions.find(el => el.optionId === o.optionId || (el.includes || []).includes(o.optionId));
            const isActive =
              (!excludeList.some(el => el === o.optionId) && !selectedOptions.some(el => (el.includes || []).includes(o.optionId))) ||
              !selectedOptions.some(el => (el.includes || []).includes(o.optionId));
            return (
              <OptionsRow
                key={o.optionId}
                selected={!!selectedOption}
                option={selectedOption ? selectedOption : o}
                isActive={isActive}
                onSelect={onSelectOption}
              />
            );
          });
        }

        if (subOptionList.length > 0) {
          optionsList.push(
            <PacketSection key={key}>
              <TitleRow>
                <Title>
                  <SelectAll
                    onClick={() => onSelectAll({
                      type: "require",
                      optionsType: "options",
                      optionsCategory: key
                    })}
                    checked={allRequiredCheckedSelections.includes(key)}
                    type="require"
                  />
                </Title>
                <Title>
                  <SelectAll
                    onClick={() => onSelectAll({
                      type: "exclude",
                      optionsType: "options",
                      optionsCategory: key
                    })}
                    checked={allExcludedCheckedSelections.includes(key)}
                    type="exclude"
                  />
                </Title>
                <Title>{key}</Title>
              </TitleRow>
              {subOptionList}
            </PacketSection>
          );
        }
      }
    });

    const optionSelectionList = state.optionGroups.map(option => (
      <SelectionBox
        key={option}
        onClick={() => updateFilter("option", option)}
        selected={state.selectedFilter.options.some(el => el === option)}
      >
        {lodash.capitalize(option)}
      </SelectionBox>
    ));

    const packetSelectionList = state.packetGroups.map(packet => (
      <SelectionBox
        key={packet}
        onClick={() => updateFilter("packet", packet)}
        selected={state.selectedFilter.packets.some(el => el === packet)}
      >
        {packet}
      </SelectionBox>
    ));

    return (
      <AccessoriesSection>
        {state.loading ? (
          <LoadingBox>
            <Spinner />
            <p>Laster tilleggsutstyr fra Jato</p>
          </LoadingBox>
        ) : (
          <div>
            <FilterSection>
              {optionSelectionList.length > 0 ? (
                <Options>
                  <OptionLabel>Tilleggsvalg</OptionLabel>
                  <OptionSelectionList>
                    <SelectionBox
                      key="alle-option"
                      onClick={() => updateFilter("option", "Alle")}
                      selected={state.selectedFilter.options.length === 0}
                    >
                      Alle
                    </SelectionBox>
                    {optionSelectionList}
                  </OptionSelectionList>
                </Options>
              ) : (
                <div />
              )}

              {packetSelectionList.length > 0 ? (
                <Options>
                  <OptionLabel>Pakker</OptionLabel>
                  <OptionSelectionList>
                    <SelectionBox
                      key="alle-packet"
                      onClick={() => updateFilter("packet", "Alle")}
                      selected={state.selectedFilter.packets.length === 0}
                    >
                      Alle
                    </SelectionBox>
                    {packetSelectionList}
                  </OptionSelectionList>
                </Options>
              ) : (
                <div />
              )}
            </FilterSection>

            {generateRequires()}
            {co2NotFound && <PaddedDiv style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <p><span style={{ color: "#ff1c24" }}>Bilmodellen har ikke oppgitt CO2. Biltype har muligens ikke krav til utslippsdata</span></p>
              <Checkbox label="Overfør konfigurasjon til Core" id="mycheckbox" name="mycheckbox" type="checkbox" onChange={handleCo2EmissionChange}/>
            </PaddedDiv>}
            <AccessorieList>
              {!state.accessoriesNotFound ? (
                (packetList.length > 0 || optionsList.length > 0 ? <PaddedDiv>
                  <PacketSection>
                    <TitleRow>
                      <SelectAll
                        onClick={() => onSelectAll({ type: "require", optionsType: "packages", optionsCategory: "packages" })}
                        checked={allRequiredCheckedSelections.includes("packages")}
                        type="require"
                      />
                      <SelectAll
                        onClick={() => onSelectAll({ type: "exclude", optionsType: "packages", optionsCategory: "packages" })}
                        checked={allExcludedCheckedSelections.includes("packages")}
                        type="exclude"
                      />
                      <Title>Pakker</Title>
                    </TitleRow>
                    {packetList}
                  </PacketSection>
                  {optionsList}
                </PaddedDiv> : <div>Denne bilen har ingen tilleggsutstyr</div>)
              ) : (
                <PaddedDiv style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <p style={{ paddingBottom: "20px" }}>Kunne ikke hente ut tilleggsutstyr fra Jato</p>
                  <DefaultButton onClick={() => getAccessoriesFromJato(vehicleId, accessoriesList)}>
                    Prøv på nytt
                  </DefaultButton>
                </PaddedDiv>
              )}
            </AccessorieList>
          </div>
        )}
      </AccessoriesSection>
    );
  } else {
    return (
      <AccessoriesSection>
        <AccessorieList>
          {state.loading ? (
            <LoadingBox>
              <Spinner />
              <p>Laster tilleggsutstyr fra Jato</p>
            </LoadingBox>
          ) : (
            <PaddedDiv style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <p style={{ textAlign: "center" }}>Kunne ikke finne igjen bilen hos Jato.</p>
              <p style={{ paddingBottom: "20px" }}>Start konfigurering på nytt ved å trykke på knappen under</p>
              <DefaultButton onClick={() => onSetStart()}>Start konfigurering på nytt</DefaultButton>
            </PaddedDiv>
          )}
        </AccessorieList>
      </AccessoriesSection>
    );
  }
}

export const AccessoriesSection = styled.div`
  height: calc(100% - 80px);
  margin-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-top: 27px;
  width: -webkit-fill-available;
  width: -moz-available;
`;

const AccessorieList = styled.div`
  display: flex;
  justify-content: center;
  overflow-x: hidden;
`;

const PacketSection = styled.div`
  >div {
    display: grid;
    grid-template-columns: 100px 100px auto 50px 80px;
  }
`;

const TitleRow = styled.div`
  align-items: baseline;
  border-bottom: 1px solid #e4e2e2;
`

const Title = styled.div`
  font-size: 12px;
  color: ${Colors.Grey1};
  padding-top: 30px;
  text-transform: capitalize;
`;

const PaddedDiv = styled.div`
  padding-bottom: 20px;
  width: 90%;
`;

const RequiresSpan = styled.span`
  color: #721c24;
`;


export const OptionLabel = styled.div`
  font-size: 12px;
  text-align: left;
  line-height: 16px;
  color: ${Colors.Grey1};
`;
export const FilterSection = styled.div`
  display: flex;
`;

export const OptionSelectionList = styled.div`
  text-align: left;
  padding-bottom: 20px;
`;

export const Options = styled.div`
  flex-grow: 1;
`;

const DefaultButton = styled.button`
  width: 200px;
  height: 40px;
  line-height: 15px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  margin: 0px 4px 0px 4px;
  padding: 0px 10px 0px 10px;
  background-color: ${Colors.Grey4};
  border: 2px solid ${Colors.SeaLight};
  color: ${Colors.Sea};
  &:hover {
    cursor: pointer;
  }
`;
