import axios from "axios";
import { get } from "lodash"

const generatePostBody = (callType, selections, vehicleType) => {
  const lcvDatabaseName = "NSCN_CS2002";
  const carDatabaseName = "SSCN_CS2002";

  let databaseName;
  switch (vehicleType) {
    case "car":
      databaseName = carDatabaseName;
      break;
    case "lightCommercialVehicle":
      databaseName = lcvDatabaseName;
      break;
    default:
      databaseName = carDatabaseName;
  }

  if(callType !== "vehiclesbuilder") {
    return {
      config: {
        applyInclExcl: false,
        applyInclExclFor: `${callType}`,
        includeImages: true,
        includeTranslations: true,
        includeDetails: false
      },
      input: [
        {
          databaseName,
          selections
        }
      ]
    };
  } else {
    return {
      userDateTime: new Date(),
      benchmarkVehicleId: selections,
      markets: [
        {
          databaseName,
          vehicles: [
            {
              vehicleId: selections
            }
          ]
        }
      ]
    };
  }
};

export const getMakes = (vehicleType, callback) => {
  const body = generatePostBody("makes", [], vehicleType);
  axios
    .post("/api/jato/makes", body)
    .then(res => {
      callback(res.data[0].selections);
    })
    .catch(err => {
      console.debug("Could not fetch makes from jato, returning emptylist");
      callback([]);
    });
};

export const getModels = (make, vehicleType, callback) => {
  const body = generatePostBody("models", [{ make }], vehicleType);
  axios
    .post("/api/jato/models", body)
    .then(res => {
      callback(res.data[0].selections);
    })
    .catch(err => {
      console.debug("Could not fetch models from jato, returning emptylist.");
      callback([]);
    });
};

export const getBodies = (make, model, modelYear, vehicleType, callback) => {
  const selections = [{ make, model, modelYear }];
  const body = generatePostBody("bodies", selections, vehicleType);

  axios
    .post("/api/jato/bodies", body)
    .then(res => {
      callback(res.data[0].selections);
    })
    .catch(err => {
      console.debug("Could not fetch bodies from jato, returning emptylist");
      callback([]);
    });
};

export const getEngines = (make, model, modelYear, bodyCode, doors, drivenWheels, vehicleType, callback) => {
  const selections = [{ make, model, modelYear, bodyCode, doors, drivenWheels }];
  const body = generatePostBody("vehicles", selections, vehicleType);

  axios
    .post("/api/jato/vehicles", body)
    .then(res => {
      callback(res.data[0].selections);
    })
    .catch(err => {
      console.debug("Could not fetch engines from jato, returning emptylist");
      callback([]);
    });
};

export const getVehiclesBuilder = (vehicleId, vehicleType, callback) => {
  const body = generatePostBody("vehiclesbuilder", vehicleId, vehicleType);
  return axios
    .post("/api/jato/vehiclesbuilder", body)
    .then(res => res.data)
};

export const getGenericOptionsByBody = (payload) => {
  return axios.post('/api/jato/common-options/body', payload).then(res => res.data)
}

export const getCarFilterById = async id => {
  const res = await axios.get(`/api/car-filters/${id}`)
  return get(res.data, "[0]", null) 
}

export const getMatrixMakes = async () => {
  const { data: matrixMakes } = await axios.get(`/api/core/information/makes`)
  return matrixMakes;
}

export const getMatrixModels = async (make) => {
  const { data: matrixModels } = await axios.get(`/api/core/information/models?make=${make.toUpperCase()}`)
  return matrixModels;
}

export const getMatrixEngines = async (make, model, modelYear) => {
  const {data: matrixEngines} = await axios
    .get(`/api/core/information/engines?make=${make.toUpperCase()}&model=${model}&modelYear=${modelYear}`)
  return matrixEngines;
};
