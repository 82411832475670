import styled from "styled-components";

export const SelectionBox = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  line-height: 22px;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  vertical-align: middle;
  /* color: #467EFF; */
  display: inline-block;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  min-width: 60px;
  min-height: 34px;
  cursor: pointer;

  background: ${props => (props.selected ? "#467EFF" : "white")};
  color: ${props => (props.selected ? "#FFFFFF" : "#467EFF")};
  border: ${props => (props.selected ? "2px solid #467EFF" : "2px solid #C7D8FF")};
  margin-right: 5px;
  margin-bottom: 5px;
`;

export const Header = styled.div`
  display: grid;
  margin: 0 10;
  margin-bottom: 15px;
  grid-template-columns: 136px 200px 100px 1fr;
  line-height: 22px;
  font-size: 12px;
  height: 32px;
  color: #6c6c6c;
  background: linear-gradient(0deg, #f8f8f8, #f8f8f8), #ffffff;
  box-shadow: 0px 1px 0px #e4e2e2, 0px -1px 0px #e4e2e2;
`;

export const HeaderItem = styled.div`
  text-align: left;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
`;
