import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const EngingeRow = ({
  onClick,
  vehicleId,
  vehicleUid,
  engineName,
  price,
  basePrice,
  fuelType,
  transmission,
  drivenWheels,
  numberOfDoors,
  seats,
  maximumPowerhpPs
}) => {
  return (
    <Row onClick={() => onClick(vehicleId, vehicleUid, engineName, price, fuelType, basePrice, transmission)}>
      <EngineFieldLeft>{engineName}</EngineFieldLeft>
      <EngineDrivenWheels>{drivenWheels}</EngineDrivenWheels>
      <EngineField>{numberOfDoors}</EngineField>
      <EngineField>{seats}</EngineField>
      <EngineField>{maximumPowerhpPs}</EngineField>
      <EngineField>{price}</EngineField>
    </Row>
  );
};

EngingeRow.propTypes = {
  onClick: PropTypes.func,
  vehicleUid: PropTypes.number,
  engineName: PropTypes.string,
  price: PropTypes.string,
  basePrice: PropTypes.number,
  fuelType: PropTypes.string,
  transmission: PropTypes.string,
  drivenWheels: PropTypes.string,
  numberOfDoors: PropTypes.string,
  seats: PropTypes.string,
  maximumPowerhpPs: PropTypes.number
};

EngingeRow.defaultProps = {
  onClick: null,
  vehicleUid: 0,
  engineName: "",
  price: "",
  basePrice: 0,
  fuelType: "",
  transmission: "",
  drivenWheels: "",
  numberOfDoors: "",
  seats: "",
  maximumPowerhpPs: 0
};

export default EngingeRow;

export const Row = styled.div`
  display: grid;
  margin: 0 10;
  grid-template-columns: 300px 50px 50px 50px 100px 100px;
  border-bottom: 1px solid #e4e2e2;
  padding: 10px;
  line-height: 22px;
  font-size: 14px;
  color: #6c6c6c;
  cursor: pointer;
`;

export const EngineField = styled.div`
  padding-top: 15px;
  padding-left: 0px;
  text-align: center;
`;

export const EngineFieldLeft = styled(EngineField)`
  text-align: left;
  font-weight: 600;
`;

export const EngineDrivenWheels = styled(EngineField)`
  text-transform: capitalize;
`;
