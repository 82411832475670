import Branding from './plugins/Branding'
import CustomerIcon from './plugins/CustomerIcon'
import MainLogo from './plugins/MainLogo'
import Summary from './components/CaseSummary'
import QueueItem from './components/QueueItem'
import CreateCarConfiguration from './components/CreateCarConfiguration'
import {Files} from "./components/Files";

const CustomComponents = {
  case: {
    caseSummary: {
      component: Summary,
    },
    tasks: {
      useNewTasksModule: true,
      taskComponents: {
        'create-car-configuration': {
          component: CreateCarConfiguration,
          customWidth: '95%',
          customHeight: '95%',
        },
      },
      taskLists: [
        {
          id: "task-list-failed-tasks",
          title: "task-list-title-failed-tasks",
          query: "status=failed"
        },
        {
          id: "task-list-pending-user-tasks",
          title: "task-list-title-pending-user-tasks",
          query: "status=pending&taskCategory=user-task",
          badgeFilter: () => true
        },
        {
          id: "task-list-pending-message-tasks",
          title: "task-list-title-pending-message-tasks",
          query: "status=pending&taskCategory=message-task",
          isPrimaryActionList: false,
          filter: (task) => {
            const tasksToHide = ['signing-success-callback', 'update-isIncluded']
            return !tasksToHide.includes(task.taskType)
          }
        }
      ]
    },
    mainStatus: {
      defaultStatusWithPendingTask: 'Manuell behandling',
      defaultStatusWithServiceTasks: 'Automatisk behandling',
    },
    tabs: [
      {
        id: 'files',
        title: 'Filer',
        component: Files,
        hide: (flow) => !['signing-batch', 'budget-batch'].includes(flow.flowDefinitionId),
      },
    ],
  },
  queue: {
    queueItem: QueueItem,
  },
  menu: {
    menuStructure: [
      {
        name: 'all-cases',
        elems: ['car-configuration', 'budget-selection', 'signing', 'budget-batch', 'signing-batch'],
        isOpen: true,
      },
      { name: 'failed-cases' },
    ],
  },
  queueDefinition: {
    'car-configuration': {
      name: 'car-configuration',
      queueFilter:
        '?status=!completed&status=!archived&flowDefinitionId=car-configuration&sort=createdAt&dir=-1',
      pollingEnabled: true,
      pollingInterval: 5000,
      limit: 15,
    },
    'budget-selection': {
      name: 'budget-selection',
      queueFilter:
        '?status=!completed&status=!archived&flowDefinitionId=budget-selection&sort=createdAt&dir=-1',
      pollingEnabled: true,
      pollingInterval: 5000,
      limit: 15,
    },
    'signing': {
      name: 'signing',
      queueFilter:
        '?status=!completed&status=!archived&flowDefinitionId=signing&sort=createdAt&dir=-1',
      pollingEnabled: true,
      pollingInterval: 5000,
      limit: 15,
    },
    'signing-batch': {
      name: 'signing-batch',
      queueFilter:
        '?status=!completed&status=!archived&flowDefinitionId=signing-batch&sort=createdAt&dir=-1',
      pollingEnabled: true,
      pollingInterval: 5000,
      limit: 15,
    },
    'budget-batch': {
      name: 'budget-batch',
      queueFilter:
        '?status=!completed&status=!archived&flowDefinitionId=budget-batch&sort=createdAt&dir=-1',
      pollingEnabled: true,
      pollingInterval: 5000,
      limit: 15,
    },
    'failed-cases': {
      name: 'failed-cases',
      queueFilter:
        '?status=!completed&status=!archived&tasks.status=failed&sort=createdAt&dir=-1',
      limit: 10,
    },
  },
}

const SearchFields = ['data.budgetId', 'data.budgetData.companyName']

export { Branding, CustomerIcon, MainLogo, CustomComponents, SearchFields }
