import styled from "styled-components";
import { Colors } from "@flow/style";
import { Icons } from "@flow/icons";
import Pencil from "../icons/Pencil"
import { useTable, useSortBy } from "react-table";
import React from "react";

export const Table = ({ columns, data, renderRowSubComponent, selectedRows, selectedRowsHandler, onRowSelected }) => {
  const { level, onRowClick, onActionClick } = renderRowSubComponent

  const getSelectCol = () => {
    switch (level) {
      case "car-filter":
        return [
          {
            id: 'edit',
            Cell: ({ row }) => (
              <IconContainer onClick={() => onActionClick(row, "edit")}>
                <Pencil />
              </IconContainer>
            )
          },
          {
          id: 'delete',
          Cell: ({ row }) => (
            <IconContainer onClick={() => onActionClick(row, "delete")}>
              <Icons.Trashcan/>
            </IconContainer>
          ),
        }
      ]
      case "customer":
        return [
          {
            id: 'selection',
            Cell: ({ row }) => (
              <IconContainer onClick={() => onActionClick(row, 'delete-customer')}>
                <Icons.Trashcan/>
              </IconContainer>
            ),
          }
        ]
      default:
        return []
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    { 
      columns, 
      data, 
      initialState: {
        sortBy: [
          {
            id: "configuration.make",
            desc: false
          }
        ]
      }
    }, 
    useSortBy, 
    hooks => {
      hooks.visibleColumns.push(columns => [
        ...columns,
        ...getSelectCol()
      ])
    }
  )

  return <TableWrapper>
    <StyledTable {...getTableProps()}>
      <TableHead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <TableHeader {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? <Justify><Icons.ChevronDown /></Justify>
                      : <Justify><Icons.ChevronUp /></Justify>
                    : ""}
                  </span>
              </TableHeader>
            ))}
          </tr>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (<>
            <TableDataRow
              clickable={!!onRowClick}
              selected={selectedRows.find(aRow => aRow.parentId === row.original.id)}
              {...row.getRowProps()}
            >
              {row.cells.slice(0, 7).map(cell => {
                return (
                  <TableData {...cell.getCellProps()} onClick={() => {
                    if (onRowClick) {
                      selectedRowsHandler(row.original.id, row)
                      onRowSelected(row)
                    }
                  }}>
                    {cell.render('Cell')}
                  </TableData>
                )
              })}
              {row.cells.slice(7).map(cell => {
                return (
                  <TableData {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableData>
                )
              })}
            </TableDataRow>
            {selectedRows
              .filter(aRow => aRow.parentId === row.original.id)
              .map(({ subRow }) => <CustomerDataRow>
                <TableData colSpan={visibleColumns.length}>
                  {onRowClick({ row: subRow })}
                </TableData>
              </CustomerDataRow>)}
          </>)
        })}
      </TableBody>
    </StyledTable>
  </TableWrapper>
}

export const StyledTable = styled.table`
  width: 100%;
  line-height: 28px;
  background-color: ${Colors.Snow}
  margin-bottom: 25px;
  border: 1px solid ${Colors.Grey2};
  border-radius: 3px;
  border-spacing: 0;

  // these rules 
  tr td:nth-last-child(1n) {
    padding: 0 10px 0 10px;
  }

  tr td:last-child {
    padding: 0 10px 0 10px;
  }
  &:first-child {
    border-bottom: 0;
  }
`

export const TableWrapper = styled.div`
  width: 100%;
`

export const TableHead = styled.thead`
  background-color: #F8F8F8;
  color: #6C6C6C;

  tr th:nth-last-child(1n) {
    padding: 0 10px 0 10px;
  }

  tr th:last-child {
    padding: 0 10px 0 10px;
  }
`

export const TableHeader = styled.th`
  padding: 0 36px 0 8px;
  text-align: start;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
`

export const TableBody = styled.tbody`
  font-weight: 400;
  font-size: 14px;
`

export const TableDataRow = styled.tr`
  > td:not(:first-child) {
    cursor: ${props => props.clickable ? "pointer" : "initial"};
  }

  background-color: ${props => props.selected ? Colors.SeaLight : Colors.Snow}
`

export const CustomerDataRow = styled(TableDataRow)`
  cursor: initial;
`

export const TableData = styled.td`
  padding: 0 36px 0 8px;
  border-bottom: 0.4px solid ${Colors.Grey2};
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`

const Justify = styled.div`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
`
