import React from "react";
import styled from "styled-components";
import { Icons } from "@flow/icons";
import { Colors } from "@flow/style";

const Tooltip = ({ text }) => {
  return (
    <TooltipWrapper alt={text} className="tooltip">
      <div className="triangle-right" />
      <Icons.Help />
    </TooltipWrapper>
  );
};

export default Tooltip;

export const TooltipWrapper = styled.div`
  padding-right: 10px;
  position: relative;
  display: inline-flex;
  width: 40px;
  height: 24px;

  :hover:after {
    content: attr(alt);
    background: ${Colors.Grey1};
    border-radius: 15px;
    position: absolute;
    color: ${Colors.Snow};
    margin-top: -30px;
    margin-right: 30px;
    padding: 5px 15px;
    text-align: center;
    z-index: 99999999;
    width: 400px;
    right: 40%;
    top: -9px;
    min-height: 80px;
  }

  .triangle-right {
    width: 15px;
  }

  :hover {
    .triangle-right {
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-left: 15px solid ${Colors.Grey1};
      border-bottom: 10px solid transparent;
    }
  }
`;
