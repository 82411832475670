import React, { useState } from 'react'
import styled from 'styled-components'
import ContextEntry from './ContextEntry'
import { Colors } from '@flow/style'
import lodash from 'lodash'
import { useHistory } from 'react-router-dom'
import { Chevron } from './icons/Chevron'

const Summary = (props) => {
  const history = useHistory()
  const [showMore, setShowMore] = useState(false)

  const { caseData = {} } = props
  const { data = {}, flowDefinitionId } = caseData

  const processType = flowDefinitionId || ''

  //Basic data on all processes
  const companyName = lodash.get(data, 'budgetData.companyName')
  const contractNumber = lodash.get(data, 'budgetId')
  const contactPerson = lodash.get(data, 'budgetData.contactPerson', '')
  const driver = lodash.get(data, 'budgetData.driver', '')

  //car-configuration process
  const carMake = lodash.get(data, 'carConfiguration.carMake', '')
  const carModel = lodash.get(data, 'carConfiguration.carModel', '')
  const carEngine = lodash.get(data, 'carConfiguration.carEngineName', '')
  const retailPrice = lodash.get(data, 'carConfiguration.retailPrice', 0)
  const accessoriesPrice = lodash.get(
    data,
    'carConfiguration.sumAccessoriesRetailPrice',
    0,
  )

  const kilometerLength = lodash.get(data, 'budgetData.kmLength')
  const contractLength = lodash.get(data, 'budgetData.contractLength')
  const totalPrice = retailPrice + accessoriesPrice

  //recalculate-residual-valuen process
  const resultValuePercent = lodash.get(
    data,
    'remainingValue.resultValuePercent',
    '-',
  )
  const resultValuePrice = lodash.get(
    data,
    'remainingValue.resultValuePrice',
    '-',
  )

  //signing process
  const signingEmail = lodash.get(data, 'budgetData.email', '-')

  // signing-batch process
  const signingBatchEmail = lodash.get(data, 'signer.email', '-')
  const signingBatchBudgets = Object.values(lodash.get(data, 'budgets', {}))

  // Budget-batch process
  const signingBatchFlowId = lodash.get(data, 'signingBatchFlowId', '')
  const isIncludedInSigningBatch = lodash.get(
    data,
    'isIncludedInSigningBatch',
    '',
  )

  return (
    <CaseSummary>
      {processType === 'signing-batch' ? (
        <>
          <ContextEntry
            title={'E-postadresse for signering'}
            main={signingBatchEmail}
            subTitles={['']}
          />
          <StyledTable>
            <thead>
              <tr>
                <th>Kontraktnummer</th>
                <th>Merke</th>
                <th>Modell</th>
              </tr>
            </thead>
            <tbody>
              {signingBatchBudgets
                .map((budget, index) => {
                  return (
                    <StyledTableRow
                      id={index}
                      onClick={() =>
                        history.push(
                          `/cases/case/budget-batch/${budget.flowId}`,
                        )
                      }
                    >
                      <td>{budget.budgetId}</td>
                      <td>{budget.carMake}</td>
                      <td>{budget.carModel}</td>
                      <td>
                        <ChevronWrapper>
                          <Chevron />
                        </ChevronWrapper>
                      </td>
                    </StyledTableRow>
                  )
                })
                .slice(0, showMore ? signingBatchBudgets.length : 5)}
            </tbody>
            {signingBatchBudgets.length > 5 && (
              <tfoot style={{ textAlign: 'center' }}>
                <tr>
                  <td colSpan="3">
                    <ExpandButton onClick={() => setShowMore(!showMore)}>
                      {showMore ? 'Se mindre' : 'Se flere'}
                    </ExpandButton>
                  </td>
                </tr>
              </tfoot>
            )}
          </StyledTable>
        </>
      ) : (
        <>
          <ContextEntry
            title={'Selskap'}
            main={`${companyName || ''}`}
            subTitles={[`Kontaktperson: ${contactPerson}`]}
          />
          <ContextEntry
            title={'Kontraktnummer'}
            main={contractNumber}
            subTitles={[]}
          />
          <ContextEntry title={'Sjåfør'} main={driver} subTitles={[]} />
          <ContextEntry
            title={'Kontraktlengde'}
            main={contractLength ? contractLength : '-'}
            subTitles={[]}
          />
          <ContextEntry
            title={'Kilometerlengde'}
            main={
              kilometerLength
                ? (kilometerLength + '' || '0')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                : '-'
            }
            subTitles={[]}
          />
        </>
      )}

      {processType === 'recalculate-residual-value' ? (
        <div className="processInfo" style={{ paddingTop: 10 }}>
          <p
            style={{
              paddingLeft: 20,
              fontSize: 16,
              fontWeight: 400,
              marginBottom: 8,
              color: Colors.Grey1,
            }}
          >
            Rekalkulering av restverdi
          </p>
          <div
            style={{
              paddingTop: 5,
              display: 'flex',
              flexDirection: 'row',
              placeContent: 'flex-start',
            }}
          >
            <ContextEntry
              title={'Treff fra restverditabell'}
              main={resultValuePercent + '%'}
              subTitles={['']}
            />
            <ContextEntry
              title={'Restverdi'}
              main={
                resultValuePrice !== '-'
                  ? new Intl.NumberFormat('no-NB', {
                      style: 'currency',
                      currency: 'NOK',
                    }).format(resultValuePrice) + ' kr'
                  : ' '
              }
              subTitles={['']}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {processType === 'car-configuration' ? (
        <div className="processInfo" style={{ paddingTop: 10 }}>
          <p
            style={{
              paddingLeft: 20,
              fontSize: 16,
              fontWeight: 400,
              marginBottom: 8,
              color: Colors.Grey1,
            }}
          >
            Bilvalg
          </p>
          <div
            style={{
              paddingTop: 5,
              display: 'flex',
              flexDirection: 'row',
              placeContent: 'flex-start',
            }}
          >
            <ContextEntry title={'Bilmerke'} main={carMake} subTitles={[]} />
            <ContextEntry title={'Bilmodell'} main={carModel} subTitles={[]} />
            <ContextEntry title={'Motor'} main={carEngine} subTitles={[]} />
            <ContextEntry
              title={'Totalpris (Utsalgspris + tilleggsutstyr)'}
              main={
                totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') +
                ' kr'
              }
              subTitles={[]}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {processType === 'signing' ? (
        <div className="processInfo" style={{ paddingTop: 10 }}>
          <p
            style={{
              paddingLeft: 20,
              fontSize: 16,
              fontWeight: 400,
              marginBottom: 8,
              color: Colors.Grey1,
            }}
          >
            Signering
          </p>
          <div
            style={{
              paddingTop: 5,
              display: 'flex',
              flexDirection: 'row',
              placeContent: 'flex-start',
            }}
          >
            <ContextEntry
              title={'E-postadresse for signering'}
              main={signingEmail}
              subTitles={['']}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {processType === 'budget-batch' ? (
        <div className="processInfo" style={{ paddingTop: 10 }}>
          <p
            style={{
              paddingLeft: 20,
              fontSize: 16,
              fontWeight: 400,
              marginBottom: 8,
              color: Colors.Grey1,
            }}
          >
            Budsjett
          </p>
          <div
            style={{
              paddingTop: 5,
              display: 'flex',
              flexDirection: 'row',
              placeContent: 'flex-start',
            }}
          >
            <ContextEntry
              title={'E-postadresse for signering'}
              main={signingEmail}
              subTitles={['']}
            />
            {isIncludedInSigningBatch && (
              <ContextEntry
                title={'Inkludert i en signering'}
                main={
                  <ExpandButton
                    onClick={() =>
                      history.push(
                        `/cases/case/signing-batch/${signingBatchFlowId}`,
                      )
                    }
                  >
                    Gå til signeringsprosess
                  </ExpandButton>
                }
                subTitles={['']}
              />
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </CaseSummary>
  )
}

export default Summary

const CaseSummary = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 85%;
  min-width: 550px;
  min-height: 105px;
  justify-items: start;
  padding: 10px;
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #f7fbff;
  border: 1px solid #e6e4e3;
  border-radius: 4px;

  .processInfo {
    grid-column: 1/4;
    grid-row: 3;
    width: 100%;
    padding-top: 25;
  }
`

const StyledTable = styled.table`
  grid-column: 1 / span 3;
  text-align: left; /* Align table content to the left */
  width: 100%;
`

const StyledTableRow = styled.tr`
  cursor: pointer;
  height: 40px;

  &:hover {
    background-color: rgba(222, 239, 255, 0.3);
  }
`

const ExpandButton = styled.span`
  color: #467eff;
  cursor: pointer;
`

const ChevronWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${(props) => 'rotate(-90deg)'};
`
