import React from 'react';
import { downloadAttachment } from '../../utils/attachments';
import { LoadingSpinner } from '../LoadingSpinner';
import { useQuery } from 'react-query';
import styled from 'styled-components';

const FilePreview = ({ attachmentKey, mimeType, flowId, version }) => {
  const { data: file, isFetching } = useQuery({
    queryKey: ['file', attachmentKey, version, flowId],
    queryFn: () => downloadAttachment(flowId, attachmentKey, mimeType),
  });

  if (isFetching) {
    return (
      <div>
        <LoadingSpinner width={24} height={24} className="w-full my-5" />
      </div>
    );
  }

  const isImage = mimeType.includes('image');
  const isPdf = mimeType.includes('pdf');

  return (
    <FilePreviewContainer>
      {isImage && <FileImage src={file} alt="file" />}
      {isPdf && <FileIframe title="file" src={file} />}
    </FilePreviewContainer>
  );
};


const FilePreviewContainer = styled.div`
  width: 100%;
`;

const FileImage = styled.img`
  width: 100%;
`;

const FileIframe = styled.iframe`
  height: 100vh; /* Adjust the height as needed */
  width: 100%;
`;
export default FilePreview;
