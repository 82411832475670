import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

const formatFilterType = string => string === 'equipment-filter'

export default () => {
  const queryClient = useQueryClient();

  const { mutate: createFilter, isSuccess: isCreateCarFilterSuccess } =
    useMutation(
      "car-filter-create",
      async ({ configuration, label, type }) => {
        await axios.post("/api/car-filters", {
          label,
          allowedByAllCustomers: formatFilterType(type),
          configuration: { ...configuration, type }
        });
      },
      {
        onSuccess: () => queryClient.invalidateQueries(),
      }
    );

  const { mutate: updateFilter } = useMutation(
    "car-filter-update",
    async ({ id, label, configuration, allowedByAllCustomers }) => {
      await axios.put(`/api/car-filters/${id}`, {
        allowedByAllCustomers: allowedByAllCustomers,
        configuration: configuration,
        label,
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries(),
    }
  );

  const { mutate: deleteFilter } = useMutation(
    "car-filter-delete",
    async (id) => {
      await axios.delete(`/api/car-filters/${id}`);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(),
    }
  );

  return {
    createFilter,
    isCreateCarFilterSuccess,
    updateFilter,
    deleteFilter,
  };
};
