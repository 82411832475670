import React, { Component } from "react";
import styled from "styled-components";
import { Colors } from "@flow/style";

export default class ContextBasic extends Component {
  render() {
    return (
      <EntryFrame size={this.props.size}>
        <TextField size={this.props.size}>{this.props.title}</TextField>
        {this.props.input ? (
          <InputField placeholder="0 %" value={this.props.value} onChange={event => this.props.onChange(event.target.value)} />
        ) : (
            <TextField size={this.props.size}>{this.props.value}</TextField>
          )}
      </EntryFrame>
    );
  }
}

const EntryFrame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 43px 12px 27px;
  padding-bottom: ${props => (props.size === "small" ? "12px" : "23px")};
  padding-top: ${props => (props.size === "small" ? "0px" : "18px")};
`;

const TextField = styled.p`
  color: ${Colors.Grey1};
  font-size: ${props => (props.size === "small" ? "14px" : "18px")};
  font-weight: ${props => (props.size === "small" ? "100" : "500")};
  align-self: center;
  margin-left: 2px;
  line-height: 18px;
`;

const InputField = styled.input`
  color: ${Colors.Grey1};
  font-size: ${props => (props.size === "small" ? "14px" : "18px")};
  align-self: center;
  margin-left: 2px;
  line-height: 18px;
  border: 1px solid ${Colors.Grey2};
  border-radius: 4px;
  text-align: right;
`;
