import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Colors } from "@flow/style";

const Title = styled.p`
  color: ${Colors.Coal};
  margin-top: 20px;
`;

const List = styled.ul`
  padding-inline-start: 7%;
  color: ${Colors.Grey1};
`;

const AccessoriesList = styled.ul`
  padding-inline-start: 7%;
  color: ${Colors.Grey1};
  font-size: 12px;
`;

const accessoriesList = (accessories) => (
  <div>
    <Title>Påkrevde tilleggsutstyr</Title>
    <AccessoriesList>
      {accessories.filter(a => a.isRequired).map(a => <li key={a}>{a.optionName}</li>)}
    </AccessoriesList>
    <Title>Ekskluderte tilleggsutstyr</Title>
    <AccessoriesList>
      {accessories.filter(a => a.isExcluded).map(a => <li key={a}>{a.optionName}</li>)}
    </AccessoriesList>
  </div>
);

const DisplayCarConfig = ({ make, model, year, engine, body, accessories }) => (
  <div>
    <Title>Konfigurert bil</Title>
    <List>
      { make && <li key="make">{make}</li> }
      { model && <li key="model">{model}/{year}</li>}
      { body && <li key="body">{body}</li> }
      { engine && <li key="engine">{engine}</li> }
    </List>
    { accessories && accessories.length > 0 && accessoriesList(accessories)}
  </div>
);

DisplayCarConfig.propTypes = {
  make: PropTypes.string,
  model: PropTypes.string,
  year: PropTypes.string,
  engine: PropTypes.string,
  body: PropTypes.string,
  accessories: PropTypes.arrayOf(PropTypes.string),
};

DisplayCarConfig.defaultProps = {
  make: null,
  model: null,
  year: null,
  engine: null,
  body: null,
  accessories: null
};

export default DisplayCarConfig;
