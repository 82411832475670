import React from "react";
import autoplanLogo from "./autoplan_logo.svg";

export default class CustomerIcon extends React.Component {
  render() {
    const height = this.props.height ? this.props.height : "30px";
    const width = this.props.width ? this.props.width : "30px";
    return <img src={autoplanLogo} alt="" style={{ width, height }} />;
  }
}
