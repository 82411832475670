import React, { useMemo } from "react";
import { Header } from "../CarFilterCustomerMap";
import { Table } from "./components/Table";
import formatDate from "../../utils/dateFormatter";
import Description from "./components/Description";
import Link from "./components/Link";
import RadioSlide from "./components/RadioSlide";
import { TableInfoContainer } from "./CustomerFilters";

const Columns = ({ selectedCustomer }) => {
  return useMemo(
    () => [
      {
        Header: "Navn",
        accessor: "label",
      },
      {
        Header: "Merke",
        accessor: "configuration.make",
      },
      {
        Header: "Modell",
        accessor: "configuration.model",
      },
      {
        Header: "Karosseri",
        accessor: "configuration.body",
      },
      {
        Header: "Opprettet dato",
        accessor: (row) => formatDate(new Date(row.created_at.split("T")[0])),
      },
      {
        Header: "Oppdatert dato",
        accessor: (row) =>
          row.updated_at
            ? formatDate(new Date(row.updated_at.split("T")[0]))
            : "",
      },
      {
        Header: "Generisk",
        accessor: (row) => row.configuration.isGenericFilter ? "Ja" : "Nei"
      },
      ...(selectedCustomer
        ? [
            {
              Header: "Tilgjengelig",
              accessor: (row) => (row.available ? "Ja" : "Nei"),
            },
          ]
        : []),
    ],
    [selectedCustomer]
  );
};

export default ({
  customer,
  equipmentFilters,
  customers,
  createCarFilterHandler,
  menuSelectionHandler,
  tableHandlers,
  showCarHandler,
  showUnavailableEquipmentFilters,
  handleShowUnavailableEquipmentFilters,
}) => {
  const { getSubRowComponent, selectedRows, toggleSelectedRow } = tableHandlers;
  const columns = Columns({ selectedCustomer: !!customer });

  return (
    <div>
      <TableInfoContainer>
        <div>
          <Header>Utstyrsfiltre</Header>
          <Link
            onClick={() =>
              createCarFilterHandler({
                startCarFilter: true,
                type: "equipment-filter",
              })
            }
          >
            (Opprett nytt)
          </Link>
          <Description>
            Filtre som gjelder alle kunder untatt utlistede.
          </Description>
        </div>
        {customer && (
          <RadioSlide
            key={"customer-filters"}
            toggle={() =>
              handleShowUnavailableEquipmentFilters(
                !showUnavailableEquipmentFilters
              )
            }
            isActive={showUnavailableEquipmentFilters}
          >
            <span>Vis utilgjengelige filtre</span>
          </RadioSlide>
        )}
      </TableInfoContainer>
      <div>
        {equipmentFilters && (
          <Table
            columns={columns}
            data={
              showUnavailableEquipmentFilters
                ? equipmentFilters
                : customer
                ? equipmentFilters.filter((cf) => cf.available)
                : equipmentFilters
            }
            renderRowSubComponent={{
              level: "car-filter",
              onRowClick: ({ row }) => getSubRowComponent({ row, customers }),
              onActionClick: menuSelectionHandler,
            }}
            selectedRows={selectedRows}
            selectedRowsHandler={toggleSelectedRow}
            onRowSelected={showCarHandler}
          />
        )}
      </div>
    </div>
  );
};
