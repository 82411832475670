import styled from "styled-components";

export default styled.div`
  .mainHeader {
    background-color: 'white';
  }

  .mainMenu {
    background-color: #2a6df4;
  }

  .mainMenuIcon.active {
    color: white;
    border-left: 3px solid white;
  }

  .activeElement {
    border-left: 2px solid blue;
  }
`;
