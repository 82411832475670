import React from "react";
import styled from "styled-components";
import { Colors } from "@flow/style";
import CarFilter from "./car-filter-configuration/CarFilter";
import AppProvider from "./AppProvider";

export default (props) => {
  return (
    <AppProvider>
      <ModalContentFrame>
        <CarFilter />
      </ModalContentFrame>
    </AppProvider>
  );
};

/*
todos
- View for listing created car filters, both by this instance and ability to fetch all carfilters across all instances
  - Button to go into "create new car filter"-app
  - Ability to delete car filter (only if it was created by this instance?)
- View for listing all created carfilter-customer maps
  - Button to go into "create new carfilter-customer map"
  - Ability to delete carfilter-customer map
 */

const ModalContentFrame = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  padding: 20px;
`;

export const DefaultButton = styled.button`
  width: 120px;
  height: 40px;
  line-height: 15px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  margin: 0px 4px 0px 4px;
  padding: 0px 10px 0px 10px;
`;

export const SaveButton = styled(DefaultButton)`
  color: ${Colors.Sea};

  &:hover  {
    cursor: pointer;
  }

  background-color: ${Colors.Grey4};
  border: 2px solid ${Colors.SeaLight};
`;

export const Header = styled.h3`
  display: inline-block;
  margin: 0;
`;
