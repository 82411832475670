import React, { useMemo, useState, useRef } from "react";
import Select, { createFilter } from "react-select";
import styled from "styled-components";
import { Colors } from "@flow/style";
import { Table } from "./Table";
import useCustomerMaps from "../hooks/useCustomerMaps";
import * as Dialog from "@radix-ui/react-dialog";
import Link from "./Link"

export default ({
  customer: selectedCustomer,
  carFilter,
  data,
  selectedRows,
  handleAllowedByAllCustomers,
  allowedByAllCustomers,
}) => {
  const customers = data;

  const subColumns = useMemo(
    () => [
      {
        Header: "KundeID",
        accessor: "customer_id",
      },
      {
        Header: "Navn",
        accessor: "name",
      },
    ],
    []
  );

  const [showOptions, showOptionsSet] = useState(false);
  const [customer, customerSet] = useState("");

  const {
    selectedCustomers,
    handleAddCustomerMap,
    handleRemoveCustomerMap,
    handleDeleteCustomerMap,
    saveCustomerMapChanges,
    deleteCustomerMapChanges,
    deleteAllCustomerMaps,
    isChanged,
  } = useCustomerMaps({ selectedCarFilter: carFilter, customers, customer: selectedCustomer });

  return (
    <React.Fragment>
      <SubTableContainer>
        <ButtonGroup toggle>
          <Link onClick={() => handleAllowedByAllCustomers(carFilter.id)}>
            {allowedByAllCustomers ? (
              <LabelText>Konverter til kundefilter</LabelText>
            ) : (
              <LabelText>Konverter til utstyrsfilter</LabelText>
            )}
          </Link>
        </ButtonGroup>
        <SubTableMenu>
          <label htmlFor="add-customer">
            {!allowedByAllCustomers ? (
              <LabelText>Gjelder: </LabelText>
            ) : (
              <LabelText>Gjelder ikke: </LabelText>
            )}
            <StyledInput
              options={showOptions ? customers : []}
              menuIsOpen={showOptions}
              value={customer}
              placeholder="Legg til kundenavn eller ID"
              disabled={allowedByAllCustomers}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              getOptionValue={(option) => `${option["id"]}`}
              getOptionLabel={(option) => `${option["name"]}`}
              filterOption={createFilter({ ignoreAccents: false })}
              onInputChange={(e) => showOptionsSet(e.length >= 3)}
              onChange={(e) => {
                handleAddCustomerMap({
                  customer_id: e.id,
                  filter_id: carFilter.id,
                });
                customerSet("");
              }}
            />
          </label>
          {selectedCustomers.length > 0 && (
            <Column>
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <Button size="small">Fjern alle</Button>
                </Dialog.Trigger>
                <StyledOverlay />
                <StyledContent onPointerDownOutside={false}>
                  <Dialog.Description>
                    Ønsker du å fjerne alle kunder fra dette filteret?
                  </Dialog.Description>
                  <Center>
                    <Button
                      size="small"
                      type="save"
                      onClick={() => deleteAllCustomerMaps(carFilter.id)}
                    >
                      Bekreft
                    </Button>
                    <Dialog.Close asChild>
                      <Button size="small">Avbryt</Button>
                    </Dialog.Close>
                  </Center>
                </StyledContent>
              </Dialog.Root>
            </Column>
          )}
        </SubTableMenu>
        {(selectedCustomers || []).length > 0 && (
          <Table
            columns={subColumns}
            data={selectedCustomers}
            renderRowSubComponent={{
              level: "customer",
              onRowClick: null,
              onActionClick: (row) => {
                handleDeleteCustomerMap(row);
              },
            }}
            selectedRows={selectedRows}
          />
        )}
        {isChanged && (
          <ButtonGroup>
            <Button
              type="save"
              onClick={() => {
                saveCustomerMapChanges();
                deleteCustomerMapChanges();
              }}
            >
              Lagre endringer
            </Button>
            <Button size="small" onClick={() => handleRemoveCustomerMap()}>
              Avbryt
            </Button>
          </ButtonGroup>
        )}
      </SubTableContainer>
    </React.Fragment>
  );
};

const SubTableContainer = styled.div`
  margin: 12px 0 12px 48px;
  width: 90%;
  display: flex;
  flex-direction: column;
`;

const SubTableMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 4px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 40%;
  align-items: flex-end;
  align-content: flex-end;
`;

const LabelText = styled.p`
  font-weight: 500;
  color: ${(props) => (props.disabled ? `${Colors.Grey1}` : "inherit")};
`;

const StyledInput = styled(Select)`
  width: 250px;
  margin: 2px 0 12px 0;
  border: 1px solid ${Colors.Grey2};
  border-radius: 4px;
`;

const ButtonGroup = styled.div`
  display: ${({ toggle }) => (toggle ? "inline-flex" : "flex")};
  justify-content: ${({ toggle }) => (!toggle ? "flex-start" : "")};
  gap: ${({ toggle }) => (!toggle ? "6px;" : "")};
  margin-bottom: 12px;
`;

const Button = styled.button`
  width: ${({ size }) => (size === "small" ? "80px" : "120px")};
  height: 36px;
  line-height: 15px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 4px;
  background-color: ${Colors.Grey4};
  border: 2px solid
    ${({ type }) => (type === "save" ? Colors.SeaLight : Colors.Grey3)};
  color: ${({ type }) => (type === "save" ? Colors.Sea : Colors.Grey1)};

  &:hover {
    cursor: pointer;
  }
`;

const StyledOverlay = styled(Dialog.Overlay)`
  position: absolute;
`;

const StyledContent = styled(Dialog.Content)`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid ${Colors.Grey3};
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 25px;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  gap: 6px;
  margin-top: 8px;
`;
