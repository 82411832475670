import React, { Component } from "react";
import styled from "styled-components";
import { Colors } from "@flow/style";
import CATEGORIES_NO from "./standardEquipmentHelpers/categories"


export default class StandardEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      selectedCategory: "Bremser",
      allEquipment: {},
      accessoriesToRender: [],
      categoriesToRender: [],
    };
  }


   // Helper function for finding category names.
   groupStandardEquipmentByCategory = standardEquipment => standardEquipment.reduce((acc, item) => {
    const category = CATEGORIES_NO[item.schemaId] || 'NO_CATEGORY';
    acc[category] = [...acc[category] || [], item];
    delete acc.NO_CATEGORY
    return acc;
  }, {});

  componentDidMount() {
    const { standardEquipment } = this.props;
    this.setState({ allEquipment: this.groupStandardEquipmentByCategory(standardEquipment) })

  }

  searchCategories = (searchString, allEquipment) => {
    // Sanitize search string before searching accessory object.
    const sanitizedSearchString = searchString.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&');
    const regex = new RegExp(`.*${sanitizedSearchString}`, 'i');
    const listOfAllCategories = Object.keys(allEquipment)
    const categoriesSearchResult = [];

    listOfAllCategories.forEach(category => {
      if (category.match(regex)) {
        categoriesSearchResult.push(category)
      }
    });

    return categoriesSearchResult
  }

  searchAccessories = (searchString, allEquipment) => {
    const sanitizedSearchString = searchString.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&');
    const regex = new RegExp(`.*${sanitizedSearchString}`, 'i')
    const listOfallEquipment = Object.values(allEquipment).flat()
    const accessorySearchResult = [];

    listOfallEquipment.forEach(accessory => {
      if (accessory.content.match(regex)) {
        accessorySearchResult.push(accessory)
      }
    });

    return accessorySearchResult
  }

  renderCategories = (searchString) => {
    const { allEquipment, categoriesToRender, selectedCategory } = this.state;

    // Render all accessories in the clicked category, unless a search is specified.
    if (!searchString) {
      return Object.keys(allEquipment).sort().map((category) => {
        return (
          <Category
            selected={category === selectedCategory}
            key={category}
            onClick={(e) => {
              this.setState({ selectedCategory: e.target.innerText })
            }}
          >
            {category}
          </Category>
        )
      })
    } else {
      // If a search is specified, we list all accessories that match the search (regardless of their categories)
      return categoriesToRender.sort().map(category => {
        return (
          <Category
            key={category}
            onClick={(e) => {
              this.setState({ selectedCategory: e.target.innerText, searchString: "" })
            }}
          >
            {category}
          </Category>
        )
      })
    }
  }

  renderAccessories = (searchString) => {
    const { allEquipment, selectedCategory, accessoriesToRender, categoriesToRender } = this.state;

    if (!searchString) {
      return allEquipment[selectedCategory].map(accessory => {
        return (
          <Accessory key={accessory.schemaId}>
            {accessory.content}
          </Accessory>)
      })
    } else {
      if (accessoriesToRender.length > 0) {
        return accessoriesToRender.map(accessory => {
          return (
            <Accessory key={accessory.schemaId}>
              {accessory.content}
            </Accessory>
          )
        })
      } else {
        if (categoriesToRender.length === 0) {
          return (
            <Accessory>
              Fant ingen standard utstyr eller kategorier som samsvarte med søket ditt på <b>"{`${searchString}`}"</b>
            </Accessory>
          )
        }
        return (
          <Accessory>
            Fant ingen standard utstyr som samsvarte med søket ditt på <b>{`${searchString}`}</b>
          </Accessory>
        )
      }

    }
  }


  render() {
    const { searchString, allEquipment } = this.state;

    if (allEquipment && Object.keys(allEquipment).length === 0) {
      return (<ModalContentFrame>
        <Items>
          <SelectedCategory>Henter standardutstyr...</SelectedCategory>
        </Items>
      </ModalContentFrame>)
    }

    return (
      <ModalContentFrame>
        <AccessoryCategories>
          <CategoryList>
            <SearchBar
              value={searchString}
              placeholder={"🔎 Søk i standardutstyr"}
              onChange={(e) => this.setState({
                searchString: e.target.value,
                categoriesToRender: this.searchCategories(e.target.value, allEquipment),
                accessoriesToRender: this.searchAccessories(e.target.value, allEquipment)
              })
              }
            />

            {this.renderCategories(searchString)}
          </CategoryList>
        </AccessoryCategories>
        <Items>
          <SelectedCategory>{searchString ?
            <>
              Søkeresultat
              <ToolTip href="#">
                <ToolTipText>
                  Resultatet viser alt av standardutstyr som matcher søket ditt <u>på tvers av alle kategorier</u>
                </ToolTipText>
              </ToolTip>
            </> : this.state.selectedCategory}
          </SelectedCategory>
          <AccessoryList>
            {this.renderAccessories(searchString)}
          </AccessoryList>
        </Items>
      </ModalContentFrame>
    );
  }
}


const AccessoryCategories = styled.div`
  width: 20%;
  flex-direction: column;
  overflow-y: auto;
`;

const SearchBar = styled.input`
  position: absolute;
  top: 0px;
  left: 20px;
  list-style-type: none;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 60px;
  flex-grow: 1;
  margin-left: 0;
  border: none;
  outline: none;
  margin-bottom: 10px;
`

const Items = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  border-left: 1px solid ${Colors.Grey3};
`;

const ModalContentFrame = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const SelectedCategory = styled.h2`
  padding-left: 120px;
  padding-top: 100px;
`;

const AccessoryList = styled.ul`
  padding-left: 200px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-size: 16px;
  line-height: 40px;
  color: #5E5B5B;
`;

const Accessory = styled.li``;

const Category = styled.li`
  padding-left: 20px;
  background: ${(props) => props.selected ? "linear-gradient(0deg, #DCEDFF, #DCEDFF), #FFFFFF" : "inherit"} ;
  box-shadow: ${(props) => props.selected ? "-2px 0px 0px #467EFF" : "inherit"};
`;

const CategoryList = styled.ul`
  list-style-type: none;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 60px;

  li:hover {
    background: linear-gradient(0deg, #DCEDFF, #DCEDFF), #FFFFFF;
    box-shadow: -2px 0px 0px #467EFF;
  }
`

const ToolTipText = styled.span`
  visibility: hidden;
  width: 22vw;
  background-color: ${Colors.Sea};
  font-size: 90%;
  color: white;
  text-align: center;
  padding: 25px 20px;
  border-radius: 6px;
  position: fixed;
  font-weight: normal;
  z-index: 10;

  &:hover {
  visibility: visible;
  }
`

//Info Popups on Hover
const ToolTip = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 1%;

  &:before{
  content: "?";
  color: white;
  display: inline-block;
  font-family: sans-serif;
  font-weight: bold;
  text-align: center;
  width: 1.8ex;
  height: 1.8ex;
  font-size: 1.4ex;
  line-height: 1.8ex;
  border-radius: 1.2ex;
  margin-right: 4px;
  padding: 1px;

  background: ${Colors.Sea};
  border: 1px solid ${Colors.Sea};
  text-decoration: none;
  }

  &:hover:before {
  color: white;
  background: black;
  border-color: white;
  text-decoration: none;
  }

  &:hover {
  ${ToolTipText} {
    visibility: visible;
  }
  }
`
