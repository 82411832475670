import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import { Spinner } from "@flow/icons";
import { getBodies } from "./requests";
import BodyRow from "./components/BodyRow";
import { SelectionBox, Header, HeaderItem } from "./Styles";
import { LoadingBox } from "./components/LoadingBox";

export default ({ carConfig, jatoData, vehicleType, cacheJatoData, onSelectBody }) => {
  const [bodies, bodiesSet] = useState([])
  const [uniqueBodyTypes, uniqueBodyTypesSet] = useState([])
  const [_, vehicleTypeSet] = useState("")
  const [selectedBody, selectedBodySet] = useState([])
  const [loading, loadingSet] = useState(true)

  useEffect(() => {
    if (jatoData.bodies && !jatoData.bodies.length > 0) {
      getBodies(carConfig.make, carConfig.model, carConfig.carYear, vehicleType, bodies => {
        const bodyTypes = bodies.map(body => body.shortBodyName);
        const uniqueBodyTypes = Array.from(new Set(bodyTypes));

        bodiesSet(bodies)
        uniqueBodyTypesSet(uniqueBodyTypes)
        loadingSet(false)
        vehicleTypeSet(vehicleType)
        cacheJatoData({ jatoData: { ...jatoData, bodies }, vehicleType });
      });
    } else {
      const bodies = jatoData.bodies;
      const bodyTypes = bodies.map(body => body.shortBodyName);
      const uniqueBodyTypes = Array.from(new Set(bodyTypes));

      bodiesSet(bodies)
      uniqueBodyTypesSet(uniqueBodyTypes)
      loadingSet(false)
    }
  }, [])

  const updateFilter = (filter) => {
    let selectedFilter = [];
    if (filter === "Alle") {
      selectedFilter = [];
    } else if (selectedBody.some(el => el === filter)) {
      selectedFilter = selectedBody.filter(el => el !== filter);
    } else {
      selectedFilter = [...selectedBody, filter];
    }

    selectedBodySet(selectedFilter)
  }

  const bodyList = bodies.reduce((acc, body) => {
    const canBeFromBody = selectedBody.length === 0 || selectedBody.some(el => el === body.shortBodyName);

    if (canBeFromBody) {
      return [
        ...acc,
        <BodyRow
          key={body.bodyCode + "-" + body.drivenWheels}
          bodyName={body.shortBodyName}
          body={body}
          imageUrl={body.photoUrl}
          year={body.modelYear}
          drivenWheels={body.drivenWheels}
          numberOfDoors={body.numberOfDoors}
          priceFrom={body.minPriceToDisplay}
          priceTo={body.maxPriceToDisplay}
          onClick={onSelectBody}
        />
      ];
    }
    return acc;
  }, []);

  const bodyTypeSelectionList = uniqueBodyTypes.map(bodyType => {
    return (
      <SelectionBox
        key={bodyType}
        onClick={() => updateFilter(bodyType)}
        selected={selectedBody.some(el => el === bodyType)}
      >
        {bodyType}
      </SelectionBox>
    );
  });
  return (
    <BodySection>
      <BodyLabel>Vis type</BodyLabel>
      <BodyFilters>
        <SelectionBox onClick={() => updateFilter("Alle")} selected={selectedBody.length === 0}>
          Alle
        </SelectionBox>
        {bodyTypeSelectionList}
      </BodyFilters>
      <BodyHeader>
        <HeaderItem />
        <HeaderItem>MODELL</HeaderItem>
        <HeaderItem>ÅRSMODELL</HeaderItem>
        <HeaderItem>DRIFT</HeaderItem>
        <HeaderItem>DØRER</HeaderItem>
        <HeaderItem>PRIS KR</HeaderItem>
      </BodyHeader>
      <BodyList loading={loading}>
        {loading ? (
          <LoadingBox>
            <Spinner />
            <p>Laster karosserityper fra Jato</p>
          </LoadingBox>
        ) : (
            bodyList
          )}
      </BodyList>
    </BodySection>
  );
}

export const BodyLabel = styled.div`
  font-size: 12px;
  text-align: left;
  line-height: 16px;
  color: #6c6c6c;
`;

export const BodySection = styled.div`
  height: calc(100% - 80px);
  margin-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-top: 27px;
  width: -webkit-fill-available;
  width: -moz-available;
`;

export const BodyFilters = styled.div`
  text-align: left;
  padding-bottom: 20px;
`;

export const BodyList = styled.div`
  height: calc(100% - 145px);
  display: ${props => (props.loading ? "flex" : "block")};
  flex-direction: ${props => (props.loading ? "row" : "unset")};
  justify-content: ${props => (props.loading ? "center" : "unset")};
  align-items: ${props => (props.loading ? "center" : "baseline")};
`;

export const BodyHeader = styled(Header)`
  grid-template-columns: 136px 150px 100px 100px 100px 1fr;
`;
