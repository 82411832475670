import React, { Component } from "react";
import styled from "styled-components";
import lodash from "lodash";
import { Colors } from "@flow/style";

export default class NavigationMenu extends Component {
  generateStatus(status) {
    const [trueSteps, falseSteps] = lodash.partition(status, el => el);
    const mapTrueSteps = trueSteps.map(el => "done");
    const mapFalseSteps = falseSteps.map((el, i) => (i === 0 ? "current" : "todo"));
    if (mapFalseSteps.length === 0) {
      const stepsArray = [...mapTrueSteps];
      stepsArray.splice(stepsArray.length - 1, 1, "current");
      return stepsArray;
    } else {
      return [...mapTrueSteps, ...mapFalseSteps];
    }
  }

  setStatus(status, title, onClick) {
    switch (status) {
      case "current":
        return <CurrentStep>{title}</CurrentStep>;
      case "todo":
        return <ToDoStep>{title}</ToDoStep>;
      case "done":
        return <DoneStep onClick={onClick}>{title}</DoneStep>;
      default:
        break;
    }
  }

  render() {
    const stepStatus = this.generateStatus(this.props.steps);
    return (
      <Navigation>
        {this.setStatus(stepStatus[0], "Type", this.props.onTypeClick)}
        <Seperator>/</Seperator>
        {this.setStatus(stepStatus[1], "Merke", this.props.onMakeClick)}
        <Seperator>/</Seperator>
        {this.setStatus(stepStatus[2], "Modell", this.props.onModelClick)}
        <Seperator>/</Seperator>
        {this.setStatus(stepStatus[3], "Karosseri", this.props.onBodyClick)}
        <Seperator>/</Seperator>
        {this.setStatus(stepStatus[4], "Motor", this.props.onEngineClick)}
        <Seperator>/</Seperator>
        {this.setStatus(stepStatus[5], "Tilleggsutstyr")}
      </Navigation>
    );
  }
}

const Navigation = styled.div`
  height: 30px;
  display: flex;
  padding-left: 50px;
  padding-top: 30px;
  padding-bottom: 20px;
`;

const Seperator = styled.p`
  padding-left: 4px;
  padding-right: 4px;
  color: ${Colors.Grey2};
`;

const ToDoStep = styled.p`
  color: ${Colors.Grey2};
`;

const CurrentStep = styled.p`
  color: ${Colors.Coal};
`;

const DoneStep = styled.a`
  color: ${Colors.Sea};
  text-decoration: underline;
  cursor: pointer;
`;
