import React from 'react'
import styled from 'styled-components'

const Link = ({ onClick, children }) => {
  return <StyledLink
    onClick={onClick}
  >
    {children}
  </StyledLink>
}

const StyledLink = styled.a`
  color: #2a6df4;
  cursor: pointer;
  
  :hover {
    text-decoration: underline;
  }
  :active, :visited {
    color: inherit;
  }
  
  ::before {
    content: ' ';
  }
`

export default Link
