import React, { Component } from "react";
import styled from "styled-components";
import { Icons } from "@flow/icons";
import { Colors } from "@flow/style";

export default class ContextEntry extends Component {
  render() {
    const marginText = this.props.marginText || 3;
    let key = 0;
    return (
      <EntryFrame style={this.props.style}>
        <p style={{ color: Colors.Grey2, fontSize: 12, marginBottom: 8 }}>{this.props.title}</p>
        <p style={{ fontSize: 16, fontWeight: 400, marginBottom: 8 }}>{this.props.main}</p>
        {this.props.subTitles.map(elem =>
          this.props.textInTlf ? (
            <TelephoneFrame key={key++} style={{ width: "auto" }}>
              <div style={{ marginLeft: 5, marginTop: 4 }}>
                <Icons.NewTab color={Colors.Coal} />
              </div>
              <p style={{ marginBottom: 1, fontSize: 14, margin: 10, marginLeft: 5 }}>
                <a
                  style={{ textDecoration: "none", color: "inherit" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.volvocars.com/no/build/xc/xc60#r-design"
                >
                  {elem}
                </a>
              </p>
            </TelephoneFrame>
          ) : (
              <p key={key++} style={{ fontSize: 14, marginTop: marginText, color: Colors.Grey1 }}>
                {elem}
              </p>
            )
        )}
        {this.props.tlf ? (
          <TelephoneFrame>
            <div style={{ marginLeft: -5, marginTop: 4 }}>
              <Icons.Phone color={Colors.Coal} />
            </div>{" "}
            <p style={{ marginLeft: -20, marginBottom: 1, fontSize: 14 }}>
              {`
                    ${this.props.tlf.slice(0, 2)} 
                    ${this.props.tlf.slice(2, 4)} 
                    ${this.props.tlf.slice(4, 6)} 
                    ${this.props.tlf.slice(6, 8)}
                `}
            </p>
          </TelephoneFrame>
        ) : (
            ""
          )}
      </EntryFrame>
    );
  }
}

const TelephoneFrame = styled.div`
  transition: all 0.2s ease-out;
  width: 120px;
  min-height: 35px !important;
  background-color: #e5f2ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 5px;
  cursor: pointer;

  &:hover {
    background-color: #e5edff;
  }
`;

const EntryFrame = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${props => (props.style && props.style.marginLeft ? props.style.marginLeft : "20px")};
  margin-top: ${props => (props.style && props.style.marginTop ? props.style.marginTop : "5px")};
  margin-bottom: ${props => (props.style && props.style.marginBottom ? props.style.marginBottom : "10px")};
`;
