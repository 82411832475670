import { getStandardEquipment } from "./Request";
import React, { Component } from "react";
import styled from "styled-components";
import lodash from "lodash";
import { getVehiclesBuilder } from "./Request";
import { Colors } from "@flow/style";
import { Spinner } from "@flow/icons";
import PacketRow from "./components/PacketRow";
import OptionsRow from "./components/OptionsRow";
import { SelectionBox } from "./Styles";
import { LoadingBox } from "./components/LoadingBox";
import { Checkbox } from "@flow/forms";

export default class Accessories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accessories: { packages: [], options: {} },
      excludeList: [],
      selectedOptions: [],
      accessoriesPrice: 0,
      optionGroups: [],
      packetGroups: [],
      selectedFilter: {
        options: [],
        packets: []
      },
      requires: {},
      optionNames: {},
      loading: true,
      co2Emission: 0,
      mainServiceDistKm: 0,
      mainServicePeriodMonth: 0,
      interServiceDistKm: 0,
      interServicePeriodMonth: 0,
      noxEmission: 0,
      fuelConsumption: 0,
      imageUrl: "",
      firstRegistrationFee: 0,
      vatPrice: 0,
      carNotFound: false,
      accessoriesNotFound: false,
      warningCheck: false,
    };

    this.getAccessoriesFromJato = this.getAccessoriesFromJato.bind(this);
    this.onSelectOption = this.onSelectOption.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.handleCo2EmissionChange = this.handleCo2EmissionChange.bind(this);
  }

  componentDidMount() {
    const { carConfig } = this.props;
    const { vehicleId, accessoriesList, vehicleType } = carConfig;
    this.getAccessoriesFromJato(vehicleId, vehicleType, accessoriesList);
    getStandardEquipment(vehicleId, allAccessories => {
      this.props.setStandardEquipment(allAccessories)
    });
  }

  

  getAccessoriesFromJato(vehicleId, vehicleType, accessoriesList) {
    this.setState({ loading: true });
    getVehiclesBuilder(vehicleId, vehicleType, accessories => {
      if (accessories === null) {
        return this.setState({ loading: false, accessoriesNotFound: true });
      }

      if (Object.keys(accessories).length === 0) {
        return this.setState({ ...this.state, carNotFound: true, loading: false, accessoriesNotFound: false });
      }

      const { options, packages, vehiclePerformance, vehiclePhotos, vehicleEbrochurePage } = accessories;
      let optionGroups = [];
      let packetGroups = [];
      let optionNames = {};

      lodash.forEach(options, (option, key) => {
        optionGroups = [...optionGroups, key];
        option.forEach(opt => {
          optionNames[opt.optionId] = opt.optionName;
        });
      });

      const uniquePacket = lodash.uniqBy(packages, packet => packet.categoryName);

      lodash.forEach(uniquePacket, packet => {
        packetGroups = [...packetGroups, packet.categoryName];
        optionNames[packet.optionId] = packet.optionName;
      });

      let co2Emission = null;
      let mainServiceDistKm = 0;
      let mainServicePeriodMonth = 0;
      let interServiceDistKm = 0;
      let interServicePeriodMonth = 0;
      let noxEmission = 0;
      let fuelConsumption = 0;
      let firstRegistrationFee = 0;
      let vatPrice = 0;
      let imageUrl = "";
      let chargable = false;
      let EUClass = "";

      if (vehicleEbrochurePage) {
        const { pageItem } = vehicleEbrochurePage;
        const co2Item = pageItem.find(item => item.schemaId === 62203 && typeof item.dataValue === "string" && item.dataValue.length > 0);
        const mainServiceDistKmItem = pageItem.find(item => item.schemaId === 38802 && item.dataValue.length > 0);
        const mainServicePeriodMonthItem = pageItem.find(item => item.schemaId === 38803 && item.dataValue.length > 0);
        const interServiceDistKmItem = pageItem.find(item => item.schemaId === 38702 && item.dataValue.length > 0);
        const interServicePeriodMonthItem = pageItem.find(item => item.schemaId === 38703 && item.dataValue.length > 0);
        const chargableItem = pageItem.find(item => item.schemaId === 48602 && item.dataValue === "P");
        const EUClassItem = pageItem.find(item => item.schemaId === 7602 && item.dataValue.length > 0);

        if (co2Item) {
          co2Emission = Number(co2Item.dataValue);
        }
        if (mainServiceDistKmItem) {
          mainServiceDistKm = Number(mainServiceDistKmItem.dataValue);
        }
        if (mainServicePeriodMonthItem) {
          mainServicePeriodMonth = Number(mainServicePeriodMonthItem.dataValue);
        }
        if (interServiceDistKmItem) {
          interServiceDistKm = Number(interServiceDistKmItem.dataValue);
        }
        if (interServicePeriodMonthItem) {
          interServicePeriodMonth = Number(interServicePeriodMonthItem.dataValue);
        }
        if (EUClassItem) {
          EUClass = EUClassItem.dataValue;
        }
        chargable = Boolean(chargableItem);
      }

      if (vehiclePerformance) {
        noxEmission = vehiclePerformance.noxEmission;
        // co2Emission = vehiclePerformance.co2LevelgKm7603Value;
        fuelConsumption = vehiclePerformance.fuelConsumptionKm42005Value === null ? 0 : vehiclePerformance.fuelConsumptionKm42005Value;
        firstRegistrationFee = vehiclePerformance.firstRegistrationFee;
        vatPrice = vehiclePerformance.vatPrice;
      }
      if (vehiclePhotos) imageUrl = vehiclePhotos[0];

      if (!accessoriesList || accessoriesList.length < 1) {
        this.props.onSelectAccessories(
          0,
          [],
          co2Emission,
          mainServiceDistKm,
          mainServicePeriodMonth,
          interServiceDistKm,
          interServicePeriodMonth,
          noxEmission,
          fuelConsumption,
          imageUrl,
          firstRegistrationFee,
          vatPrice,
          chargable,
          EUClass,
        );
        return this.setState({
          accessories,
          optionGroups,
          packetGroups,
          optionNames,
          loading: false,
          co2Emission,
          mainServiceDistKm,
          mainServicePeriodMonth,
          interServiceDistKm,
          interServicePeriodMonth,
          noxEmission,
          fuelConsumption,
          imageUrl,
          firstRegistrationFee,
          vatPrice,
          accessoriesNotFound: false,
          chargable,
          EUClass,
        });
      }

      const optionsDuplicate = lodash.reject(lodash.cloneDeep(options), lodash.isEmpty);
      //const mergedList = packages.concat(optionsDuplicate);

      let newList = [];
      let totalPrice = 0;
      packages.forEach(packet => {
        if (Array.isArray(packet)) {
          const optionAccessorie = packet.filter(el =>
            accessoriesList.some(acc => (acc.optionId ? parseInt(acc.optionId) === el.optionId : acc === el.optionId))
          );
          if (optionAccessorie.length >= 1) {
            lodash.forEach(optionAccessorie, o => {
              totalPrice += o.retailPrice902;
              newList = [...newList, o];
            });
          }
        } else if (
          accessoriesList.some(acc => (acc.optionId ? parseInt(acc.optionId) === packet.optionId : acc === packet.optionId))
        ) {
          totalPrice += packet.retailPrice902;
          newList = [...newList, packet];

          //Add elements in attributes if it is not spesifed in includes
          let extraOptions = [];
          if (packet.attributes && packet.attributes.length > 0) {
            let modifiedAttributes = packet.attributes.filter(atName => !atName.includes("Includes") && !atName.includes("Inkluderer") && !atName.includes("And") && !atName.includes("Og"));

            //Removed requires/krever and the elements below
            //This is a bad solution since we can't be sure it will always come last in the list
            if (modifiedAttributes.includes("Requires:#-#Krever:")) {
              const requiresIndex = modifiedAttributes.indexOf("Requires:#-#Krever:");
              modifiedAttributes = modifiedAttributes.slice(0, requiresIndex);
            }

            //Split name if length is longer than 240 characters
            modifiedAttributes.forEach((atName, key) => {
              if (atName.length > 240) {
                let lastWhiteSpaceIndex = atName.substring(0, 240).lastIndexOf(" ");
                modifiedAttributes[key] = atName.substring(0, lastWhiteSpaceIndex);
                modifiedAttributes.splice(key + 1, 0, atName.substring(lastWhiteSpaceIndex, atName.length));
              }
            });

            modifiedAttributes.forEach((atName, key) => {
              extraOptions.push({ optionId: `${packet.optionId}-${key}`, price: 0, optionName: `-${atName}`, excludes: [], includes: [], requires: [], basePrice903: 0 });
            });
          }
          if (extraOptions.length > 0) newList = newList.concat(extraOptions);
        }
      });

      optionsDuplicate.forEach(option => {
        if (Array.isArray(option)) {
          const optionAccessorie = option.filter(el =>
            accessoriesList.some(acc => (acc.optionId ? parseInt(acc.optionId) === el.optionId : acc === el.optionId))
          );
          if (optionAccessorie.length >= 1) {
            lodash.forEach(optionAccessorie, o => {
              totalPrice += o.retailPrice902;
              newList = [...newList, o];
            });
          }
        } else if (
          accessoriesList.some(acc => (acc.optionId ? parseInt(acc.optionId) === option.optionId : acc === option.optionId))
        ) {
          totalPrice += option.retailPrice902;
          newList = [...newList, option];
        }
      });

      const excludeListPre = newList.map(el => el.excludes);
      const excludeList = lodash.flatten(excludeListPre);
      const optionsList = newList.map(el => ({
        optionId: el.optionId,
        name: el.optionName,
        price: el.price,
        basePrice: el.basePrice903,
        accessoriesWithoutDiscount: el.optionName.toUpperCase().includes("LOY") ? "TRUE" : "FALSE"
      }));

      this.props.onSelectAccessories(
        totalPrice,
        optionsList,
        co2Emission,
        mainServiceDistKm,
        mainServicePeriodMonth,
        interServiceDistKm,
        interServicePeriodMonth,
        noxEmission,
        fuelConsumption,
        imageUrl,
        firstRegistrationFee,
        vatPrice,
        chargable,
        EUClass,
      );
      this.setState({
        accessories,
        selectedOptions: newList,
        excludeList,
        accessoriesPrice: totalPrice,
        optionGroups,
        packetGroups,
        optionNames,
        loading: false,
        co2Emission,
        mainServiceDistKm,
        mainServicePeriodMonth,
        interServiceDistKm,
        interServicePeriodMonth,
        noxEmission,
        fuelConsumption,
        imageUrl,
        firstRegistrationFee,
        vatPrice,
        accessoriesNotFound: false,
        chargable,
        EUClass
      });
    });
  }

  updateFilter(type, value) {
    let selectedFilter = {};
    switch (type) {
      case "option":
        if (value === "Alle") {
          selectedFilter = { ...this.state.selectedFilter, options: [] };
        } else if (this.state.selectedFilter.options.some(el => el === value)) {
          selectedFilter = {
            ...this.state.selectedFilter,
            options: this.state.selectedFilter.options.filter(el => el !== value)
          };
        } else {
          selectedFilter = { ...this.state.selectedFilter, options: [...this.state.selectedFilter.options, value] };
        }
        break;
      case "packet":
        if (value === "Alle") {
          selectedFilter = { ...this.state.selectedFilter, packets: [] };
        } else if (this.state.selectedFilter.packets.some(el => el === value)) {
          selectedFilter = {
            ...this.state.selectedFilter,
            packets: this.state.selectedFilter.packets.filter(el => el !== value)
          };
        } else {
          selectedFilter = { ...this.state.selectedFilter, packets: [...this.state.selectedFilter.packets, value] };
        }
        break;
      default:
        selectedFilter = this.state.selectedFilter;
        break;
    }

    this.setState({ selectedFilter });
  }

  onSelectOption(optionId, price, name, excludes, includes = [], requires = [], basePrice, attributes = []) {
    let newList;
    let totalPrice;

    const selectedOption = { optionId, price, optionName: name, excludes, includes, requires, basePrice903: basePrice };
    if (this.state.selectedOptions.some(el => el.optionId === optionId)) {
      newList = this.state.selectedOptions.filter(el => el.optionId !== optionId);
      //Remove elements that were in the attributes
      newList = newList.reduce((acc, el) => {
        const isPartOfPacket = typeof el.optionId === "string" && el.optionId.includes(optionId);
        if (!isPartOfPacket) {
          return [
            ...acc,
            el
          ];
        }
        return acc;
      }, []);
      totalPrice = this.state.accessoriesPrice - price;

      if (this.state.requires[optionId]) {
        delete this.state.requires[optionId];
      }

      Object.keys(this.state.requires).forEach(key => {
        this.state.requires[key].requires.forEach(required => {
          if (required.isIncluded) {
            let isStillIncluded = false;
            required.requireOne.forEach(requiredOption => {
              if (requiredOption.isIncluded) {
                if (optionId === requiredOption.optionId) {
                  requiredOption.isIncluded = false;
                } else {
                  isStillIncluded = true;
                }
              }
            });

            if (!isStillIncluded) {
              required.isIncluded = false;
            }
          }
        });
      });
    } else {
      newList = [...this.state.selectedOptions, selectedOption];
      totalPrice = this.state.accessoriesPrice + price;

      //Add elements in attributes if it is not spesifed in includes
      let extraOptions = [];
      if (attributes && attributes.length > 0) {
        let modifiedAttributes = attributes.filter(atName => !atName.includes("Includes") && !atName.includes("Inkluderer") && !atName.includes("And") && !atName.includes("Og"));

        //Removed requires/krever and the elements below
        //This is a bad solution since we can't be sure it will always come last in the list
        if (modifiedAttributes.includes("Requires:#-#Krever:")) {
          const requiresIndex = modifiedAttributes.indexOf("Requires:#-#Krever:");
          modifiedAttributes = modifiedAttributes.slice(0, requiresIndex);
        }

        //Split name if length is longer than 240 characters
        modifiedAttributes.forEach((atName, key) => {
          if (atName.length > 240) {
            let lastWhiteSpaceIndex = atName.substring(0, 240).lastIndexOf(" ");
            modifiedAttributes[key] = atName.substring(0, lastWhiteSpaceIndex);
            modifiedAttributes.splice(key + 1, 0, atName.substring(lastWhiteSpaceIndex, atName.length));
          }
        });

        modifiedAttributes.forEach((atName, key) => {
          extraOptions.push({ optionId: `${optionId}-${key}`, price: 0, optionName: `-${atName}`, excludes: [], includes: [], requires: [], basePrice903: 0 });
        });
      }
      if (extraOptions.length > 0) newList = newList.concat(extraOptions);

      const newRequires = [];
      requires.forEach(required => {
        const newRequired = { requireOne: [], isIncluded: false };
        required.optionId.forEach(requiredOption => {
          const newRequiredOption = {
            optionId: requiredOption,
            isIncluded: false
          };

          newRequired.requireOne.push(newRequiredOption);
          this.state.selectedOptions.some(selectedOption => {
            if (selectedOption.optionId === requiredOption) {
              newRequired.isIncluded = true;
              newRequiredOption.isIncluded = true;
              return true;
            }
          });
        });
        newRequires.push(newRequired);
      });

      Object.keys(this.state.requires).forEach(key => {
        this.state.requires[key].requires.forEach(required => {
          required.requireOne.forEach(requiredOption => {
            if (requiredOption.optionId === optionId) {
              requiredOption.isIncluded = true;
              if (!required.isIncluded) {
                required.isIncluded = true;
              }
            }
          });
        });
      });

      const updatedRequires = { ...this.state.requires, [optionId]: { requires: newRequires, name } };
      this.setState({ requires: updatedRequires });
    }

    const optionsList = newList.map(el => ({
      optionId: el.optionId,
      price: el.price,
      name: el.optionName,
      basePrice: el.basePrice903,
      accessoriesWithoutDiscount: el.optionName.toUpperCase().includes("LOY") ? "TRUE" : "FALSE"
    }));

    const excludeListPre = newList.map(el => el.excludes);
    const excludeList = lodash.flatten(excludeListPre);

    this.props.onSelectAccessories(
      totalPrice,
      optionsList,
      this.state.co2Emission,
      this.state.mainServiceDistKm,
      this.state.mainServicePeriodMonth,
      this.state.interServiceDistKm,
      this.state.interServicePeriodMonth,
      this.state.noxEmission,
      this.state.fuelConsumption,
      this.state.imageUrl,
      this.state.firstRegistrationFee,
      this.state.vatPrice,
      this.state.chargable,
      this.state.EUClass,
    );
    this.setState({ selectedOptions: newList, excludeList, accessoriesPrice: totalPrice });
  }
  generateRequires() {
    const items = [];
    Object.keys(this.state.requires).forEach(key => {
      this.state.requires[key].requires.forEach(required => {
        if (!required.isIncluded) {
          const divList = [];

          for (let i = 0; i < required.requireOne.length; i++) {
            if (i === 0) {
              divList.push(<RequiresSpan>{this.state.optionNames[required.requireOne[i].optionId]}</RequiresSpan>);
            } else {
              divList.push(" eller ");
              divList.push(<RequiresSpan>{this.state.optionNames[required.requireOne[i].optionId]}</RequiresSpan>);
            }
          }

          divList.push(` er påkrevd av ${this.state.requires[key].name}`);

          items.push(<div>{divList}</div>);
        }
      });
    });

    return items;
  }

  /**
   * This event is triggered everytime the checkbox is being checked. The const is a boolean and
   * is passed further to the createCarConfiguration.js as a param to handle disabledCompleteButton.
   * @param {*} event checkbox event
   */
  handleCo2EmissionChange(event) {
    const ignoreWarningMessage = event.target.checked;
    this.props.updateCo2(ignoreWarningMessage);
  }

  render() {
    const { carConfig } = this.props;
    const { vehicleId, accessoriesList } = carConfig;
    if (!this.state.carNotFound) {
      const packetList = this.state.accessories.packages.reduce((acc, packet) => {
        const canBeFromPackets =
          this.state.selectedFilter.packets.length === 0 ||
          this.state.selectedFilter.packets.some(el => el === packet.categoryName);

        if (canBeFromPackets) {
          const isSelected = this.state.selectedOptions.some(el => el.optionId === packet.optionId);
          const isActive = !this.state.excludeList.some(el => el === packet.optionId);
          return [
            ...acc,
            <PacketRow
              key={packet.optionId}
              selected={isSelected}
              id={packet.optionId}
              name={packet.optionName}
              price={packet.retailPrice902}
              basePrice={packet.basePrice903}
              attributes={packet.attributes}
              excludes={packet.excludes}
              includes={packet.includes}
              requires={packet.requires}
              isActive={isActive}
              onSelect={this.onSelectOption}
            />
          ];
        }
        return acc;
      }, []);

      const optionsList = [];
      const { onSelectOption } = this;
      const { selectedOptions } = this.state;
      const { selectedFilter } = this.state;
      const { excludeList } = this.state;
      lodash.forEach(this.state.accessories.options, (option, key) => {
        const canBeFromOptions =
          selectedFilter.options.length === 0 || selectedFilter.options.some(el => lodash.lowerFirst(el) === key);

        if (canBeFromOptions) {
          let subOptionList = [];
          if (option.length > 0) {
            subOptionList = option.map(o => {
              const isSelected =
                selectedOptions.filter(el => el.optionId === o.optionId || el.includes.includes(o.optionId)).length > 0;
              const isActive =
                (!excludeList.some(el => el === o.optionId) && !selectedOptions.some(el => el.includes.includes(o.optionId))) ||
                !selectedOptions.some(el => el.includes.includes(o.optionId));
              return (
                <OptionsRow
                  key={o.optionId}
                  selected={isSelected}
                  id={o.optionId}
                  name={o.optionName}
                  price={o.retailPrice902}
                  basePrice={o.basePrice903}
                  attributes={o.attributes}
                  excludes={o.excludes}
                  includes={o.includes}
                  requires={o.requires}
                  isActive={isActive}
                  onSelect={onSelectOption}
                />
              );
            });
          }

          if (subOptionList.length > 0) {
            optionsList.push(
              <PacketSection key={key}>
                <Title>{key} </Title>
                {subOptionList}
              </PacketSection>
            );
          }
        }
      });

      const optionSelectionList = this.state.optionGroups.map(option => (
        <SelectionBox
          key={option}
          onClick={() => this.updateFilter("option", option)}
          selected={this.state.selectedFilter.options.some(el => el === option)}
        >
          {lodash.capitalize(option)}
        </SelectionBox>
      ));

      const packetSelectionList = this.state.packetGroups.map(packet => (
        <SelectionBox
          key={packet}
          onClick={() => this.updateFilter("packet", packet)}
          selected={this.state.selectedFilter.packets.some(el => el === packet)}
        >
          {packet}
        </SelectionBox>
      ));

      return (
        <AccessoriesSection>
          {this.state.loading ? (
            <LoadingBox>
              <Spinner />
              <p>Laster tilleggsutstyr fra Jato</p>
            </LoadingBox>
          ) : (
            <div>
              <FilterSection>
                {optionSelectionList.length > 0 ? (
                  <Options>
                    <OptionLabel>Tilleggsvalg</OptionLabel>
                    <OptionSelectionList>
                      <SelectionBox
                        key="alle-option"
                        onClick={() => this.updateFilter("option", "Alle")}
                        selected={this.state.selectedFilter.options.length === 0}
                      >
                        Alle
                      </SelectionBox>
                      {optionSelectionList}
                    </OptionSelectionList>
                  </Options>
                ) : (
                  <div />
                )}

                {packetSelectionList.length > 0 ? (
                  <Options>
                    <OptionLabel>Pakker</OptionLabel>
                    <OptionSelectionList>
                      <SelectionBox
                        key="alle-packet"
                        onClick={() => this.updateFilter("packet", "Alle")}
                        selected={this.state.selectedFilter.packets.length === 0}
                      >
                        Alle
                      </SelectionBox>
                      {packetSelectionList}
                    </OptionSelectionList>
                  </Options>
                ) : (
                  <div />
                )}
              </FilterSection>

              {this.generateRequires()}
              {this.props.co2NotFound && <PaddedDiv style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <p><span style={{ color: "#ff1c24" }}>Bilmodellen har ikke oppgitt CO2. Biltype har muligens ikke krav til utslippsdata</span></p>
                <Checkbox label="Overfør konfigurasjon til Core" id="mycheckbox" name="mycheckbox" type="checkbox" onChange={this.handleCo2EmissionChange} />
              </PaddedDiv>}
              <AccessorieList>
                {!this.state.accessoriesNotFound ? (
                  (packetList.length > 0 || optionsList.length > 0 ? <PaddedDiv>
                    <PacketSection>
                      <Title>Pakker</Title>
                      {packetList}
                    </PacketSection>
                    {optionsList}
                  </PaddedDiv> : <div>Denne bilen har ingen tilleggsutstyr</div>)
                ) : (
                  <PaddedDiv style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <p style={{ paddingBottom: "20px" }}>Kunne ikke hente ut tilleggsutstyr fra Jato</p>
                    <DefaultButton onClick={() => this.getAccessoriesFromJato(vehicleId, accessoriesList)}>
                      Prøv på nytt
                    </DefaultButton>
                  </PaddedDiv>
                )}
              </AccessorieList>
            </div>
          )}
        </AccessoriesSection>
      );
    } else {
      return (
        <AccessoriesSection>
          <AccessorieList>
            {this.state.loading ? (
              <LoadingBox>
                <Spinner />
                <p>Laster tilleggsutstyr fra Jato</p>
              </LoadingBox>
            ) : (
              <PaddedDiv style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <p style={{ textAlign: "center" }}>Kunne ikke finne igjen bilen hos Jato.</p>
                <p style={{ paddingBottom: "20px" }}>Start konfigurering på nytt ved å trykke på knappen under</p>
                <DefaultButton onClick={() => this.props.onSetStart()}>Start konfigurering på nytt</DefaultButton>
              </PaddedDiv>
            )}
          </AccessorieList>
        </AccessoriesSection>
      );
    }
  }
}

export const AccessoriesSection = styled.div`
  height: calc(100% - 80px);
  margin-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-top: 27px;
  width: -webkit-fill-available;
  width: -moz-available;
`;

const AccessorieList = styled.div`
  display: flex;
  justify-content: center;
  overflow-x: hidden;
`;

const PacketSection = styled.div``;

const Title = styled.div`
  font-size: 12px;
  color: ${Colors.Grey1};
  border-bottom: 1px solid #e4e2e2;
  padding-top: 30px;
  text-transform: capitalize;
`;

const PaddedDiv = styled.div`
  padding-bottom: 20px;
  width: 90%;
`;

const RequiresSpan = styled.span`
  color: #721c24;
`;


export const OptionLabel = styled.div`
  font-size: 12px;
  text-align: left;
  line-height: 16px;
  color: ${Colors.Grey1};
`;
export const FilterSection = styled.div`
  display: flex;
`;

export const OptionSelectionList = styled.div`
  text-align: left;
  padding-bottom: 20px;
`;

export const Options = styled.div`
  flex-grow: 1;
`;

const DefaultButton = styled.button`
  width: 200px;
  height: 40px;
  line-height: 15px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  margin: 0px 4px 0px 4px;
  padding: 0px 10px 0px 10px;
  background-color: ${Colors.Grey4};
  border: 2px solid ${Colors.SeaLight};
  color: ${Colors.Sea};
  &:hover {
    cursor: pointer;
  }
`;