import { useMutation, useQueryClient, useQuery } from "react-query";
import axios from "axios";
import { useMemo, useState } from "react";

const formatCustomer = (customer, customers) => {
  if (!customers) {
    return []
  }
  
  return {
    ...customer,
    name: customers.filter(o => o.id === customer.customer_id).map(cust => cust.name).join(", ")
  }
}

export default ({ selectedCarFilter, customers, customer }) => {
  const queryClient = useQueryClient()

  const { data: customerMaps } = useQuery(["customer-maps-db-data", selectedCarFilter.id], async () => {
    const res = await axios.get(`/api/customer-filter-maps?filterId=${selectedCarFilter.id}`)
    return res.data
  })

  const [selectedCustomersToBeDeleted, selectedCustomersToBeDeletedSet] = useState([])
  const [selectedCustomersToBeAdded, selectedCustomersToBeAddedSet] = useState([])

  const selectedCustomers = useMemo(() => {
    if (!customerMaps) return [];
    return [
      ...customerMaps
      .filter(existingMap => !selectedCustomersToBeDeleted.some(toBeDeleted => existingMap.customer_id === toBeDeleted.customer_id)),
      ...selectedCustomersToBeAdded
    ].map(c => formatCustomer(c, customers))
  }, [selectedCustomersToBeDeleted.length, selectedCustomersToBeAdded.length, customerMaps])

  const { mutate: saveCustomerMapChanges } = useMutation("save-to-customer-map", async () => {
    if (selectedCustomersToBeAdded.length > 0) {
      await axios.post(`/api/customer-filter-maps`, { 
        customerIds: selectedCustomersToBeAdded.map(({ customer_id }) => customer_id), 
        filterIds: [selectedCarFilter.id]
      })
    }
  }, {
    onSuccess: async () => {
      selectedCustomersToBeAddedSet([])
      await queryClient.invalidateQueries(['customer-maps-db-data'])
      await queryClient.invalidateQueries([customer, "filter-by-customer-id"])
    }
  })

  const { mutate: deleteCustomerMapChanges } = useMutation("delete-from-customer-map", async () => {
    if (selectedCustomersToBeDeleted.length > 0) {
      await axios.delete(`/api/customer-filter-maps`, { data: { ids: selectedCustomersToBeDeleted.map(({ id }) => id) } })
    }
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("customer-maps-db-data")
      selectedCustomersToBeDeletedSet([])
    }
  })

  const { mutate: deleteAllCustomerMaps } = useMutation("delete-all-from-map", async (id) => {
    if (id) {
      await axios.delete(`/api/customer-filter-maps/?filterId=${id}`)
    }
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['customer-maps-db-data'])
      await queryClient.invalidateQueries([customer, "filter-by-customer-id"])
    }
  })

  const handleAddCustomerMap = ({ filter_id, customer_id }) => {
    if (selectedCustomers.some(customer => customer.customer_id === customer_id)) {
      return
    }

    // Early-out since this essentially resets this customers relationship on this filter
    if (selectedCustomersToBeDeleted.some(toBeDeleted => toBeDeleted.customer_id === customer_id)) {
      selectedCustomersToBeDeletedSet(prevState => prevState.filter(customer => customer.customer_id !== customer_id))
      return
    }

    selectedCustomersToBeAddedSet([...selectedCustomersToBeAdded, { filter_id, customer_id }])
  }

  const handleRemoveCustomerMap = () => {
    selectedCustomersToBeDeletedSet([])
    selectedCustomersToBeAddedSet([])
  }

  const handleDeleteCustomerMap = (rowdata) => {
    const { id, filter_id, customer_id } = rowdata.original
    if (!selectedCustomersToBeDeleted.some(toBeDeleted => toBeDeleted.customer_id === customer_id)) {
      selectedCustomersToBeDeletedSet(prevState => [...prevState, { id, customer_id, filter_id }])
    }
  }

  return {
    selectedCustomers,
    handleAddCustomerMap,
    handleRemoveCustomerMap,
    handleDeleteCustomerMap,
    deleteAllCustomerMaps,
    saveCustomerMapChanges,
    deleteCustomerMapChanges,
    isChanged: selectedCustomersToBeAdded.length > 0 || selectedCustomersToBeDeleted.length > 0
  }
}
