import React, { Component } from "react";
import styled from "styled-components";
import { getModels } from "./Request";
import { Spinner } from "@flow/icons";
import { SelectionBox, Header, HeaderItem } from "./Styles";
import ModelRow from "./components/ModelRow";
import { LoadingBox } from "./components/LoadingBox";

export default class Model extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleType: "",
      models: [],
      uniqueModelTypes: [],
      uniqueModelYears: [],
      selectedFilter: {
        models: [],
        year: [],
        priceLow: 0,
        priceHigh: 10000000
      },
      loading: true
    };

    this.updateFilter = this.updateFilter.bind(this);
  }

  componentDidMount() {
    const { jatoData, vehicleType, carConfig } = this.props;

    if (jatoData.models && !jatoData.models.length > 0) {
      getModels(carConfig.carMake, vehicleType, models => {
        const modelTypes = models.map(el => el.modelNameToDisplay);
        const uniqueModelTypes = Array.from(new Set(modelTypes));

        const modelYears = models.map(el => el.modelYear);
        const uniqueModelYears = Array.from(new Set(modelYears));

        this.setState({ models: models, uniqueModelTypes, uniqueModelYears, loading: false, vehicleType });
        this.props.cacheJatoData({ jatoData: { ...jatoData, models }, vehicleType });
      });
    } else {
      const models = jatoData.models;
      const modelTypes = models.map(el => el.modelNameToDisplay);
      const uniqueModelTypes = Array.from(new Set(modelTypes));

      const modelYears = models.map(el => el.modelYear);
      const uniqueModelYears = Array.from(new Set(modelYears));

      this.setState({ models: models, uniqueModelTypes, uniqueModelYears, loading: false });
    }
  }

  updateFilter(type, value) {
    let selectedFilter = {};
    switch (type) {
      case "models":
        if (value === "Alle") {
          selectedFilter = { ...this.state.selectedFilter, models: [] };
        } else if (this.state.selectedFilter.models.some(el => el === value)) {
          selectedFilter = { ...this.state.selectedFilter, models: this.state.selectedFilter.models.filter(el => el !== value) };
        } else {
          selectedFilter = { ...this.state.selectedFilter, models: [...this.state.selectedFilter.models, value] };
        }
        break;
      case "year":
        if (value === "Alle") {
          selectedFilter = { ...this.state.selectedFilter, year: [] };
        } else if (this.state.selectedFilter.year.some(el => el === value)) {
          selectedFilter = { ...this.state.selectedFilter, year: this.state.selectedFilter.year.filter(el => el !== value) };
        } else {
          selectedFilter = { ...this.state.selectedFilter, year: [...this.state.selectedFilter.year, value] };
        }
        break;
      case "priceLow":
        if (value === "") {
          selectedFilter = { ...this.state.selectedFilter, priceLow: 0 };
        } else {
          selectedFilter = { ...this.state.selectedFilter, priceLow: parseInt(value) };
        }
        break;
      case "priceHigh":
        if (value === "") {
          selectedFilter = { ...this.state.selectedFilter, priceHigh: 10000000 };
        } else {
          selectedFilter = { ...this.state.selectedFilter, priceHigh: parseInt(value) };
        }
        break;
      default:
        selectedFilter = this.state.selectedFilter;
        break;
    }

    this.setState({ selectedFilter });
  }

  render() {
    const modelList = this.state.models.reduce((acc, car) => {
      const canBeFromModel =
        this.state.selectedFilter.models.length === 0 ||
        this.state.selectedFilter.models.some(el => el === car.modelNameToDisplay);
      const canBeFromYear =
        this.state.selectedFilter.year.length === 0 || this.state.selectedFilter.year.some(el => el === car.modelYear);
      const canBeFromPrice =
        car.minPrice >= this.state.selectedFilter.priceLow && car.maxPrice <= this.state.selectedFilter.priceHigh;

      if (canBeFromModel && canBeFromYear && canBeFromPrice) {
        return [
          ...acc,
          <ModelRow
            key={car.modelKey}
            model={car.modelNameToDisplay}
            modelKey={car.modelKey}
            imageUrl={car.photoUrl}
            year={car.modelYear}
            priceFrom={car.minPriceToDisplay}
            priceTo={car.maxPriceToDisplay}
            onClick={this.props.onSelectModel}
          />
        ];
      }
      return acc;
    }, []);

    const modelSelectionList = this.state.uniqueModelTypes.map(modelType => {
      return (
        <SelectionBox
          key={modelType}
          onClick={() => this.updateFilter("models", modelType)}
          selected={this.state.selectedFilter.models.some(el => el === modelType)}
        >
          {modelType}
        </SelectionBox>
      );
    });

    const yearSelectionList = this.state.uniqueModelYears.map(modelYear => {
      return (
        <SelectionBox
          key={modelYear}
          selected={this.state.selectedFilter.year.some(el => el === modelYear)}
          onClick={() => this.updateFilter("year", modelYear)}
        >
          {modelYear}
        </SelectionBox>
      );
    });
    return (
      <ModelSection>
        <PriceAndYearFilter>
          <PriceFilter>
            <PriceFilterLabel>Angi pris</PriceFilterLabel>
            <PriceRangeFilter>
              <PriceRange type="text" placeholder="Fra" onChange={event => this.updateFilter("priceLow", event.target.value)} />
              <PriceRange type="text" placeholder="Til" onChange={event => this.updateFilter("priceHigh", event.target.value)} />
            </PriceRangeFilter>
          </PriceFilter>
          <YearFilter>
            <YearFilterLabel>Årsmodell</YearFilterLabel>
            <YearSelection>
              <SelectionBox
                selected={this.state.selectedFilter.year.length === 0}
                onClick={() => this.updateFilter("year", "Alle")}
              >
                Alle
              </SelectionBox>
              {yearSelectionList}
            </YearSelection>
          </YearFilter>
        </PriceAndYearFilter>
        <ModelFilter>
          <ModelFilterLabel>Velg modell</ModelFilterLabel>
          <ModelSelection>
            <SelectionBox
              selected={this.state.selectedFilter.models.length === 0}
              onClick={() => this.updateFilter("models", "Alle")}
            >
              Alle
            </SelectionBox>
            {modelSelectionList}
          </ModelSelection>
        </ModelFilter>
        <Header>
          <HeaderItem />
          <HeaderItem>MODELL</HeaderItem>
          <HeaderItem>ÅRSMODELL</HeaderItem>
          <HeaderItem>PRIS KR</HeaderItem>
        </Header>
        <ModelList loading={this.state.loading}>
          {this.state.loading ? (
            <LoadingBox>
              <Spinner />
              <p>Laster bilmodeller fra Jato</p>
            </LoadingBox>
          ) : (
              modelList
            )}
        </ModelList>
      </ModelSection>
    );
  }
}

const PriceRange = styled.input`
  background-color: transparent;
  color: black;
`;

export const ModelSection = styled.div`
  margin-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
`;

export const ModelLabel = styled.div`
  font-size: 12px;
  text-align: left;
  line-height: 16px;
  color: #6c6c6c;
`;

export const ModelList = styled.div`
  margin: 0 10;
  height: 80%;
  display: ${props => (props.loading ? "flex" : "block")};
  flex-direction: ${props => (props.loading ? "row" : "unset")};
  justify-content: ${props => (props.loading ? "center" : "unset")};
  align-items: ${props => (props.loading ? "center" : "baseline")};
`;

export const PriceAndYearFilter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  padding-top: 27px;
  padding-bottom: 20px;
`;

export const PriceFilter = styled.div``;

export const PriceFilterLabel = styled.div`
  text-align: left;
  font-size: 12px;
  color: #6c6c6c;
  padding-bottom: 5px;
`;

export const PriceRangeFilter = styled.div`
  display: grid;
  grid-template-columns: 160px 160px;
  column-gap: 10px;

  input[type="text"] {
    width: 150px;
    padding: 10px;
    border: 1px solid #b8b8b8;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const YearFilter = styled.div``;

export const YearSelection = styled.div`
  display: block;
  text-align: left;
`;

export const YearFilterLabel = styled.div`
  text-align: left;
  font-size: 12px;
  color: #6c6c6c;
  padding-bottom: 5px;
`;

export const ModelFilter = styled.div``;

export const ModelFilterLabel = styled.div`
  text-align: left;
  font-size: 12px;
  color: #6c6c6c;
  padding-bottom: 5px;
`;

export const ModelSelection = styled.div`
  display: inline-block;
  text-align: left;
  padding-bottom: 20px;
`;
