import React, { useEffect, useState } from "react";
import lodash from "lodash";
import { Spinner } from "@flow/icons";
import { Colors } from "@flow/style";
import styled from "styled-components";
import { getMakes, getMatrixMakes } from "./requests";
import BrandBox from "./components/BrandBox";
import { LoadingBox } from "./components/LoadingBox";

export default ({
                  jatoData,
                  vehicleType,
                  onClick
}) => {
  const [makes, makesSet] = useState([])
  const [_, vehicleTypeSet] = useState("")
  const [filteredMakes, filteredMakesSet] = useState([])
  const [loading, loadingSet] = useState(true)

  useEffect(() => {
    if (jatoData.makes && !jatoData.makes.length > 0 && vehicleType) {
      getMakes(vehicleType, async makes => {
        // Filter out makes not available in restverdimatrise
        const matrixMakes = await getMatrixMakes(`${process.env.AUTOPLAN_API_URL}/api/information/makes`)
        makes = makes.filter(make => matrixMakes.some(({ Make }) => Make.toUpperCase() === make.makeKey.toUpperCase()))

        makesSet(makes)
        filteredMakesSet(makes)
        loadingSet(false)
        vehicleTypeSet(vehicleType)
      })
    } else {
      makesSet(jatoData.makes)
      filteredMakesSet(jatoData.makes)
      loadingSet(false)
    }
  }, [])


  const filterMake = (filter) => {
    const newList = lodash.filter(makes, o => {
      return o.makeKey.includes(lodash.toUpper(filter));
    });
    filteredMakesSet(newList)
  }

  const list = lodash.sortBy(filteredMakes, el => {
    return el.makeNameToDisplay;
  });
  const makesList = list
    .map(make => (
      <BrandBox
        key={make.makeKey}
        make={make.makeNameToDisplay}
        makeKey={make.makeKey}
        vehicleId={make.vehicleId}
        imageUrl={make.logoUrl}
        onClick={data => onClick(data)}
      />
    ))
    .sort();
  return (
    <MakeSection>
      <div className="titleAndSearch">
        <MakeLabel>Bilmerke</MakeLabel>
        <SearchBox placeholder="Søk" onChange={event => filterMake(event.target.value)}/>
      </div>

      <MakeBox loading={loading}>
        {loading ? (
          <LoadingBox>
            <Spinner/>
            <p>Laster bilmerker fra Jato</p>
          </LoadingBox>
        ) : (
          <BrandList>{makesList}</BrandList>
        )}
      </MakeBox>
    </MakeSection>
  );
}

export const SearchBox = styled.input`
  height: 40px;
  width: 550px;
  margin-bottom: 40px;
  border: 1px solid ${Colors.Sea};
  border-radius: 4px;
  font-size: 20px;
  padding: 5px;
  background-color: transparent;
  color: black;
`;

export const MakeLabel = styled.div`
  font-size: 12px;
  text-align: left;
  line-height: 16px;
  color: #6c6c6c;
  margin-top: 27px;
  margin-bottom: 7px;
`;

export const MakeSection = styled.div`
  margin-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .titleAndSearch {
  }
`;

export const MakeBox = styled.div`
  font-size: 14px;
  width: 94%;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: ${props => (props.loading ? "center" : "baseline")};
`;

export const BrandList = styled.div`
  display: grid;
  width: -webkit-fill-available;
  width: -moz-available;
  margin: 0 10;
  grid-template-columns: repeat(auto-fill, minmax(124px, 1fr));
  grid-auto-rows: minmax(95px, auto);
`;
