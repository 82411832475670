import { useMemo } from "react";
import { useQuery } from "react-query";
import axios from "axios";

export const useFilters = ({
                             customer,
                             showUnavailableCustomerFilters,
                             showUnavailableEquipmentFilters,
                           }) => {
  const { data: carFilters } = useQuery(["car-filter-db-data"], async () => {
    const res = await axios.get("/api/car-filters");
    return res.data;
  });

  const { data: customerMaps, isLoading } = useQuery(
    [customer, "filter-by-customer-id"],
    async () => {
      const res = await axios.get(
        `/api/customer-filter-maps?customerId=${customer.id}`
      );
      return res.data;
    },
    { enabled: !!customer }
  );

  const equipmentFilters = useMemo(() => {
    if (!carFilters || isLoading) {
      return null;
    }
    if (customer && customerMaps) {
      // customers with a mapping towards an equipment filter have access to the filter
      return carFilters
        .filter((carFilter) => carFilter.allowed_by_all_customers)
        .map((carFilter) =>
          customerMaps.some((cMap) => cMap.filter_id === carFilter.id)
            ? {
              ...carFilter,
              available: false,
            }
            : { ...carFilter, available: true }
        );
    }

    return carFilters.filter((carFilter) => carFilter.allowed_by_all_customers);
  }, [carFilters, customerMaps, showUnavailableEquipmentFilters, customer]);

  const customerFilters = useMemo(() => {
    if (!carFilters || isLoading) {
      return null;
    }
    if (customer && customerMaps) {
      // customers with a mapping towards a customer filter are excluded from the car filter

      return carFilters
        .filter((carFilter) => !carFilter.allowed_by_all_customers)
        .map((carFilter) =>
          customerMaps.some((cMap) => cMap.filter_id === carFilter.id)
            ? {
              ...carFilter,
              available: true,
            }
            : { ...carFilter, available: false }
        );
    }

    return carFilters.filter(
      (carFilter) => !carFilter.allowed_by_all_customers
    );
  }, [carFilters, customerMaps, showUnavailableCustomerFilters, customer]);

  return {
    carFilters,
    equipmentFilters,
    customerFilters,
  };
};
