import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ModelRow = ({ onClick, model, modelKey, year, imageUrl, priceFrom, priceTo, vehicleId }) => {
  return (
    <Row onClick={() => onClick(modelKey, year, vehicleId)}>
      <ModelImage>
        <img style={{ width: "80px", height: "50px" }} src={imageUrl} alt="modelimage" />
      </ModelImage>
      <ModelName>{model}</ModelName>
      <ModelYear>{year}</ModelYear>
      <ModelPriceRange>
        {priceFrom} - {priceTo}
      </ModelPriceRange>
    </Row>
  );
};

ModelRow.propTypes = {
  onClick: PropTypes.func,
  model: PropTypes.string,
  modelKey: PropTypes.string,
  year: PropTypes.string,
  imageUrl: PropTypes.string,
  priceFrom: PropTypes.string,
  priceTo: PropTypes.string
};

ModelRow.defaultProps = {
  onClick: null,
  model: "",
  modelKey: "",
  year: "",
  imageUrl: "",
  priceFrom: "",
  priceTo: ""
};

export default ModelRow;

export const Row = styled.div`
  display: grid;
  margin: 0 10;
  grid-template-columns: 136px 200px 100px 1fr;
  border-bottom: 1px solid #e4e2e2;
  padding: 10px;
  line-height: 22px;
  font-size: 14px;
  color: #6c6c6c;
  cursor: pointer;
`;

export const ModelName = styled.div`
  padding-top: 15px;
  text-align: left;
`;

export const ModelImage = styled.div`
  padding-top: 0px;
`;

export const ModelYear = styled.div`
  padding-top: 15px;
  text-align: left;
`;

export const ModelPriceRange = styled.div`
  padding-top: 15px;
  text-align: left;
`;
