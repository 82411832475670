import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import { Spinner } from "@flow/icons";
import { getEngines, getMatrixEngines } from "./requests";
import EngineRow from "./components/EngineRow";
import { SelectionBox, Header, HeaderItem } from "./Styles";
import { LoadingBox } from "./components/LoadingBox";

export default ({ carConfig, jatoData, vehicleType, cacheJatoData, onSelectEngine }) => {
  const [_, vehicleTypeSet] = useState("")
  const [engines, enginesSet] = useState([])
  const [fuelTypes, fuelTypesSet] = useState([])
  const [seatTypes, seatTypesSet] = useState([])
  const [transmissionTypes, transmissionTypesSet] = useState([])
  const [filteredList, filteredListSet] = useState([])
  const [selectedFilter, selectedFilterSet] = useState({
    fuel: [],
    transmission: [],
    seat: []
  })
  const [loading, loadingSet] = useState(true)

  useEffect(() => {
    if (jatoData.vehicles && !jatoData.vehicles.length > 0 && vehicleType) {
      getEngines(
        carConfig.make,
        carConfig.model,
        carConfig.carYear,
        carConfig.body,
        carConfig.numberOfDoors,
        carConfig.drivenWheels,
        vehicleType,
        async engines => {

          // Filter out engines not available in "restverdimatrisen"
          const matrixEngines = await getMatrixEngines(carConfig.make, carConfig.model, carConfig.carYear);
          engines = engines.filter(engine => matrixEngines.some(({Engine}) => engine.derivativeLocal.toUpperCase() === Engine.toUpperCase()))

          const fuelTypes = engines.map(el => el.fuelType);
          const uniqueFuelTypes = Array.from(new Set(fuelTypes));

          const seatTypes = engines.map(el => el.seats);
          const uniqueSeatTypes = Array.from(new Set(seatTypes));

          const transmissionTypes = engines.map(el => el.transmissionToDisplay);
          const uniqueTransmissionTypes = Array.from(new Set(transmissionTypes));

          enginesSet(engines)
          filteredListSet(engines)
          fuelTypesSet(uniqueFuelTypes)
          seatTypesSet(uniqueSeatTypes)
          transmissionTypesSet(uniqueTransmissionTypes)
          loadingSet(false)
          cacheJatoData({ jatoData: { ...jatoData, vehicles: engines }, vehicleType });
        }
      );
    } else {
      const engines = jatoData.vehicles
      enginesSet(engines)
      filteredListSet(engines)
      loadingSet(false)
    }
  }, [])

  const updateFilter = (type, value) => {
    let sFilter = {};
    switch (type) {
      case "fuel":
        if (value === "Alle") {
          sFilter = { ...selectedFilter, fuel: [] };
        } else if (selectedFilter.fuel.some(el => el === value)) {
          sFilter = { ...selectedFilter, fuel: selectedFilter.fuel.filter(el => el !== value) };
        } else {
          sFilter = { ...selectedFilter, fuel: [...selectedFilter.fuel, value] };
        }
        break;
      case "transmission":
        if (value === "Alle") {
          sFilter = { ...selectedFilter, transmission: [] };
        } else if (selectedFilter.transmission.some(el => el === value)) {
          sFilter = {
            ...selectedFilter,
            transmission: selectedFilter.transmission.filter(el => el !== value)
          };
        } else {
          sFilter = { ...selectedFilter, transmission: [...selectedFilter.transmission, value] };
        }
        break;
      case "seat":
        if (value === "Alle") {
          sFilter = { ...selectedFilter, seat: [] };
        } else if (selectedFilter.seat.some(el => el === value)) {
          sFilter = { ...selectedFilter, seat: selectedFilter.seat.filter(el => el !== value) };
        } else {
          sFilter = { ...selectedFilter, seat: [...selectedFilter.seat, value] };
        }
        break;
      default:
        sFilter = selectedFilter;
        break;
    }

    selectedFilterSet(sFilter)
  }

  const engineList = engines.reduce((acc, engine) => {
    const canBeFromFuel =
      selectedFilter.fuel.length === 0 || selectedFilter.fuel.some(el => el === engine.fuelType);
    const canBeFromGearBox =
      selectedFilter.transmission.length === 0 ||
      selectedFilter.transmission.some(el => el === engine.transmissionToDisplay);
    const canBeFromSeats =
      selectedFilter.seat.length === 0 || selectedFilter.seat.some(el => el === engine.seats);

    if (canBeFromFuel && canBeFromGearBox && canBeFromSeats) {
      let fuelType = engine.fuelType;
      if (engine.secondaryFuelType && engine.secondaryFuelType !== "-") fuelType = `${engine.fuelType}${engine.secondaryFuelType}`;
      return [
        ...acc,
        <EngineRow
          key={engine.vehicleUid}
          engineName={engine.derivativeLocal}
          vehicleId={engine.vehicleId}
          vehicleUid={engine.vehicleUid}
          drivenWheels={engine.drivenWheels}
          numberOfDoors={engine.numberOfDoors}
          seats={engine.seats}
          maximumPowerhpPs={engine.maximumPowerhpPs}
          price={engine.priceToDisplay}
          basePrice={engine.basePrice}
          fuelType={fuelType}
          transmission={engine.transmissionToDisplay}
          onClick={onSelectEngine}
        />
      ];
    }
    return acc;
  }, []);

  const fuelSelectionList = fuelTypes.map(fuel => {
    return (
      <SelectionBox
        key={fuel}
        onClick={() => updateFilter("fuel", fuel)}
        selected={selectedFilter.fuel.some(el => el === fuel)}
      >
        {fuel}
      </SelectionBox>
    );
  });

  const seatSelectionList = seatTypes.map(seat => {
    return (
      <SelectionBox
        key={seat}
        onClick={() => updateFilter("seat", seat)}
        selected={selectedFilter.seat.some(el => el === seat)}
      >
        {seat}
      </SelectionBox>
    );
  });

  const transmissionSelectionList = transmissionTypes.map(transmission => {
    return (
      <SelectionBox
        key={transmission}
        onClick={() => updateFilter("transmission", transmission)}
        selected={selectedFilter.transmission.some(el => el === transmission)}
      >
        {transmission}
      </SelectionBox>
    );
  });

  return (
    <EngineSection>
      <FilterSection>
        <Fuel>
          <EngineLabel>Drivstoff</EngineLabel>
          <EngineSelectionList>
            <SelectionBox
              onClick={() => updateFilter("fuel", "Alle")}
              selected={selectedFilter.fuel.length === 0}
            >
              Alle
            </SelectionBox>
            {fuelSelectionList}
          </EngineSelectionList>
        </Fuel>
        <Transmission>
          <EngineLabel>Girkasse</EngineLabel>
          <EngineSelectionList>
            <SelectionBox
              onClick={() => updateFilter("transmission", "Alle")}
              selected={selectedFilter.transmission.length === 0}
            >
              Alle
            </SelectionBox>
            {transmissionSelectionList}
          </EngineSelectionList>
        </Transmission>
        <Seats>
          <EngineLabel>Seter</EngineLabel>
          <EngineSelectionList>
            <SelectionBox
              onClick={() => updateFilter("seat", "Alle")}
              selected={selectedFilter.seat.length === 0}
            >
              Alle
            </SelectionBox>
            {seatSelectionList}
          </EngineSelectionList>
        </Seats>
      </FilterSection>
      <WideHeader>
        <HeaderItemNoPadding>TYPE</HeaderItemNoPadding>
        <HeaderItemCenter>DRIFT</HeaderItemCenter>
        <HeaderItemCenter>DØRER</HeaderItemCenter>
        <HeaderItemCenter>SETER</HeaderItemCenter>
        <HeaderItemCenter>HK</HeaderItemCenter>
        <HeaderItemCenter>PRIS KR</HeaderItemCenter>
      </WideHeader>
      <EngineList loading={loading}>
        {loading ? (
          <LoadingBox>
            <Spinner />
            <p>Laster motorer fra Jato</p>
          </LoadingBox>
        ) : (
            engineList
          )}
      </EngineList>
    </EngineSection>
  );
}

export const EngineLabel = styled.div`
  font-size: 12px;
  text-align: left;
  line-height: 16px;
  color: #6c6c6c;
`;
export const FilterSection = styled.div`
  display: flex;
`;
export const Fuel = styled.div`
  flex-grow: 1;
`;

export const Transmission = styled.div`
  flex-grow: 1;
`;

export const Seats = styled.div`
  flex-grow: 1;
`;

export const EngineSection = styled.div`
  height: calc(100% - 80px);
  margin-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-top: 27px;
  width: -webkit-fill-available;
  width: -moz-available;
`;

export const EngineSelectionList = styled.div`
  text-align: left;
  padding-bottom: 20px;
`;

export const EngineList = styled.div`
  height: calc(100% - 145px);
  display: ${props => (props.loading ? "flex" : "block")};
  flex-direction: ${props => (props.loading ? "row" : "unset")};
  justify-content: ${props => (props.loading ? "center" : "unset")};
  align-items: ${props => (props.loading ? "center" : "baseline")};
`;

const WideHeader = styled(Header)`
  grid-template-columns: 300px 50px 50px 50px 100px 100px;
  padding-left: 10px;
`;

const HeaderItemCenter = styled(HeaderItem)`
  text-align: center;
  padding-left: 0px;
`;

const HeaderItemNoPadding = styled(HeaderItem)`
  padding-left: 0px;
`;
