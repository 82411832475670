import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const BodyRow = ({ onClick, body, bodyName, numberOfDoors, imageUrl, year, drivenWheels, priceFrom, priceTo }) => {
  return (
    <Row onClick={() => onClick(body.bodyCode, bodyName, body.numberOfDoors, body.drivenWheels, imageUrl, body.vehicleId)}>
      <BodyImage>
        <img style={{ width: "80px", height: "50px" }} src={imageUrl} alt="body" />
      </BodyImage>
      <BodyName>{bodyName}</BodyName>
      <BodyYear>{year}</BodyYear>
      <BodyDrive>{drivenWheels}</BodyDrive>
      <BodyDoors>{numberOfDoors}</BodyDoors>
      <BodyPriceRange>
        {priceFrom} - {priceTo}
      </BodyPriceRange>
    </Row>
  );
};

BodyRow.propTypes = {
  onClick: PropTypes.func,
  body: PropTypes.shape({}),
  bodyName: PropTypes.string,
  imageUrl: PropTypes.string,
  year: PropTypes.string,
  drivenWheels: PropTypes.string,
  priceFrom: PropTypes.string,
  priceTo: PropTypes.string
};

BodyRow.defaultProps = {
  onClick: null,
  body: {},
  bodyName: "",
  imageUrl: "",
  year: "",
  drivenWheels: "",
  priceFrom: "",
  priceTo: ""
};

export default BodyRow;

export const Row = styled.div`
  display: grid;
  margin: 0 10;
  grid-template-columns: 136px 150px 100px 100px 100px 1fr;
  border-bottom: 1px solid #e4e2e2;
  padding: 10px;
  line-height: 22px;
  font-size: 14px;
  color: #6c6c6c;
  cursor: pointer;
`;

export const BodyName = styled.div`
  padding-top: 15px;
  text-align: left;
  text-transform: capitalize;
`;

export const BodyImage = styled.div`
  padding-top: 0px;
`;

export const BodyYear = styled.div`
  padding-top: 15px;
  text-align: left;
`;

export const BodyDrive = styled.div`
  padding-top: 15px;
  text-align: left;
`;

export const BodyDoors = styled.div`
  padding-top: 15px;
  text-align: left;
`;

export const BodyPriceRange = styled.div`
  padding-top: 15px;
  text-align: left;
`;
