import React from "react";
import { QueryClient, QueryClientProvider } from "react-query"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false,
      enabled: true,
      retry(failureCount, error) {
        if (error.status === 404) return false
        else return failureCount < 2
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      //useErrorBoundary: true
    }
  }
})

const AppProvider = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  )
}

export default AppProvider
