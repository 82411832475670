import { useQuery } from 'react-query'
import {
  fetchAttachmentByKey,
  fetchAttachmentsByFlowId,
} from '../../utils/attachments'
import axios from 'axios'
import { CenteredSpinner } from '@flow/icons'
import FilePreview from './PreviewFile'
import { useState } from 'react'
import styled from 'styled-components'
import { Chevron } from '../icons/Chevron'

function formatDate(dateString) {
  const date = new Date(dateString);
  let datePart = [
    date.getMonth() + 1,
    date.getDate(),
    date.getFullYear().toString().slice(2, 4)
  ].map((n, i) => n.toString().padStart(2, "0")).join(".");
  let timePart = [
    date.getHours(),
    date.getMinutes(),
  ].map((n, i) => n.toString().padStart(2, "0")).join(".");
  return datePart + " " + timePart;
}


export function Files(props) {
  const { flow } = props
  const { flowId } = flow

  const [openIndex, setOpenIndex] = useState(null)

  const toggleDocument = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  const { data: files, isFetching } = useQuery({
    queryKey: ['files', flowId],
    queryFn: async () => {
      const { attachments } = await fetchAttachmentsByFlowId(flowId)
      const attachmentsWithVersions = await Promise.all(
        attachments.map(async (attachment) => {
          let data
          try {
            data = await fetchAttachmentByKey(flowId, attachment.attachmentKey)
          } catch (err) {
            if (axios.isAxiosError(err)) {
              data = null
            }
          }

          const version = data?.attachment
          if (!version) {
            console.error('failed to fetch attachment:', attachment)
            return {
              attachmentKey: attachment.attachmentKey,
              owner: '',
              filename: attachment.attachmentKey,
              hasFailed: true,
            }
          }
          if (version.previousVersions) {
            return [
              { attachmentKey: attachment.attachmentKey, ...version },

              ...Object.values(version.previousVersions).map((element) => {
                return { attachmentKey: attachment.attachmentKey, ...element }
              }),
            ]
          } else {
            return { attachmentKey: attachment.attachmentKey, ...version }
          }
        }),
      )
      return {
        flowId,
        attachments: attachmentsWithVersions
          .flat()
          .sort((a, b) => b.version - a.version),
      }
    },
  })

  if (isFetching) return <CenteredSpinner />

  return (
    <div>
      {(files.attachments || []).map((attachment, index) => (
        <DocumentContainer key={index} isOpen={openIndex === index}>
          <DocumentHeaderWrapper onClick={() => toggleDocument(index)}>
            <ChevronWrapper isOpen={openIndex === index}>
              <Chevron size={24} />
            </ChevronWrapper>
            <DocumentHeader>
              <Text> {attachment.filename}</Text>
              <DateText> {formatDate(attachment.updatedAt)}</DateText>
            </DocumentHeader>
          </DocumentHeaderWrapper>
          <ExpandedContent isOpen={openIndex === index}>
            <FilePreview
              attachmentKey={attachment.attachmentKey}
              mimeType={attachment.mimetype}
              flowId={flowId}
              version={attachment.version}
            />
          </ExpandedContent>
        </DocumentContainer>
      ))}
    </div>
  )
}

const DocumentContainer = styled.div`
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: ${(props) => (props.isOpen ? '#f8fbff' : 'transparent')};

  &:first-child {
    border-top: 1px solid #eee;
  }

  &:hover {
    background-color: #f8fbff;
  }
`

const DocumentHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center; /* Center content vertically */
`

const DocumentHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
`

const Text = styled.p`
  font-family: "IBM Plex Sans";
`;

const DateText = styled.p`
    font-size: 12px;
    margin-bottom: 0;
    font-weight: 500;
    color: #2B2B2B;
    font-family: "IBM Plex Sans";
`;

const ChevronWrapper = styled.span`
  margin-right: 5px;
  transition: transform 0.3s ease;
  transform: ${(props) => (props.isOpen ? 'rotate(0deg)' : 'rotate(-90deg)')};
`

const ExpandedContent = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  padding: ${(props) => (props.isOpen ? '10px' : '0')};
`
