import axios from "axios";

export async function fetchAttachmentsByFlowId(flowId) {
  return axios
    .get(`/api/flow/flows/${flowId}/attachments`)
    .then((response) => response.data);
}

export async function fetchAttachmentByKey(
  flowId,
  attachmentKey
) {
  return axios
    .get(`/api/flow/flows/${flowId}/attachments/${attachmentKey}`)
    .then((response) => response.data);
}

export async function downloadAttachment(
  flowId,
  attachmentKey,
  mimeType
) {
  const { data } = await axios.get(
    `/api/flow/flows/${flowId}/attachments/${attachmentKey}/download`,
    {
      responseType: "blob"
    }
  );
  const file = new Blob([data], { type: mimeType });

  return URL.createObjectURL(file);
}