export default {
    173: "Segment",
    174: "Segment",
    176: "Segment",
    202: "Nasjonale data",
    504: "Motor",
    701: "Seter",
    702: "Seter",
    703: "Seter",
    704: "Seter",
    705: "Seter",
    1001: "Musikkanlegg",
    1101: "Musikkanlegg",
    1102: "Musikkanlegg",
    1104: "Musikkanlegg",
    1105: "Musikkanlegg",
    1106: "Musikkanlegg",
    1107: "Musikkanlegg",
    1108: "Musikkanlegg",
    1109: "Musikkanlegg",
    1110: "Musikkanlegg",
    1111: "Musikkanlegg",
    1112: "Musikkanlegg",
    1113: "Musikkanlegg",
    1201: "Musikkanlegg",
    1202: "Musikkanlegg",
    1301: "Musikkanlegg",
    1302: "Musikkanlegg",
    1303: "Musikkanlegg",
    1304: "Musikkanlegg",
    1306: "Musikkanlegg",
    1307: "Musikkanlegg",
    1309: "Musikkanlegg",
    1310: "Musikkanlegg",
    1311: "Musikkanlegg",
    1312: "Musikkanlegg",
    1313: "Musikkanlegg",
    1315: "Musikkanlegg",
    1316: "Musikkanlegg",
    1319: "Musikkanlegg",
    1320: "Musikkanlegg",
    1321: "Musikkanlegg",
    1322: "Musikkanlegg",
    1323: "Musikkanlegg",
    1324: "Musikkanlegg",
    1325: "Musikkanlegg",
    1326: "Musikkanlegg",
    1327: "Musikkanlegg",
    1328: "Musikkanlegg",
    1329: "Musikkanlegg",
    1330: "Musikkanlegg",
    1331: "Musikkanlegg",
    1332: "Musikkanlegg",
    1333: "Musikkanlegg",
    1334: "Musikkanlegg",
    1335: "Musikkanlegg",
    1336: "Musikkanlegg",
    1337: "Musikkanlegg",
    1401: "Musikkanlegg",
    1402: "Musikkanlegg",
    1501: "Eksteriør",
    1502: "Eksteriør",
    1601: "Eksteriør",
    1602: "Eksteriør",
    1604: "Eksteriør",
    1605: "Eksteriør",
    1701: "Eksteriør",
    1702: "Eksteriør",
    1801: "Eksteriør",
    1803: "Eksteriør",
    1804: "Eksteriør",
    1805: "Eksteriør",
    1901: "Komfort",
    1902: "Komfort",
    1903: "Komfort",
    2001: "Eksteriør",
    2002: "Eksteriør",
    2003: "Eksteriør",
    2201: "Eksteriør",
    2202: "Eksteriør",
    2205: "Eksteriør",
    2206: "Eksteriør",
    2301: "Eksteriør",
    2401: "Eksteriør",
    2501: "Nasjonale data",
    2502: "Nasjonale data",
    2601: "Eksteriør",
    2602: "Eksteriør",
    2701: "Eksteriør",
    2801: "Eksteriør",
    2802: "Eksteriør",
    2803: "Eksteriør",
    2804: "Eksteriør",
    2805: "Eksteriør",
    2806: "Eksteriør",
    2807: "Eksteriør",
    2901: "Eksteriør",
    2902: "Eksteriør",
    2903: "Eksteriør",
    3001: "Eksteriør",
    3002: "Eksteriør",
    3003: "Eksteriør",
    3004: "Eksteriør",
    3005: "Eksteriør",
    3007: "Eksteriør",
    3008: "Eksteriør",
    3009: "Eksteriør",
    3010: "Eksteriør",
    3011: "Eksteriør",
    3012: "Eksteriør",
    3101: "Bremser",
    3102: "Bremser",
    3103: "Bremser",
    3201: "Bremser",
    3202: "Bremser",
    3301: "Støtfangere",
    3302: "Støtfangere",
    3303: "Støtfangere",
    3304: "Støtfangere",
    3305: "Støtfangere",
    3306: "Støtfangere",
    3307: "Støtfangere",
    3308: "Støtfangere",
    3309: "Støtfangere",
    3401: "Støtfangere",
    3402: "Støtfangere",
    3501: "Avgifter",
    3505: "Avgifter",
    3508: "Avgifter",
    3509: "Avgifter",
    3510: "Avgifter",
    3511: "Avgifter",
    3512: "Avgifter",
    3513: "Avgifter",
    3514: "Avgifter",
    3515: "Avgifter",
    3516: "Avgifter",
    3517: "Avgifter",
    3518: "Avgifter",
    3519: "Avgifter",
    3520: "Avgifter",
    3521: "Avgifter",
    3522: "Avgifter",
    3523: "Avgifter",
    3524: "Avgifter",
    3525: "Avgifter",
    3526: "Avgifter",
    3527: "Avgifter",
    3528: "Avgifter",
    3529: "Avgifter",
    3530: "Avgifter",
    3531: "Avgifter",
    3532: "Avgifter",
    3533: "Avgifter",
    3534: "Avgifter",
    3535: "Avgifter",
    3601: "Avgifter",
    3602: "Avgifter",
    3603: "Avgifter",
    3604: "Avgifter",
    3605: "Avgifter",
    3606: "Avgifter",
    3701: "Komfort",
    3702: "Komfort",
    3703: "Komfort",
    3704: "Komfort",
    3801: "Komfort",
    3802: "Komfort",
    3803: "Komfort",
    3804: "Komfort",
    3805: "Komfort",
    3901: "Komfort",
    4001: "Komfort",
    4002: "Komfort",
    4101: "Komfort",
    4102: "Komfort",
    4201: "Komfort",
    4202: "Komfort",
    4301: "Låser",
    4302: "Låser",
    4401: "Komfort",
    4402: "Komfort",
    4405: "Komfort",
    4406: "Komfort",
    4407: "Komfort",
    4408: "Komfort",
    4409: "Komfort",
    4410: "Komfort",
    4411: "Komfort",
    4501: "Komfort",
    4502: "Komfort",
    4503: "Komfort",
    4504: "Komfort",
    4505: "Komfort",
    4506: "Komfort",
    4601: "Dimensjoner",
    4602: "Dimensjoner",
    4603: "Dimensjoner",
    4701: "Komfort",
    4702: "Komfort",
    4703: "Komfort",
    4801: "Komfort",
    4802: "Komfort",
    4803: "Komfort",
    4901: "Komfort",
    4902: "Komfort",
    4903: "Komfort",
    4905: "Komfort",
    4906: "Komfort",
    4907: "Komfort",
    5001: "Komfort",
    5003: "Komfort",
    5004: "Komfort",
    5101: "Komfort",
    5102: "Komfort",
    5103: "Komfort",
    5104: "Komfort",
    5105: "Komfort",
    5106: "Komfort",
    5201: "Komfort",
    5301: "Komfort",
    5302: "Komfort",
    5401: "Komfort",
    5501: "Komfort",
    5502: "Komfort",
    5503: "Komfort",
    5504: "Komfort",
    5601: "Komfort",
    5602: "Komfort",
    5603: "Komfort",
    5604: "Komfort",
    5701: "Komfort",
    5702: "Komfort",
    5801: "Dimensjoner",
    5802: "Dimensjoner",
    5803: "Dimensjoner",
    5804: "Dimensjoner",
    5805: "Dimensjoner",
    5806: "Dimensjoner",
    5807: "Dimensjoner",
    5808: "Dimensjoner",
    5809: "Dimensjoner",
    5810: "Dimensjoner",
    5811: "Dimensjoner",
    5812: "Dimensjoner",
    5815: "Dimensjoner",
    5901: "Dimensjoner",
    5902: "Dimensjoner",
    5903: "Dimensjoner",
    5904: "Dimensjoner",
    5905: "Dimensjoner",
    5906: "Dimensjoner",
    5907: "Dimensjoner",
    5908: "Dimensjoner",
    5909: "Dimensjoner",
    5912: "Dimensjoner",
    5913: "Dimensjoner",
    5917: "Dimensjoner",
    5918: "Dimensjoner",
    5919: "Dimensjoner",
    5920: "Dimensjoner",
    5921: "Dimensjoner",
    5922: "Dimensjoner",
    5923: "Dimensjoner",
    6001: "Dimensjoner",
    6002: "Dimensjoner",
    6003: "Dimensjoner",
    6004: "Dimensjoner",
    6005: "Dimensjoner",
    6006: "Dimensjoner",
    6007: "Dimensjoner",
    6008: "Dimensjoner",
    6009: "Dimensjoner",
    6010: "Dimensjoner",
    6101: "Dimensjoner",
    6102: "Dimensjoner",
    6103: "Dimensjoner",
    6104: "Dimensjoner",
    6106: "Dimensjoner",
    6107: "Dimensjoner",
    6108: "Dimensjoner",
    6109: "Dimensjoner",
    6110: "Dimensjoner",
    6111: "Dimensjoner",
    6112: "Dimensjoner",
    6113: "Dimensjoner",
    6201: "Komfort",
    6202: "Komfort",
    6203: "Komfort",
    6301: "Dører",
    6302: "Dører",
    6303: "Dører",
    6304: "Dører",
    6305: "Dører",
    6307: "Dører",
    6308: "Dører",
    6401: "Dører",
    6402: "Dører",
    6501: "Transmisjon",
    6502: "Transmisjon",
    6503: "Transmisjon",
    6504: "Transmisjon",
    6505: "Transmisjon",
    6506: "Transmisjon",
    6507: "Transmisjon",
    6508: "Transmisjon",
    6509: "Transmisjon",
    6510: "Transmisjon",
    6512: "Transmisjon",
    6513: "Transmisjon",
    6514: "Transmisjon",
    6601: "Transmisjon",
    6602: "Transmisjon",
    6701: "Transmisjon",
    6702: "Transmisjon",
    6703: "Transmisjon",
    6801: "Transmisjon",
    6802: "Transmisjon",
    6803: "Transmisjon",
    6804: "Transmisjon",
    6901: "Transmisjon",
    6902: "Transmisjon",
    6903: "Transmisjon",
    7001: "Nødutstyr",
    7101: "Nødutstyr",
    7201: "Nødutstyr",
    7301: "Nødutstyr",
    7401: "Motor",
    7402: "Motor",
    7403: "Motor",
    7404: "Motor",
    7405: "Motor",
    7406: "Motor",
    7407: "Motor",
    7408: "Motor",
    7409: "Motor",
    7410: "Motor",
    7411: "Motor",
    7412: "Motor",
    7413: "Motor",
    7414: "Motor",
    7415: "Motor",
    7416: "Motor",
    7417: "Motor",
    7420: "Motor",
    7421: "Motor",
    7422: "Motor",
    7423: "Motor",
    7424: "Motor",
    7425: "Motor",
    7426: "Motor",
    7501: "Motor",
    7502: "Motor",
    7503: "Motor",
    7504: "Motor",
    7505: "Motor",
    7601: "Motor",
    7602: "Motor",
    7603: "Motor",
    7604: "Motor",
    7605: "Motor",
    7606: "Motor",
    7607: "Motor",
    7608: "Motor",
    7609: "Motor",
    7610: "Motor",
    7611: "Motor",
    7612: "Motor",
    7613: "Motor",
    7614: "Motor",
    7615: "Motor",
    7616: "Motor",
    7617: "Motor",
    7618: "Motor",
    7619: "Motor",
    7620: "Motor",
    7621: "Motor",
    7622: "Motor",
    7625: "Motor",
    7626: "Motor",
    7627: "Motor",
    7628: "Motor",
    7630: "Motor",
    7632: "Motor",
    7633: "Motor",
    7634: "Motor",
    7635: "Motor",
    7636: "Motor",
    7637: "Motor",
    7701: "Motor",
    7702: "Motor",
    7703: "Motor",
    7801: "Motor",
    7901: "Motor",
    7902: "Motor",
    8001: "Motor",
    8101: "Motor",
    8201: "Sikt",
    8301: "Hybrid og elektrisk system",
    8302: "Hybrid og elektrisk system",
    8303: "Hybrid og elektrisk system",
    8304: "Hybrid og elektrisk system",
    8305: "Hybrid og elektrisk system",
    8306: "Hybrid og elektrisk system",
    8307: "Hybrid og elektrisk system",
    8308: "Hybrid og elektrisk system",
    8309: "Hybrid og elektrisk system",
    8310: "Hybrid og elektrisk system",
    8312: "Hybrid og elektrisk system",
    8313: "Hybrid og elektrisk system",
    8314: "Hybrid og elektrisk system",
    8315: "Hybrid og elektrisk system",
    8316: "Hybrid og elektrisk system",
    8317: "Hybrid og elektrisk system",
    8318: "Hybrid og elektrisk system",
    8319: "Hybrid og elektrisk system",
    8320: "Hybrid og elektrisk system",
    8321: "Motor",
    8401: "Drivstoff",
    8402: "Drivstoff",
    8403: "Drivstoff",
    8501: "Drivstoff",
    8502: "Drivstoff",
    8503: "Drivstoff",
    8504: "Drivstoff",
    8601: "Drivstoff",
    8602: "Drivstoff",
    8603: "Drivstoff",
    8701: "Drivstoff",
    8702: "Drivstoff",
    8703: "Drivstoff",
    8704: "Drivstoff",
    8705: "Drivstoff",
    8706: "Drivstoff",
    8707: "Drivstoff",
    8708: "Drivstoff",
    8801: "Drivstoff",
    8901: "Drivstoff",
    8902: "Drivstoff",
    8903: "Drivstoff",
    8904: "Drivstoff",
    8905: "Drivstoff",
    8906: "Drivstoff",
    9001: "Instrumentering",
    9002: "Instrumentering",
    9003: "Instrumentering",
    9004: "Instrumentering",
    9005: "Instrumentering",
    9006: "Instrumentering",
    9101: "Instrumentering",
    9201: "Instrumentering",
    9301: "Instrumentering",
    9401: "Instrumentering",
    9402: "Instrumentering",
    9501: "Instrumentering",
    9601: "Instrumentering",
    9602: "Instrumentering",
    9701: "Instrumentering",
    9702: "Instrumentering",
    9801: "Instrumentering",
    9802: "Instrumentering",
    9901: "Instrumentering",
    10001: "Instrumentering",
    10002: "Instrumentering",
    10101: "Instrumentering",
    10102: "Instrumentering",
    10201: "Instrumentering",
    10202: "Instrumentering",
    10301: "Instrumentering",
    10302: "Instrumentering",
    10401: "Instrumentering",
    10501: "Instrumentering",
    10601: "Instrumentering",
    10602: "Instrumentering",
    10701: "Instrumentering",
    10801: "Instrumentering",
    10901: "Instrumentering",
    11001: "Instrumentering",
    11101: "Instrumentering",
    11201: "Instrumentering",
    11301: "Instrumentering",
    11401: "Instrumentering",
    11501: "Instrumentering",
    11502: "Instrumentering",
    11601: "Instrumentering",
    11602: "Instrumentering",
    11701: "Instrumentering",
    11702: "Instrumentering",
    11801: "Instrumentering",
    11802: "Instrumentering",
    11803: "Instrumentering",
    11804: "Instrumentering",
    11805: "Instrumentering",
    11806: "Instrumentering",
    11807: "Instrumentering",
    11901: "Interiør",
    11903: "Interiør",
    11904: "Interiør",
    11905: "Interiør",
    11906: "Interiør",
    11907: "Interiør",
    11908: "Interiør",
    11909: "Interiør",
    11910: "Interiør",
    11911: "Interiør",
    12001: "Interiør",
    12003: "Interiør",
    12005: "Interiør",
    12006: "Interiør",
    12007: "Interiør",
    12101: "Interiør",
    12102: "Interiør",
    12103: "Interiør",
    12104: "Interiør",
    12105: "Interiør",
    12201: "Interiør",
    12202: "Interiør",
    12203: "Interiør",
    12301: "Interiør",
    12302: "Interiør",
    12401: "Interiør",
    12402: "Interiør",
    12501: "Lys",
    12502: "Lys",
    12503: "Lys",
    12504: "Lys",
    12505: "Lys",
    12506: "Lys",
    12601: "Lys",
    12602: "Lys",
    12603: "Lys",
    12604: "Lys",
    12605: "Lys",
    12606: "Lys",
    12607: "Lys",
    12608: "Lys",
    12609: "Lys",
    12610: "Lys",
    12611: "Lys",
    12612: "Lys",
    12701: "Eksteriør",
    12702: "Eksteriør",
    12703: "Eksteriør",
    12801: "Lys",
    12901: "Lys",
    13001: "Lys",
    13002: "Lys",
    13101: "Lys",
    13102: "Lys",
    13201: "Lys",
    13301: "Lys",
    13401: "Lys",
    13501: "Ytelser",
    13502: "Ytelser",
    13503: "Ytelser",
    13504: "Ytelser",
    13505: "Ytelser",
    13506: "Ytelser",
    13507: "Ytelser",
    13601: "Lys",
    13701: "Lys",
    13702: "Lys",
    13801: "Sikkerhet",
    13802: "Sikkerhet",
    13901: "Sikkerhet",
    13902: "Sikkerhet",
    14001: "Lys",
    14101: "Hjul",
    14102: "Hjul",
    14103: "Hjul",
    14104: "Hjul",
    14105: "Hjul",
    14106: "Hjul",
    14107: "Hjul",
    14109: "Hjul",
    14110: "Hjul",
    14111: "Hjul",
    14112: "Hjul",
    14113: "Hjul",
    14114: "Hjul",
    14115: "Hjul",
    14116: "Hjul",
    14117: "Hjul",
    14118: "Hjul",
    14122: "Hjul",
    14123: "Hjul",
    14124: "Hjul",
    14125: "Hjul",
    14126: "Hjul",
    14127: "Hjul",
    14201: "Lasterom",
    14202: "Lasterom",
    14203: "Lasterom",
    14204: "Lasterom",
    14205: "Lasterom",
    14206: "Lasterom",
    14207: "Lasterom",
    14208: "Lasterom",
    14301: "Sikt",
    14302: "Sikt",
    14303: "Sikt",
    14401: "Sikt",
    14402: "Sikt",
    14403: "Sikt",
    14501: "Lasterom",
    14502: "Lasterom",
    14503: "Lasterom",
    14504: "Lasterom",
    14505: "Lasterom",
    14601: "Lasterom",
    14602: "Lasterom",
    14701: "Lasterom",
    14702: "Lasterom",
    14703: "Lasterom",
    14801: "Låser",
    14802: "Låser",
    14803: "Låser",
    14804: "Låser",
    14805: "Låser",
    14806: "Låser",
    14807: "Låser",
    14808: "Låser",
    14809: "Låser",
    14810: "Låser",
    14811: "Låser",
    14812: "Låser",
    14901: "Låser",
    14903: "Låser",
    14904: "Låser",
    14905: "Låser",
    14907: "Låser",
    15001: "Låser",
    15101: "Ikke-kodet utstyr",
    15102: "Ikke-kodet utstyr",
    15104: "Ikke-kodet utstyr",
    15201: "Lakk",
    15202: "Lakk",
    15203: "Lakk",
    15204: "Lakk",
    15205: "Lakk",
    15301: "Ytelser",
    15302: "Ytelser",
    15303: "Ytelser",
    15304: "Ytelser",
    15305: "Ytelser",
    15306: "Ytelser",
    15307: "Ytelser",
    15308: "Ytelser",
    15313: "Ytelser",
    15315: "Ytelser",
    15316: "Ytelser",
    15317: "Ytelser",
    15318: "Ytelser",
    15319: "Ytelser",
    15320: "Ytelser",
    15321: "Ytelser",
    15322: "Ytelser",
    15323: "Ytelser",
    15324: "Ytelser",
    15325: "Ytelser",
    15326: "Ytelser",
    15327: "Ytelser",
    15401: "Ytelser",
    15402: "Ytelser",
    15403: "Ytelser",
    15404: "Ytelser",
    15405: "Ytelser",
    15406: "Ytelser",
    15409: "Ytelser",
    15410: "Ytelser",
    15411: "Ytelser",
    15414: "Ytelser",
    15415: "Ytelser",
    15416: "Ytelser",
    15417: "Ytelser",
    15418: "Ytelser",
    15419: "Ytelser",
    15420: "Ytelser",
    15421: "Ytelser",
    15501: "Tak",
    15503: "Tak",
    15601: "Tak",
    15602: "Tak",
    15603: "Tak",
    15604: "Tak",
    15605: "Tak",
    15606: "Tak",
    15607: "Tak",
    15608: "Tak",
    15609: "Tak",
    15610: "Tak",
    15701: "Tak",
    15704: "Tak",
    15705: "Tak",
    15801: "Tak",
    15802: "Tak",
    15901: "Tak",
    15904: "Tak",
    15905: "Tak",
    15906: "Tak",
    15907: "Tak",
    15908: "Tak",
    16001: "Tak",
    16201: "Tak",
    16202: "Tak",
    16301: "Sikkerhet",
    16302: "Sikkerhet",
    16303: "Sikkerhet",
    16304: "Sikkerhet",
    16305: "Sikkerhet",
    16306: "Sikkerhet",
    16401: "Sikkerhet",
    16402: "Sikkerhet",
    16403: "Sikkerhet",
    16404: "Sikkerhet",
    16501: "Sikkerhet",
    16502: "Sikkerhet",
    16503: "Sikkerhet",
    16504: "Sikkerhet",
    16505: "Sikkerhet",
    16506: "Sikkerhet",
    16507: "Sikkerhet",
    16508: "Sikkerhet",
    16509: "Sikkerhet",
    16510: "Sikkerhet",
    16511: "Sikkerhet",
    16601: "Sikkerhet",
    16602: "Sikkerhet",
    16603: "Sikkerhet",
    16604: "Sikkerhet",
    16701: "Sikkerhet",
    16702: "Sikkerhet",
    16703: "Sikkerhet",
    16704: "Sikkerhet",
    16705: "Sikkerhet",
    16706: "Sikkerhet",
    16707: "Sikkerhet",
    16708: "Sikkerhet",
    16709: "Sikkerhet",
    16710: "Sikkerhet",
    16801: "Sikkerhet",
    16802: "Sikkerhet",
    16803: "Sikkerhet",
    16804: "Sikkerhet",
    16805: "Sikkerhet",
    16806: "Sikkerhet",
    16807: "Sikkerhet",
    16808: "Sikkerhet",
    16901: "Sikkerhet",
    16902: "Sikkerhet",
    16903: "Sikkerhet",
    16904: "Sikkerhet",
    16905: "Sikkerhet",
    16906: "Sikkerhet",
    16907: "Sikkerhet",
    16908: "Sikkerhet",
    17001: "Sikkerhet",
    17002: "Sikkerhet",
    17003: "Sikkerhet",
    17004: "Sikkerhet",
    17005: "Sikkerhet",
    17006: "Sikkerhet",
    17007: "Sikkerhet",
    17008: "Sikkerhet",
    17101: "Sikkerhet",
    17102: "Sikkerhet",
    17103: "Sikkerhet",
    17104: "Sikkerhet",
    17105: "Sikkerhet",
    17106: "Sikkerhet",
    17107: "Sikkerhet",
    17108: "Sikkerhet",
    17201: "Sikkerhet",
    17202: "Sikkerhet",
    17203: "Sikkerhet",
    17204: "Sikkerhet",
    17205: "Sikkerhet",
    17206: "Sikkerhet",
    17207: "Sikkerhet",
    17208: "Sikkerhet",
    17301: "Sikkerhet",
    17401: "Seter",
    17402: "Seter",
    17403: "Seter",
    17404: "Seter",
    17405: "Seter",
    17406: "Seter",
    17407: "Seter",
    17408: "Seter",
    17409: "Seter",
    17501: "Seter",
    17503: "Seter",
    17504: "Seter",
    17505: "Seter",
    17506: "Seter",
    17601: "Seter",
    17603: "Seter",
    17604: "Seter",
    17605: "Seter",
    17606: "Seter",
    17701: "Seter",
    17702: "Seter",
    17703: "Seter",
    17704: "Seter",
    17801: "Seter",
    17802: "Seter",
    17803: "Seter",
    17804: "Seter",
    17805: "Seter",
    17806: "Seter",
    17807: "Seter",
    17808: "Seter",
    17809: "Seter",
    17811: "Seter",
    17812: "Seter",
    17813: "Seter",
    17814: "Seter",
    17815: "Seter",
    17816: "Seter",
    17817: "Seter",
    17818: "Seter",
    17819: "Seter",
    17820: "Seter",
    17821: "Seter",
    17822: "Seter",
    17823: "Seter",
    17824: "Seter",
    17825: "Seter",
    17826: "Seter",
    17827: "Seter",
    17828: "Seter",
    17829: "Seter",
    17830: "Seter",
    17831: "Seter",
    17832: "Seter",
    17901: "Seter",
    17902: "Seter",
    17903: "Seter",
    17904: "Seter",
    17905: "Seter",
    17906: "Seter",
    17907: "Seter",
    17908: "Seter",
    17909: "Seter",
    17910: "Seter",
    17911: "Seter",
    17912: "Seter",
    17913: "Seter",
    17914: "Seter",
    17915: "Seter",
    17916: "Seter",
    17917: "Seter",
    17918: "Seter",
    17919: "Seter",
    17920: "Seter",
    17921: "Seter",
    17922: "Seter",
    17923: "Seter",
    17924: "Seter",
    17925: "Seter",
    17926: "Seter",
    17927: "Seter",
    17928: "Seter",
    17929: "Seter",
    17930: "Seter",
    17931: "Seter",
    17932: "Seter",
    18001: "Seter",
    18003: "Seter",
    18004: "Seter",
    18005: "Seter",
    18006: "Seter",
    18007: "Seter",
    18008: "Seter",
    18009: "Seter",
    18010: "Seter",
    18011: "Seter",
    18012: "Seter",
    18013: "Seter",
    18014: "Seter",
    18015: "Seter",
    18016: "Seter",
    18017: "Seter",
    18018: "Seter",
    18019: "Seter",
    18020: "Seter",
    18021: "Seter",
    18101: "Seter",
    18102: "Seter",
    18103: "Seter",
    18104: "Seter",
    18105: "Seter",
    18106: "Seter",
    18107: "Seter",
    18108: "Seter",
    18109: "Seter",
    18110: "Seter",
    18111: "Seter",
    18112: "Seter",
    18113: "Seter",
    18114: "Seter",
    18115: "Seter",
    18116: "Seter",
    18117: "Seter",
    18201: "Seter",
    18202: "Seter",
    18203: "Seter",
    18204: "Seter",
    18205: "Seter",
    18206: "Seter",
    18207: "Seter",
    18208: "Seter",
    18209: "Seter",
    18210: "Seter",
    18211: "Seter",
    18212: "Seter",
    18213: "Seter",
    18214: "Seter",
    18215: "Seter",
    18216: "Seter",
    18217: "Seter",
    18301: "Seter",
    18302: "Seter",
    18303: "Seter",
    18304: "Seter",
    18305: "Seter",
    18306: "Seter",
    18307: "Seter",
    18308: "Seter",
    18309: "Seter",
    18310: "Seter",
    18311: "Seter",
    18312: "Seter",
    18313: "Seter",
    18315: "Seter",
    18316: "Seter",
    18317: "Seter",
    18401: "Styring",
    18402: "Styring",
    18403: "Styring",
    18405: "Styring",
    18406: "Styring",
    18407: "Styring",
    18408: "Styring",
    18409: "Styring",
    18410: "Styring",
    18411: "Styring",
    18412: "Styring",
    18501: "Styring",
    18502: "Styring",
    18503: "Styring",
    18504: "Styring",
    18505: "Styring",
    18506: "Styring",
    18601: "Styring",
    18701: "Oppbevaringsrom",
    18702: "Oppbevaringsrom",
    18703: "Oppbevaringsrom",
    18704: "Oppbevaringsrom",
    18801: "Oppbevaringsrom",
    18802: "Oppbevaringsrom",
    18803: "Oppbevaringsrom",
    18901: "Sikt",
    18902: "Sikt",
    18903: "Sikt",
    19001: "Sikt",
    19002: "Sikt",
    19003: "Sikt",
    19101: "Oppbevaringsrom",
    19102: "Oppbevaringsrom",
    19201: "Oppbevaringsrom",
    19301: "Oppbevaringsrom",
    19302: "Oppbevaringsrom",
    19303: "Oppbevaringsrom",
    19401: "Oppbevaringsrom",
    19402: "Oppbevaringsrom",
    19403: "Oppbevaringsrom",
    19501: "Oppbevaringsrom",
    19502: "Oppbevaringsrom",
    19601: "Oppbevaringsrom",
    19602: "Oppbevaringsrom",
    19603: "Oppbevaringsrom",
    19604: "Oppbevaringsrom",
    19605: "Oppbevaringsrom",
    19606: "Oppbevaringsrom",
    19701: "Oppbevaringsrom",
    19801: "Sikt",
    19802: "Sikt",
    19803: "Sikt",
    19901: "Oppbevaringsrom",
    20001: "Fjæring",
    20002: "Fjæring",
    20003: "Fjæring",
    20004: "Fjæring",
    20005: "Fjæring",
    20006: "Fjæring",
    20007: "Fjæring",
    20201: "Fjæring",
    20202: "Fjæring",
    20301: "Fjæring",
    20302: "Fjæring",
    20303: "Fjæring",
    20304: "Fjæring",
    20401: "Fjæring",
    20402: "Fjæring",
    20403: "Fjæring",
    20404: "Fjæring",
    20405: "Fjæring",
    20601: "Transmisjon",
    20602: "Transmisjon",
    20603: "Transmisjon",
    20604: "Transmisjon",
    20605: "Transmisjon",
    20606: "Transmisjon",
    20607: "Transmisjon",
    20608: "Transmisjon",
    20609: "Transmisjon",
    20610: "Transmisjon",
    20611: "Transmisjon",
    20612: "Transmisjon",
    20613: "Transmisjon",
    20614: "Transmisjon",
    20615: "Transmisjon",
    20616: "Transmisjon",
    20617: "Transmisjon",
    20618: "Transmisjon",
    20619: "Transmisjon",
    20620: "Transmisjon",
    20621: "Transmisjon",
    20622: "Transmisjon",
    20623: "Transmisjon",
    20624: "Transmisjon",
    20625: "Transmisjon",
    20626: "Transmisjon",
    20627: "Transmisjon",
    20628: "Transmisjon",
    20629: "Transmisjon",
    20630: "Transmisjon",
    20631: "Transmisjon",
    20632: "Transmisjon",
    20633: "Transmisjon",
    20634: "Transmisjon",
    20635: "Transmisjon",
    20636: "Transmisjon",
    20701: "Transmisjon",
    20801: "Ventilasjon",
    20802: "Ventilasjon",
    20803: "Ventilasjon",
    20804: "Ventilasjon",
    20805: "Ventilasjon",
    20806: "Ventilasjon",
    20807: "Ventilasjon",
    20808: "Ventilasjon",
    20809: "Ventilasjon",
    20811: "Ventilasjon",
    20812: "Ventilasjon",
    20813: "Ventilasjon",
    20814: "Ventilasjon",
    20815: "Ventilasjon",
    20816: "Ventilasjon",
    20817: "Ventilasjon",
    20901: "Ventilasjon",
    20902: "Ventilasjon",
    20904: "Ventilasjon",
    21001: "Ventilasjon",
    21002: "Ventilasjon",
    21003: "Ventilasjon",
    21004: "Ventilasjon",
    21005: "Ventilasjon",
    21006: "Ventilasjon",
    21007: "Ventilasjon",
    21008: "Ventilasjon",
    21009: "Ventilasjon",
    21010: "Ventilasjon",
    21011: "Ventilasjon",
    21101: "Ventilasjon",
    21201: "Ventilasjon",
    21202: "Ventilasjon",
    21301: "Ventilasjon",
    21302: "Ventilasjon",
    21401: "Sikt",
    21402: "Sikt",
    21403: "Sikt",
    21404: "Sikt",
    21501: "Sikt",
    21503: "Sikt",
    21504: "Sikt",
    21505: "Sikt",
    21601: "Sikt",
    21602: "Sikt",
    21603: "Sikt",
    21604: "Sikt",
    21605: "Sikt",
    21607: "Sikt",
    21608: "Sikt",
    21609: "Sikt",
    21610: "Sikt",
    21611: "Sikt",
    21612: "Sikt",
    21701: "Sikt",
    21702: "Sikt",
    21801: "Sikt",
    21901: "Sikt",
    21902: "Sikt",
    22001: "Sikt",
    22002: "Sikt",
    22101: "Sikt",
    22102: "Sikt",
    22103: "Sikt",
    22201: "Sikt",
    22202: "Sikt",
    22203: "Sikt",
    22301: "Sikt",
    22302: "Sikt",
    22305: "Sikt",
    22306: "Sikt",
    22307: "Sikt",
    22501: "Sikt",
    22503: "Sikt",
    22504: "Sikt",
    22601: "Sikt",
    22602: "Sikt",
    22603: "Sikt",
    22701: "Sikt",
    22801: "Sikt",
    22802: "Sikt",
    22803: "Sikt",
    22804: "Sikt",
    22806: "Sikt",
    22807: "Sikt",
    23001: "Sikt",
    23002: "Sikt",
    23201: "Sikt",
    23301: "Sikt",
    23302: "Sikt",
    23304: "Sikt",
    23306: "Sikt",
    23307: "Sikt",
    23401: "Sikt",
    23501: "Garanti",
    23502: "Garanti",
    23503: "Garanti",
    23601: "Garanti",
    23602: "Garanti",
    23603: "Garanti",
    23701: "Garanti",
    23702: "Garanti",
    23703: "Garanti",
    23801: "Garanti",
    23802: "Garanti",
    23803: "Garanti",
    23901: "Garanti",
    23902: "Garanti",
    23903: "Garanti",
    24001: "Garanti",
    24002: "Garanti",
    24003: "Garanti",
    24101: "Vekter",
    24102: "Vekter",
    24103: "Vekter",
    24105: "Vekter",
    24106: "Vekter",
    24107: "Vekter",
    24108: "Vekter",
    24109: "Vekter",
    24110: "Vekter",
    24111: "Vekter",
    24112: "Vekter",
    24113: "Vekter",
    24114: "Vekter",
    24115: "Vekter",
    24116: "Vekter",
    24117: "Vekter",
    24118: "Vekter",
    24119: "Vekter",
    24120: "Vekter",
    24121: "Vekter",
    24122: "Vekter",
    24123: "Vekter",
    24124: "Vekter",
    24301: "Hjul",
    24401: "Hjul",
    24402: "Hjul",
    24404: "Hjul",
    24405: "Hjul",
    24406: "Hjul",
    24414: "Hjul",
    24415: "Hjul",
    24416: "Hjul",
    24417: "Hjul",
    24418: "Hjul",
    24419: "Hjul",
    24420: "Hjul",
    24421: "Hjul",
    24422: "Hjul",
    24501: "Hjul",
    24502: "Hjul",
    24503: "Hjul",
    24504: "Hjul",
    24601: "Eksteriør",
    24602: "Eksteriør",
    24603: "Eksteriør",
    24701: "Eksteriør",
    24702: "Eksteriør",
    24703: "Eksteriør",
    24704: "Eksteriør",
    24705: "Eksteriør",
    25001: "Eksteriør",
    25101: "Eksteriør",
    25102: "Eksteriør",
    25201: "Komfort",
    25202: "Komfort",
    25203: "Komfort",
    25204: "Komfort",
    25205: "Komfort",
    25206: "Komfort",
    25207: "Komfort",
    25208: "Komfort",
    25209: "Komfort",
    25210: "Komfort",
    25211: "Komfort",
    25212: "Komfort",
    25301: "Komfort",
    25401: "Motor",
    25501: "Motor",
    25601: "Interiør",
    25701: "Interiør",
    25702: "Musikkanlegg",
    25801: "Låser",
    26001: "Hjul",
    26002: "Hjul",
    26101: "Musikkanlegg",
    26102: "Musikkanlegg",
    26201: "Motor",
    26301: "Instrumentering",
    26401: "Instrumentering",
    26501: "Tak",
    26502: "Tak",
    26601: "Oppbevaringsrom",
    26602: "Oppbevaringsrom",
    26603: "Oppbevaringsrom",
    26604: "Oppbevaringsrom",
    26605: "Oppbevaringsrom",
    26606: "Oppbevaringsrom",
    26901: "Nasjonale data",
    27001: "Nasjonale data",
    27101: "Nasjonale data",
    27501: "Nasjonale data",
    27601: "Nasjonale data",
    30701: "Transmisjon",
    30702: "Transmisjon",
    30801: "Interiør",
    30802: "Interiør",
    31001: "Nasjonale data",
    31002: "Nasjonale data",
    31003: "Nasjonale data",
    31004: "Nasjonale data",
    31101: "Lakk",
    31102: "Lakk",
    31103: "Lakk",
    31104: "Lakk",
    31105: "Lakk",
    31106: "Lakk",
    31301: "Musikkanlegg",
    31401: "Musikkanlegg",
    31501: "Eksteriør",
    31601: "Eksteriør",
    31602: "Eksteriør",
    31701: "Eksteriør",
    31801: "Eksteriør",
    31901: "Eksteriør",
    32001: "Eksteriør",
    32101: "Bremser",
    32201: "Bremser",
    32202: "Bremser",
    32301: "Bremser",
    32401: "Komfort",
    32402: "Komfort",
    32501: "Komfort",
    32601: "Komfort",
    32701: "Komfort",
    32702: "Komfort",
    32703: "Komfort",
    32704: "Komfort",
    32705: "Komfort",
    32706: "Komfort",
    32707: "Komfort",
    32708: "Komfort",
    32709: "Komfort",
    32710: "Komfort",
    32711: "Komfort",
    32801: "Komfort",
    32901: "Komfort",
    33001: "Komfort",
    33101: "Komfort",
    33301: "Dører",
    33302: "Dører",
    33401: "Motor",
    33501: "Motor",
    33502: "Motor",
    33701: "Drivstoff",
    33702: "Drivstoff",
    33703: "Drivstoff",
    33704: "Drivstoff",
    33705: "Drivstoff",
    33706: "Drivstoff",
    33707: "Drivstoff",
    33708: "Drivstoff",
    33709: "Drivstoff",
    33710: "Drivstoff",
    33801: "Instrumentering",
    33802: "Instrumentering",
    33803: "Instrumentering",
    33901: "Interiør",
    33902: "Interiør",
    34101: "Interiør",
    34102: "Interiør",
    34103: "Interiør",
    34104: "Interiør",
    34105: "Interiør",
    34201: "Instrumentering",
    34301: "Lys",
    34401: "Lasterom",
    34501: "Lasterom",
    34502: "Lasterom",
    34601: "Låser",
    34701: "Nasjonale data",
    34702: "Nasjonale data",
    34703: "Nasjonale data",
    34704: "Nasjonale data",
    34801: "Nasjonale data",
    34802: "Nasjonale data",
    34803: "Nasjonale data",
    34804: "Nasjonale data",
    35301: "Ytelser",
    35302: "Ytelser",
    35303: "Ytelser",
    35304: "Ytelser",
    35305: "Ytelser",
    35306: "Ytelser",
    35307: "Ytelser",
    35308: "Ytelser",
    35309: "Ytelser",
    35401: "Ytelser",
    35402: "Ytelser",
    35403: "Ytelser",
    35501: "Ytelser",
    35502: "Ytelser",
    35503: "Ytelser",
    35504: "Ytelser",
    35505: "Ytelser",
    35506: "Ytelser",
    35507: "Ytelser",
    35508: "Ytelser",
    35509: "Ytelser",
    35510: "Ytelser",
    35511: "Ytelser",
    35512: "Ytelser",
    35513: "Ytelser",
    35514: "Ytelser",
    35515: "Ytelser",
    35516: "Ytelser",
    35517: "Ytelser",
    35518: "Ytelser",
    35519: "Ytelser",
    35601: "Sikkerhet",
    35701: "Seter",
    35801: "Sikt",
    36001: "Rest",
    36002: "Rest",
    36003: "Rest",
    36901: "Tak",
    36902: "Tak",
    37001: "Motor",
    37101: "Nasjonale data",
    37102: "Nasjonale data",
    37103: "Nasjonale data",
    37104: "Nasjonale data",
    37105: "Nasjonale data",
    37106: "Nasjonale data",
    37107: "Nasjonale data",
    37108: "Nasjonale data",
    37109: "Nasjonale data",
    37201: "Kampanje",
    37202: "Kampanje",
    37203: "Kampanje",
    37204: "Kampanje",
    37205: "Kampanje",
    37206: "Kampanje",
    37207: "Kampanje",
    37301: "Ikke-kodet utstyr",
    37302: "Ikke-kodet utstyr",
    37303: "Ikke-kodet utstyr",
    37304: "Ikke-kodet utstyr",
    37401: "Bremser",
    37501: "Komfort",
    37502: "Komfort",
    37503: "Komfort",
    37504: "Komfort",
    37505: "Komfort",
    37506: "Komfort",
    37507: "Komfort",
    37508: "Komfort",
    37509: "Komfort",
    37601: "Komfort",
    37701: "Komfort",
    37702: "Komfort",
    37703: "Komfort",
    37704: "Komfort",
    37705: "Komfort",
    37706: "Komfort",
    37707: "Komfort",
    37708: "Komfort",
    37801: "Komfort",
    37901: "Komfort",
    37902: "Komfort",
    38001: "Komfort",
    38002: "Komfort",
    38003: "Komfort",
    38004: "Komfort",
    38005: "Komfort",
    38006: "Komfort",
    38007: "Komfort",
    38008: "Komfort",
    38009: "Komfort",
    38101: "Komfort",
    38201: "Kundespesifikt utstyr",
    38202: "Kundespesifikt utstyr",
    38301: "Motor",
    38302: "Motor",
    38303: "Motor",
    38401: "Motor",
    38501: "Motor",
    38601: "Ikke-kodet utstyr",
    38602: "Ikke-kodet utstyr",
    38603: "Ikke-kodet utstyr",
    38701: "Service",
    38702: "Service",
    38703: "Service",
    38704: "Service",
    38705: "Service",
    38801: "Service",
    38802: "Service",
    38803: "Service",
    38804: "Service",
    38805: "Service",
    38901: "Sikt",
    39101: "Musikkanlegg",
    39301: "Kundespesifikt utstyr",
    39302: "Kundespesifikt utstyr",
    39401: "Kundespesifikt utstyr",
    39402: "Kundespesifikt utstyr",
    39501: "Kundespesifikt utstyr",
    39502: "Kundespesifikt utstyr",
    39601: "Kundespesifikt utstyr",
    39602: "Kundespesifikt utstyr",
    41001: "Musikkanlegg",
    41002: "Musikkanlegg",
    41003: "Musikkanlegg",
    41004: "Musikkanlegg",
    41101: "Bremser",
    41201: "Bremser",
    41301: "Bremser",
    41401: "Sikkerhet",
    41402: "Komfort",
    41501: "Komfort",
    41601: "Motor",
    41602: "Motor",
    41801: "Lasterom",
    41901: "Ikke-kodet utstyr",
    42001: "Ytelser",
    42002: "Ytelser",
    42003: "Ytelser",
    42004: "Ytelser",
    42005: "Ytelser",
    42006: "Ytelser",
    42007: "Ytelser",
    42008: "Ytelser",
    42009: "Ytelser",
    42010: "Ytelser",
    42011: "Ytelser",
    42012: "Ytelser",
    42013: "Ytelser",
    42014: "Ytelser",
    42015: "Ytelser",
    42016: "Ytelser",
    42017: "Ytelser",
    42018: "Ytelser",
    42019: "Ytelser",
    42020: "Ytelser",
    42021: "Ytelser",
    42022: "Ytelser",
    42023: "Ytelser",
    42024: "Ytelser",
    42025: "Ytelser",
    42026: "Ytelser",
    42027: "Ytelser",
    42028: "Ytelser",
    42029: "Ytelser",
    42030: "Ytelser",
    42031: "Ytelser",
    42032: "Ytelser",
    42033: "Ytelser",
    42034: "Ytelser",
    42035: "Ytelser",
    42036: "Ytelser",
    42037: "Ytelser",
    42038: "Ytelser",
    42039: "Ytelser",
    42040: "Ytelser",
    42041: "Ytelser",
    42042: "Ytelser",
    42043: "Ytelser",
    42044: "Ytelser",
    42045: "Ytelser",
    42046: "Ytelser",
    42047: "Ytelser",
    42048: "Ytelser",
    42049: "Ytelser",
    42101: "Sikkerhet",
    42102: "Sikkerhet",
    42103: "Sikkerhet",
    42104: "Sikkerhet",
    42105: "Sikkerhet",
    42106: "Sikkerhet",
    42107: "Sikkerhet",
    42108: "Sikkerhet",
    42109: "Sikkerhet",
    42110: "Sikkerhet",
    42111: "Sikkerhet",
    42112: "Sikkerhet",
    42113: "Sikkerhet",
    42114: "Sikkerhet",
    42115: "Sikkerhet",
    42116: "Sikkerhet",
    42117: "Sikkerhet",
    42118: "Sikkerhet",
    42119: "Sikkerhet",
    42120: "Sikkerhet",
    42121: "Sikkerhet",
    42122: "Sikkerhet",
    42123: "Sikkerhet",
    42124: "Sikkerhet",
    42125: "Sikkerhet",
    42126: "Sikkerhet",
    42127: "Sikkerhet",
    42128: "Sikkerhet",
    42129: "Sikkerhet",
    42130: "Sikkerhet",
    42201: "Sikkerhet",
    42202: "Sikkerhet",
    42301: "Oppbevaringsrom",
    42302: "Oppbevaringsrom",
    42303: "Oppbevaringsrom",
    42601: "Sikt",
    42701: "Sikt",
    42801: "Hjul",
    43101: "Eksteriør",
    43201: "Bremser",
    43202: "Bremser",
    43301: "Bremser",
    43401: "Komfort",
    43501: "Dører",
    43502: "Dører",
    43503: "Dører",
    43504: "Dører",
    43505: "Dører",
    43506: "Dører",
    43601: "Interiør",
    43701: "Lys",
    43801: "Låser",
    43901: "Låser",
    44001: "Sikkerhet",
    44101: "Sikkerhet",
    44102: "Sikkerhet",
    44103: "Sikkerhet",
    44104: "Sikkerhet",
    44105: "Sikkerhet",
    44106: "Sikkerhet",
    44107: "Sikkerhet",
    44108: "Sikkerhet",
    44109: "Sikkerhet",
    44110: "Sikkerhet",
    44111: "Sikkerhet",
    44112: "Sikkerhet",
    44201: "Transmisjon",
    44301: "Transmisjon",
    44302: "Transmisjon",
    44401: "Sikkerhet",
    44402: "Sikkerhet",
    44701: "Komfort",
    44801: "Komfort",
    44802: "Komfort",
    44803: "Komfort",
    44804: "Komfort",
    44901: "Dører",
    44902: "Dører",
    44903: "Dører",
    44904: "Dører",
    45001: "Motor",
    45002: "Motor",
    45101: "Kampanje",
    45102: "Kampanje",
    45103: "Kampanje",
    45104: "Kampanje",
    45105: "Kampanje",
    45106: "Kampanje",
    45107: "Kampanje",
    45108: "Kampanje",
    45109: "Kampanje",
    45110: "Kampanje",
    45111: "Kampanje",
    45112: "Kampanje",
    45113: "Kampanje",
    45114: "Kampanje",
    45115: "Kampanje",
    45116: "Kampanje",
    45117: "Kampanje",
    45118: "Kampanje",
    45119: "Kampanje",
    45120: "Kampanje",
    45121: "Kampanje",
    45122: "Kampanje",
    45123: "Kampanje",
    45124: "Kampanje",
    45125: "Kampanje",
    45126: "Kampanje",
    45127: "Kampanje",
    45128: "Kampanje",
    45129: "Kampanje",
    45130: "Kampanje",
    45131: "Kampanje",
    45132: "Kampanje",
    45133: "Kampanje",
    45134: "Kampanje",
    45135: "Kampanje",
    45136: "Kampanje",
    45137: "Kampanje",
    45138: "Kampanje",
    45139: "Kampanje",
    45140: "Kampanje",
    45141: "Kampanje",
    45201: "Kampanje",
    45202: "Kampanje",
    45203: "Kampanje",
    45204: "Kampanje",
    45205: "Kampanje",
    45206: "Kampanje",
    45207: "Kampanje",
    45208: "Kampanje",
    45209: "Kampanje",
    45401: "Kampanje",
    45402: "Kampanje",
    45403: "Kampanje",
    45404: "Kampanje",
    45405: "Kampanje",
    45406: "Kampanje",
    45407: "Kampanje",
    45408: "Kampanje",
    45409: "Kampanje",
    45410: "Kampanje",
    45411: "Kampanje",
    45412: "Kampanje",
    45413: "Kampanje",
    45801: "Kampanje",
    45802: "Kampanje",
    45803: "Kampanje",
    45804: "Kampanje",
    45805: "Kampanje",
    45901: "Lasterom",
    46001: "Nasjonale data",
    46002: "Nasjonale data",
    46101: "Ikke-kodet utstyr",
    46102: "Ikke-kodet utstyr",
    46201: "Sikkerhet",
    46202: "Sikkerhet",
    46203: "Sikkerhet",
    46204: "Sikkerhet",
    46301: "Sikkerhet",
    46401: "Transmisjon",
    46402: "Transmisjon",
    46403: "Transmisjon",
    46501: "Transmisjon",
    46601: "Sikt",
    46602: "Sikt",
    46701: "Sikkerhet",
    46702: "Sikkerhet",
    46801: "Sikt",
    46802: "Sikt",
    46901: "Musikkanlegg",
    46902: "Musikkanlegg",
    46903: "Musikkanlegg",
    46904: "Musikkanlegg",
    46905: "Musikkanlegg",
    46906: "Musikkanlegg",
    46907: "Musikkanlegg",
    46908: "Musikkanlegg",
    46909: "Musikkanlegg",
    46910: "Musikkanlegg",
    46911: "Musikkanlegg",
    46912: "Musikkanlegg",
    46913: "Musikkanlegg",
    47001: "Kampanje",
    47002: "Kampanje",
    47005: "Kampanje",
    47006: "Kampanje",
    47007: "Kampanje",
    47008: "Kampanje",
    47009: "Kampanje",
    47010: "Kampanje",
    47011: "Kampanje",
    47012: "Kampanje",
    47013: "Kampanje",
    47014: "Kampanje",
    47015: "Kampanje",
    47016: "Kampanje",
    47017: "Kampanje",
    47018: "Kampanje",
    47019: "Kampanje",
    47020: "Kampanje",
    47021: "Kampanje",
    47022: "Kampanje",
    47023: "Kampanje",
    47024: "Kampanje",
    47025: "Kampanje",
    47026: "Kampanje",
    47027: "Kampanje",
    47028: "Kampanje",
    47029: "Kampanje",
    47030: "Kampanje",
    47031: "Kampanje",
    47032: "Kampanje",
    47033: "Kampanje",
    47034: "Kampanje",
    47101: "Kampanje",
    47102: "Kampanje",
    47103: "Kampanje",
    47104: "Kampanje",
    47201: "Kampanje",
    47202: "Kampanje",
    47301: "Kampanje",
    47302: "Kampanje",
    47303: "Kampanje",
    47401: "Kampanje",
    47402: "Kampanje",
    47501: "Kampanje",
    47502: "Kampanje",
    47503: "Kampanje",
    47504: "Kampanje",
    47505: "Kampanje",
    47506: "Kampanje",
    47507: "Kampanje",
    47508: "Kampanje",
    47509: "Kampanje",
    47510: "Kampanje",
    47511: "Kampanje",
    47512: "Kampanje",
    47513: "Kampanje",
    47514: "Kampanje",
    47515: "Kampanje",
    47516: "Kampanje",
    47517: "Kampanje",
    47518: "Kampanje",
    47521: "Kampanje",
    47522: "Kampanje",
    47523: "Kampanje",
    47524: "Kampanje",
    47534: "Kampanje",
    47536: "Kampanje",
    47537: "Kampanje",
    47538: "Kampanje",
    47539: "Kampanje",
    47540: "Kampanje",
    47541: "Kampanje",
    47542: "Kampanje",
    47543: "Kampanje",
    47544: "Kampanje",
    47545: "Kampanje",
    47546: "Kampanje",
    47547: "Kampanje",
    47548: "Kampanje",
    47549: "Kampanje",
    47550: "Kampanje",
    47551: "Kampanje",
    47552: "Kampanje",
    47553: "Kampanje",
    47554: "Kampanje",
    47555: "Kampanje",
    47556: "Kampanje",
    47557: "Kampanje",
    47558: "Kampanje",
    47559: "Kampanje",
    47560: "Kampanje",
    47561: "Kampanje",
    47601: "Kampanje",
    47602: "Kampanje",
    47603: "Kampanje",
    47604: "Kampanje",
    47605: "Kampanje",
    47606: "Kampanje",
    47607: "Kampanje",
    47608: "Kampanje",
    47609: "Kampanje",
    47610: "Kampanje",
    47611: "Kampanje",
    47612: "Kampanje",
    47613: "Kampanje",
    47614: "Kampanje",
    47615: "Kampanje",
    47616: "Kampanje",
    47619: "Kampanje",
    47620: "Kampanje",
    47621: "Kampanje",
    47622: "Kampanje",
    47631: "Kampanje",
    47632: "Kampanje",
    47633: "Kampanje",
    47634: "Kampanje",
    47635: "Kampanje",
    47636: "Kampanje",
    47637: "Kampanje",
    47638: "Kampanje",
    47639: "Kampanje",
    47640: "Kampanje",
    47641: "Kampanje",
    47642: "Kampanje",
    47643: "Kampanje",
    47644: "Kampanje",
    47645: "Kampanje",
    47646: "Kampanje",
    47647: "Kampanje",
    47648: "Kampanje",
    47649: "Kampanje",
    47650: "Kampanje",
    47701: "Kampanje",
    47702: "Kampanje",
    47703: "Kampanje",
    47704: "Kampanje",
    47705: "Kampanje",
    47707: "Kampanje",
    47801: "Kampanje",
    47802: "Kampanje",
    47803: "Kampanje",
    47804: "Kampanje",
    47805: "Kampanje",
    47901: "Kampanje",
    47902: "Kampanje",
    47903: "Kampanje",
    48001: "Kampanje",
    48004: "Kampanje",
    48201: "Kampanje",
    48202: "Kampanje",
    48701: "Eksteriør",
    48702: "Eksteriør",
    48801: "Eksteriør",
    48901: "Bremser",
    48902: "Bremser",
    49001: "Avgifter",
    49002: "Avgifter",
    49003: "Avgifter",
    49004: "Avgifter",
    49005: "Avgifter",
    49006: "Avgifter",
    49101: "Komfort",
    49201: "Komfort",
    49301: "Komfort",
    49302: "Komfort",
    49401: "Komfort",
    49402: "Komfort",
    49403: "Komfort",
    49501: "Komfort",
    49502: "Komfort",
    49601: "Komfort",
    49602: "Komfort",
    49701: "Komfort",
    49702: "Komfort",
    49801: "Motor",
    49901: "Motor",
    49902: "Motor",
    50001: "Instrumentering",
    50002: "Instrumentering",
    50003: "Instrumentering",
    50004: "Instrumentering",
    50005: "Instrumentering",
    50006: "Instrumentering",
    50007: "Instrumentering",
    50008: "Instrumentering",
    50101: "Interiør",
    50102: "Interiør",
    50201: "Lys",
    50202: "Lys",
    50203: "Lys",
    50301: "Ikke-kodet utstyr",
    50302: "Ikke-kodet utstyr",
    50303: "Ikke-kodet utstyr",
    50401: "Sikkerhet",
    50501: "Sikkerhet",
    50601: "Sikkerhet",
    50701: "Seter",
    50801: "Ytelser",
    50802: "Ytelser",
    50803: "Ytelser",
    50804: "Ytelser",
    50805: "Ytelser",
    50806: "Ytelser",
    50807: "Ytelser",
    50808: "Ytelser",
    50809: "Ytelser",
    50810: "Ytelser",
    50811: "Ytelser",
    50812: "Ytelser",
    50813: "Ytelser",
    51001: "Garanti",
    51002: "Garanti",
    51003: "Garanti",
    51004: "Garanti",
    51101: "Vekter",
    51102: "Vekter",
    51103: "Vekter",
    51104: "Vekter",
    51105: "Vekter",
    51201: "Kampanje",
    51202: "Kampanje",
    51203: "Kampanje",
    51204: "Kampanje",
    51205: "Kampanje",
    51206: "Kampanje",
    51207: "Kampanje",
    51208: "Kampanje",
    51209: "Kampanje",
    51210: "Kampanje",
    51211: "Kampanje",
    51212: "Kampanje",
    51213: "Kampanje",
    51214: "Kampanje",
    51215: "Kampanje",
    51216: "Kampanje",
    51301: "Motor",
    51302: "Motor",
    51303: "Motor",
    51304: "Motor",
    51401: "Komfort",
    51501: "Motor",
    51502: "Motor",
    51503: "Motor",
    51601: "Drivstoff",
    51602: "Drivstoff",
    51701: "Drivstoff",
    51702: "Drivstoff",
    51801: "Hybrid og elektrisk system",
    51802: "Hybrid og elektrisk system",
    51803: "Hybrid og elektrisk system",
    51901: "Hybrid og elektrisk system",
    52001: "Hybrid og elektrisk system",
    52002: "Hybrid og elektrisk system",
    52101: "Hybrid og elektrisk system",
    52102: "Hybrid og elektrisk system",
    52201: "Instrumentering",
    52301: "Nasjonale data",
    52302: "Nasjonale data",
    52303: "Nasjonale data",
    52304: "Nasjonale data",
    52305: "Nasjonale data",
    52306: "Nasjonale data",
    52307: "Nasjonale data",
    52308: "Nasjonale data",
    52309: "Nasjonale data",
    52310: "Nasjonale data",
    52311: "Nasjonale data",
    52312: "Nasjonale data",
    52313: "Nasjonale data",
    52401: "Transmisjon",
    52402: "Transmisjon",
    52501: "Garanti",
    52502: "Garanti",
    52503: "Garanti",
    52504: "Garanti",
    52601: "Hjul",
    52602: "Hjul",
    52603: "Hjul",
    52604: "Hjul",
    52701: "Kampanje",
    52702: "Kampanje",
    52801: "Komfort",
    52802: "Komfort",
    52901: "Komfort",
    52902: "Komfort",
    52903: "Komfort",
    52904: "Komfort",
    52905: "Komfort",
    52906: "Komfort",
    52907: "Komfort",
    53001: "Komfort",
    53002: "Komfort",
    53101: "Komfort",
    53102: "Komfort",
    53103: "Komfort",
    53104: "Komfort",
    53105: "Komfort",
    53106: "Komfort",
    53201: "Komfort",
    53301: "Motor",
    53302: "Motor",
    53303: "Motor",
    53304: "Motor",
    53401: "Hybrid og elektrisk system",
    53402: "Hybrid og elektrisk system",
    53403: "Hybrid og elektrisk system",
    53404: "Hybrid og elektrisk system",
    53405: "Hybrid og elektrisk system",
    53406: "Hybrid og elektrisk system",
    53407: "Hybrid og elektrisk system",
    53501: "Hybrid og elektrisk system",
    53502: "Hybrid og elektrisk system",
    53503: "Hybrid og elektrisk system",
    53504: "Hybrid og elektrisk system",
    53505: "Hybrid og elektrisk system",
    53506: "Hybrid og elektrisk system",
    53601: "Lasterom",
    53602: "Lasterom",
    53603: "Lasterom",
    53701: "Regionale priser",
    53702: "Regionale priser",
    53703: "Regionale priser",
    53704: "Regionale priser",
    53705: "Regionale priser",
    53706: "Regionale priser",
    53707: "Regionale priser",
    53708: "Regionale priser",
    53709: "Regionale priser",
    53710: "Regionale priser",
    53711: "Regionale priser",
    53712: "Regionale priser",
    53713: "Regionale priser",
    53714: "Regionale priser",
    53715: "Regionale priser",
    53716: "Regionale priser",
    53717: "Regionale priser",
    53718: "Regionale priser",
    53719: "Regionale priser",
    53720: "Regionale priser",
    53721: "Regionale priser",
    53722: "Regionale priser",
    53723: "Regionale priser",
    53724: "Regionale priser",
    53725: "Regionale priser",
    53726: "Regionale priser",
    53727: "Regionale priser",
    53728: "Regionale priser",
    53729: "Regionale priser",
    53730: "Regionale priser",
    53731: "Regionale priser",
    53732: "Regionale priser",
    53733: "Regionale priser",
    53734: "Regionale priser",
    53735: "Regionale priser",
    53736: "Regionale priser",
    53737: "Regionale priser",
    53801: "Regionale priser",
    53802: "Regionale priser",
    53803: "Regionale priser",
    53804: "Regionale priser",
    53805: "Regionale priser",
    53806: "Regionale priser",
    53807: "Regionale priser",
    53808: "Regionale priser",
    53809: "Regionale priser",
    53810: "Regionale priser",
    53811: "Regionale priser",
    53812: "Regionale priser",
    53813: "Regionale priser",
    53814: "Regionale priser",
    53815: "Regionale priser",
    53816: "Regionale priser",
    53817: "Regionale priser",
    53818: "Regionale priser",
    53819: "Regionale priser",
    53820: "Regionale priser",
    53821: "Regionale priser",
    53822: "Regionale priser",
    53823: "Regionale priser",
    53824: "Regionale priser",
    53825: "Regionale priser",
    53826: "Regionale priser",
    53827: "Regionale priser",
    53828: "Regionale priser",
    53829: "Regionale priser",
    53830: "Regionale priser",
    53831: "Regionale priser",
    53832: "Regionale priser",
    53833: "Regionale priser",
    53834: "Regionale priser",
    53835: "Regionale priser",
    53836: "Regionale priser",
    53837: "Regionale priser",
    53901: "Regionale priser",
    53902: "Regionale priser",
    53903: "Regionale priser",
    53904: "Regionale priser",
    53905: "Regionale priser",
    53906: "Regionale priser",
    53907: "Regionale priser",
    53908: "Regionale priser",
    53909: "Regionale priser",
    53910: "Regionale priser",
    53911: "Regionale priser",
    53912: "Regionale priser",
    53913: "Regionale priser",
    53914: "Regionale priser",
    53915: "Regionale priser",
    53916: "Regionale priser",
    53917: "Regionale priser",
    53918: "Regionale priser",
    53919: "Regionale priser",
    53920: "Regionale priser",
    53921: "Regionale priser",
    53922: "Regionale priser",
    53923: "Regionale priser",
    53924: "Regionale priser",
    53925: "Regionale priser",
    53926: "Regionale priser",
    53927: "Regionale priser",
    53928: "Regionale priser",
    53929: "Regionale priser",
    53930: "Regionale priser",
    53931: "Regionale priser",
    53932: "Regionale priser",
    53933: "Regionale priser",
    53934: "Regionale priser",
    53935: "Regionale priser",
    53936: "Regionale priser",
    53937: "Regionale priser",
    54001: "Regionale priser",
    54002: "Regionale priser",
    54003: "Regionale priser",
    54004: "Regionale priser",
    54005: "Regionale priser",
    54006: "Regionale priser",
    54007: "Regionale priser",
    54008: "Regionale priser",
    54009: "Regionale priser",
    54010: "Regionale priser",
    54011: "Regionale priser",
    54012: "Regionale priser",
    54013: "Regionale priser",
    54014: "Regionale priser",
    54015: "Regionale priser",
    54016: "Regionale priser",
    54017: "Regionale priser",
    54018: "Regionale priser",
    54019: "Regionale priser",
    54020: "Regionale priser",
    54021: "Regionale priser",
    54022: "Regionale priser",
    54023: "Regionale priser",
    54024: "Regionale priser",
    54025: "Regionale priser",
    54026: "Regionale priser",
    54027: "Regionale priser",
    54028: "Regionale priser",
    54029: "Regionale priser",
    54030: "Regionale priser",
    54031: "Regionale priser",
    54032: "Regionale priser",
    54033: "Regionale priser",
    54034: "Regionale priser",
    54035: "Regionale priser",
    54036: "Regionale priser",
    54037: "Regionale priser",
    54101: "Regionale priser",
    54102: "Regionale priser",
    54103: "Regionale priser",
    54104: "Regionale priser",
    54105: "Regionale priser",
    54106: "Regionale priser",
    54107: "Regionale priser",
    54108: "Regionale priser",
    54109: "Regionale priser",
    54110: "Regionale priser",
    54111: "Regionale priser",
    54112: "Regionale priser",
    54113: "Regionale priser",
    54114: "Regionale priser",
    54115: "Regionale priser",
    54116: "Regionale priser",
    54117: "Regionale priser",
    54118: "Regionale priser",
    54119: "Regionale priser",
    54120: "Regionale priser",
    54121: "Regionale priser",
    54122: "Regionale priser",
    54123: "Regionale priser",
    54124: "Regionale priser",
    54125: "Regionale priser",
    54126: "Regionale priser",
    54127: "Regionale priser",
    54128: "Regionale priser",
    54129: "Regionale priser",
    54130: "Regionale priser",
    54131: "Regionale priser",
    54132: "Regionale priser",
    54133: "Regionale priser",
    54134: "Regionale priser",
    54135: "Regionale priser",
    54136: "Regionale priser",
    54137: "Regionale priser",
    54201: "Regionale priser",
    54202: "Regionale priser",
    54203: "Regionale priser",
    54204: "Regionale priser",
    54205: "Regionale priser",
    54206: "Regionale priser",
    54207: "Regionale priser",
    54208: "Regionale priser",
    54209: "Regionale priser",
    54210: "Regionale priser",
    54211: "Regionale priser",
    54212: "Regionale priser",
    54213: "Regionale priser",
    54214: "Regionale priser",
    54215: "Regionale priser",
    54216: "Regionale priser",
    54217: "Regionale priser",
    54218: "Regionale priser",
    54219: "Regionale priser",
    54220: "Regionale priser",
    54221: "Regionale priser",
    54222: "Regionale priser",
    54223: "Regionale priser",
    54224: "Regionale priser",
    54225: "Regionale priser",
    54226: "Regionale priser",
    54227: "Regionale priser",
    54228: "Regionale priser",
    54229: "Regionale priser",
    54230: "Regionale priser",
    54231: "Regionale priser",
    54232: "Regionale priser",
    54233: "Regionale priser",
    54234: "Regionale priser",
    54235: "Regionale priser",
    54236: "Regionale priser",
    54237: "Regionale priser",
    54301: "Regionale priser",
    54302: "Regionale priser",
    54303: "Regionale priser",
    54304: "Regionale priser",
    54305: "Regionale priser",
    54306: "Regionale priser",
    54307: "Regionale priser",
    54308: "Regionale priser",
    54309: "Regionale priser",
    54310: "Regionale priser",
    54311: "Regionale priser",
    54312: "Regionale priser",
    54313: "Regionale priser",
    54314: "Regionale priser",
    54315: "Regionale priser",
    54316: "Regionale priser",
    54317: "Regionale priser",
    54318: "Regionale priser",
    54319: "Regionale priser",
    54320: "Regionale priser",
    54321: "Regionale priser",
    54322: "Regionale priser",
    54323: "Regionale priser",
    54324: "Regionale priser",
    54325: "Regionale priser",
    54326: "Regionale priser",
    54327: "Regionale priser",
    54328: "Regionale priser",
    54329: "Regionale priser",
    54330: "Regionale priser",
    54331: "Regionale priser",
    54332: "Regionale priser",
    54333: "Regionale priser",
    54334: "Regionale priser",
    54335: "Regionale priser",
    54336: "Regionale priser",
    54337: "Regionale priser",
    54401: "Regionale priser",
    54402: "Regionale priser",
    54403: "Regionale priser",
    54404: "Regionale priser",
    54405: "Regionale priser",
    54406: "Regionale priser",
    54407: "Regionale priser",
    54408: "Regionale priser",
    54409: "Regionale priser",
    54410: "Regionale priser",
    54411: "Regionale priser",
    54412: "Regionale priser",
    54413: "Regionale priser",
    54414: "Regionale priser",
    54415: "Regionale priser",
    54416: "Regionale priser",
    54417: "Regionale priser",
    54418: "Regionale priser",
    54419: "Regionale priser",
    54420: "Regionale priser",
    54421: "Regionale priser",
    54422: "Regionale priser",
    54423: "Regionale priser",
    54424: "Regionale priser",
    54425: "Regionale priser",
    54426: "Regionale priser",
    54427: "Regionale priser",
    54428: "Regionale priser",
    54429: "Regionale priser",
    54430: "Regionale priser",
    54431: "Regionale priser",
    54432: "Regionale priser",
    54433: "Regionale priser",
    54434: "Regionale priser",
    54435: "Regionale priser",
    54436: "Regionale priser",
    54437: "Regionale priser",
    54501: "Sikkerhet",
    54601: "Sikkerhet",
    54701: "Sikkerhet",
    54801: "Sikkerhet",
    54802: "Sikkerhet",
    54901: "Sikt",
    55001: "Ventilasjon",
    55101: "Komfort",
    55102: "Komfort",
    55103: "Komfort",
    55104: "Komfort",
    55105: "Komfort",
    55106: "Komfort",
    55201: "Komfort",
    55202: "Komfort",
    55203: "Komfort",
    55204: "Komfort",
    55301: "Tak",
    55401: "Ytelser",
    55402: "Ytelser",
    55403: "Ytelser",
    55404: "Ytelser",
    55405: "Ytelser",
    55406: "Ytelser",
    55407: "Ytelser",
    55408: "Ytelser",
    55409: "Ytelser",
    55410: "Ytelser",
    55411: "Ytelser",
    55412: "Ytelser",
    55413: "Ytelser",
    55414: "Ytelser",
    55415: "Ytelser",
    55416: "Ytelser",
    55417: "Ytelser",
    55418: "Ytelser",
    55419: "Ytelser",
    55420: "Ytelser",
    55421: "Ytelser",
    55422: "Ytelser",
    55423: "Ytelser",
    55424: "Ytelser",
    55425: "Ytelser",
    55501: "Sikkerhet",
    55601: "Hybrid og elektrisk system",
    55602: "Hybrid og elektrisk system",
    55603: "Hybrid og elektrisk system",
    55604: "Hybrid og elektrisk system",
    55605: "Hybrid og elektrisk system",
    55701: "Eksteriør",
    55801: "Komfort",
    55901: "Komfort",
    55902: "Komfort",
    56001: "Komfort",
    56002: "Komfort",
    56101: "Komfort",
    56102: "Komfort",
    56201: "Komfort",
    56301: "Komfort",
    56302: "Komfort",
    56303: "Komfort",
    56401: "Komfort",
    56501: "Komfort",
    56601: "Komfort",
    56701: "Komfort",
    56801: "Interiør",
    56802: "Interiør",
    56901: "Motor",
    57001: "Drivstoff",
    57201: "Sikkerhet",
    57202: "Sikkerhet",
    57301: "Sikkerhet",
    57302: "Sikkerhet",
    57401: "Sikkerhet",
    57402: "Sikkerhet",
    57403: "Sikkerhet",
    57501: "Sikkerhet",
    57601: "Sikkerhet",
    57602: "Sikkerhet",
    57701: "Sikkerhet",
    57702: "Sikkerhet",
    57801: "Sikkerhet",
    57802: "Sikkerhet",
    57901: "Service",
    57902: "Service",
    57903: "Service",
    57904: "Service",
    58001: "Sikt",
    58101: "Sikt",
    58102: "Sikt",
    58201: "Garanti",
    58202: "Garanti",
    58203: "Garanti",
    58204: "Garanti",
    58301: "Garanti",
    58302: "Garanti",
    58303: "Garanti",
    58304: "Garanti",
    58401: "Garanti",
    58402: "Garanti",
    58403: "Garanti",
    58404: "Garanti",
    58501: "Garanti",
    58601: "Garanti",
    58602: "Garanti",
    58701: "Styring",
    58702: "Styring",
    58703: "Styring",
    58704: "Styring",
    58705: "Styring",
    58801: "Værbeskyttelse",
    58802: "Værbeskyttelse",
    58803: "Værbeskyttelse",
    58901: "Værbeskyttelse",
    58902: "Værbeskyttelse",
    58903: "Værbeskyttelse",
    59001: "Kampanje",
    59002: "Kampanje",
    59003: "Kampanje",
    59004: "Kampanje",
    59101: "Kampanje",
    59102: "Kampanje",
    59103: "Kampanje",
    59104: "Kampanje",
    59201: "Garanti",
    59202: "Garanti",
    59203: "Garanti",
    59204: "Garanti",
    59301: "Garanti",
    59302: "Garanti",
    59303: "Garanti",
    59304: "Garanti",
    59401: "Tak",
    59402: "Tak",
    59601: "Eksteriør",
    59701: "Komfort",
    59801: "Komfort",
    59802: "Komfort",
    59803: "Komfort",
    59804: "Komfort",
    59805: "Komfort",
    59806: "Komfort",
    59807: "Komfort",
    59901: "Komfort",
    60001: "Lasterom",
    60101: "Ytelser",
    60102: "Ytelser",
    60103: "Ytelser",
    60104: "Ytelser",
    60105: "Ytelser",
    60106: "Ytelser",
    60107: "Ytelser",
    60108: "Ytelser",
    60109: "Ytelser",
    60110: "Ytelser",
    60111: "Ytelser",
    60112: "Ytelser",
    60113: "Ytelser",
    60114: "Ytelser",
    60115: "Ytelser",
    60116: "Ytelser",
    60117: "Ytelser",
    60118: "Ytelser",
    60119: "Ytelser",
    60201: "Sikkerhet",
    60301: "Sikkerhet",
    60302: "Sikkerhet",
    60303: "Sikkerhet",
    60304: "Sikkerhet",
    60305: "Sikkerhet",
    60306: "Sikkerhet",
    60307: "Sikkerhet",
    60308: "Sikkerhet",
    60309: "Sikkerhet",
    60310: "Sikkerhet",
    61401: "Komfort",
    61402: "Komfort",
    61403: "Komfort",
    61701: "Eksteriør",
    61702: "Eksteriør",
    61703: "Eksteriør",
    61801: "Eksteriør",
    61901: "Komfort",
    62001: "Komfort",
    62101: "Komfort",
    62102: "Komfort",
    62103: "Komfort",
    62104: "Komfort",
    62105: "Komfort",
    62201: "Motor",
    62202: "Motor",
    62203: "Motor",
    62204: "Motor",
    62205: "Motor",
    62206: "Motor",
    62207: "Motor",
    62208: "Motor",
    62209: "Motor",
    62210: "Motor",
    62211: "Motor",
    62212: "Motor",
    62213: "Motor",
    62214: "Motor",
    62215: "Motor",
    62216: "Motor",
    62217: "Motor",
    62301: "Motor",
    62302: "Motor",
    62401: "Hybrid og elektrisk system",
    62402: "Hybrid og elektrisk system",
    62501: "Hybrid og elektrisk system",
    62502: "Hybrid og elektrisk system",
    62503: "Hybrid og elektrisk system",
    62601: "Lasterom",
    62701: "Lasterom",
    62801: "Ytelser",
    62802: "Ytelser",
    62803: "Ytelser",
    62804: "Ytelser",
    62805: "Ytelser",
    62806: "Ytelser",
    62807: "Ytelser",
    62808: "Ytelser",
    62809: "Ytelser",
    62810: "Ytelser",
    62811: "Ytelser",
    62812: "Ytelser",
    62813: "Ytelser",
    62814: "Ytelser",
    62815: "Ytelser",
    62816: "Ytelser",
    62817: "Ytelser",
    62818: "Ytelser",
    62819: "Ytelser",
    62820: "Ytelser",
    62901: "Ytelser",
    62902: "Ytelser",
    62903: "Ytelser",
    62904: "Ytelser",
    62905: "Ytelser",
    62906: "Ytelser",
    62907: "Ytelser",
    62908: "Ytelser",
    62909: "Ytelser",
    62910: "Ytelser",
    62911: "Ytelser",
    62912: "Ytelser",
    62913: "Ytelser",
    62914: "Ytelser",
    62915: "Ytelser",
    62916: "Ytelser",
    62917: "Ytelser",
    62918: "Ytelser",
    62919: "Ytelser",
    62920: "Ytelser",
    62921: "Ytelser",
    62922: "Ytelser",
    62923: "Ytelser",
    62924: "Ytelser",
    62925: "Ytelser",
    62926: "Ytelser",
    62927: "Ytelser",
    62928: "Ytelser",
    62929: "Ytelser",
    62930: "Ytelser",
    62931: "Ytelser",
    62932: "Ytelser",
    62933: "Ytelser",
    62934: "Ytelser",
    62935: "Ytelser",
    62936: "Ytelser",
    62937: "Ytelser",
    62938: "Ytelser",
    63001: "Tak",
    63101: "Tak",
    63201: "Transmisjon",
    63301: "Sikt",
    63302: "Sikt",
    63401: "Sikt",
    63402: "Sikt",
    103605: "Avgifter",
    105802: "Dimensjoner",
    105803: "Dimensjoner",
    105804: "Dimensjoner",
    105805: "Dimensjoner",
    105806: "Dimensjoner",
    105807: "Dimensjoner",
    105808: "Dimensjoner",
    105809: "Dimensjoner",
    105810: "Dimensjoner",
    105811: "Dimensjoner",
    105812: "Dimensjoner",
    105815: "Dimensjoner",
    105902: "Dimensjoner",
    105903: "Dimensjoner",
    105904: "Dimensjoner",
    105905: "Dimensjoner",
    105906: "Dimensjoner",
    105907: "Dimensjoner",
    105908: "Dimensjoner",
    105909: "Dimensjoner",
    105912: "Dimensjoner",
    105913: "Dimensjoner",
    105917: "Dimensjoner",
    105918: "Dimensjoner",
    105919: "Dimensjoner",
    105920: "Dimensjoner",
    105921: "Dimensjoner",
    105922: "Dimensjoner",
    105923: "Dimensjoner",
    106002: "Dimensjoner",
    106003: "Dimensjoner",
    106004: "Dimensjoner",
    106005: "Dimensjoner",
    106006: "Dimensjoner",
    106007: "Dimensjoner",
    106009: "Dimensjoner",
    106010: "Dimensjoner",
    106102: "Dimensjoner",
    106103: "Dimensjoner",
    106104: "Dimensjoner",
    106106: "Dimensjoner",
    106107: "Dimensjoner",
    106108: "Dimensjoner",
    106109: "Dimensjoner",
    106112: "Dimensjoner",
    106304: "Dører",
    106305: "Dører",
    108310: "Hybrid og elektrisk system",
    108903: "Drivstoff",
    108906: "Drivstoff",
    108993: "Drivstoff",
    113502: "Ytelser",
    113506: "Ytelser",
    115404: "Ytelser",
    115405: "Ytelser",
    115406: "Ytelser",
    115414: "Ytelser",
    123503: "Garanti",
    123603: "Garanti",
    123703: "Garanti",
    123803: "Garanti",
    123903: "Garanti",
    124003: "Garanti",
    124102: "Vekter",
    124103: "Vekter",
    124105: "Vekter",
    124106: "Vekter",
    124107: "Vekter",
    124108: "Vekter",
    124109: "Vekter",
    124110: "Vekter",
    124111: "Vekter",
    124113: "Vekter",
    124116: "Vekter",
    124117: "Vekter",
    124119: "Vekter",
    124120: "Vekter",
    124121: "Vekter",
    124123: "Vekter",
    124124: "Vekter",
    135402: "Ytelser",
    135502: "Ytelser",
    135503: "Ytelser",
    135504: "Ytelser",
    135506: "Ytelser",
    135507: "Ytelser",
    135582: "Ytelser",
    135583: "Ytelser",
    135584: "Ytelser",
    135588: "Ytelser",
    135589: "Ytelser",
    135590: "Ytelser",
    142003: "Ytelser",
    142004: "Ytelser",
    142005: "Ytelser",
    142006: "Ytelser",
    142007: "Ytelser",
    142008: "Ytelser",
    142022: "Ytelser",
    142023: "Ytelser",
    142026: "Ytelser",
    142027: "Ytelser",
    142028: "Ytelser",
    142032: "Ytelser",
    142033: "Ytelser",
    142034: "Ytelser",
    142038: "Ytelser",
    142039: "Ytelser",
    142040: "Ytelser",
    142044: "Ytelser",
    142045: "Ytelser",
    142046: "Ytelser",
    150802: "Ytelser",
    150803: "Ytelser",
    150804: "Ytelser",
    151102: "Vekter",
    151103: "Vekter",
    151105: "Vekter",
    153302: "Motor",
    160102: "Ytelser",
    160103: "Ytelser",
    160104: "Ytelser",
    160106: "Ytelser",
    160107: "Ytelser",
    160182: "Ytelser",
    160183: "Ytelser",
    160184: "Ytelser",
    160188: "Ytelser",
    160189: "Ytelser",
    160190: "Ytelser",
    400001: "JATO kode",
    400002: "JATO kode",
    400108: "JATO kode",
    400109: "JATO kode",
    400111: "JATO kode",
    400112: "JATO kode",
    400116: "JATO kode",
    400307: "JATO kode",
    400402: "JATO kode",
    400405: "JATO kode",
    400602: "JATO kode",
    400603: "JATO kode",
    400604: "JATO kode",
    400607: "JATO kode",
    400608: "JATO kode",
    400702: "JATO kode",
    406502: "JATO kode",
    407403: "JATO kode",
    407407: "JATO kode",
    407501: "JATO kode",
    408702: "JATO kode",
    408703: "JATO kode",
    415303: "JATO kode",
    420602: "JATO kode",
    420603: "JATO kode",
    424102: "JATO kode",
    428001: "JATO kode",
    500022: "Segment"
  }