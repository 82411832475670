import React from "react"
import { Colors } from "@flow/style";

const Pencil = () => (
  <div style={{
    width: "14px",
    height: "14px",
    marginBottom: "8px"
  }}>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 117.74 122.88" 
  >
    <path
      fill={Colors.Grey2}
      d="M94.62,2c-1.46-1.36-3.14-2.09-5.02-1.99c-1.88,0-3.56,0.73-4.92,2.2L73.59,13.72l31.07,30.03l11.19-11.72 c1.36-1.36,1.88-3.14,1.88-5.02s-0.73-3.66-2.09-4.92L94.62,2L94.62,2L94.62,2z M41.44,109.58c-4.08,1.36-8.26,2.62-12.35,3.98 c-4.08,1.36-8.16,2.72-12.35,4.08c-9.73,3.14-15.07,4.92-16.22,5.23c-1.15,0.31-0.42-4.18,1.99-13.6l7.74-29.61l0.64-0.66 l30.56,30.56L41.44,109.58L41.44,109.58L41.44,109.58z M22.2,67.25l42.99-44.82l31.07,29.92L52.75,97.8L22.2,67.25L22.2,67.25z"
    />
  </svg>
</div>
)

export default Pencil