import React from "react";
import styled from "styled-components";
import { Colors } from "@flow/style";
import { stepMapDescription } from "./CreateCarFilterConfiguration";

export default ({ step, onTypeClick, onMakeClick, onModelClick, onBodyClick, onEngineClick }) => {
  const setStatus = (status, title, onClick) => {
    switch (status) {
      case "current":
        return <CurrentStep>{title}</CurrentStep>;
      case "todo":
        return <ToDoStep>{title}</ToDoStep>;
      case "done":
        return <DoneStep onClick={onClick}>{title}</DoneStep>;
      default:
        break;
    }
  }

  const getHandler = step => {
    switch (step) {
      case "Type":
        return onTypeClick
      case "Merke":
        return onMakeClick
      case "Modell":
        return onModelClick
      case "Karosseri":
        return onBodyClick
      case "Motor":
        return onEngineClick
      default:
        break;
    }
  }

  const previous = Object.keys(stepMapDescription)
    .filter(key => step - key >= 0 && key < step)
    .map(key => stepMapDescription[key])

  const current = stepMapDescription[step]

  const next = Object.keys(stepMapDescription)
    .filter(key => key > step)
    .map(key => stepMapDescription[key])

  return (
    <Navigation>
      {previous && previous.map(s => (
        <>
          {setStatus("done", s, getHandler(s))}
          <Seperator>/</Seperator>
        </>
      ))}
      {current && (
        <>
          {setStatus("current", current, getHandler(current))}
          {next.length > 0 && <Seperator>/</Seperator>}
        </>
      )}
      {next && next.map((s, i) => (
        <>
          {setStatus("todo", s, getHandler(s))}
          {next[i + 1] && <Seperator>/</Seperator>}
        </>
      ))}
    </Navigation>
  );
}

const Navigation = styled.div`
  height: 30px;
  display: flex;
  padding-left: 50px;
  padding-top: 30px;
  padding-bottom: 20px;
`;

const Seperator = styled.p`
  padding-left: 4px;
  padding-right: 4px;
  color: ${Colors.Grey2};
`;

const ToDoStep = styled.p`
  color: ${Colors.Grey2};
`;

const CurrentStep = styled.p`
  color: ${Colors.Coal};
`;

const DoneStep = styled.a`
  color: ${Colors.Sea};
  text-decoration: underline;
  cursor: pointer;
`;
