import React from 'react'
import ReactDOM from 'react-dom'

import { CaseManager, APPS } from '@flow/case-manager'
import * as customerSetup from './customer'
import * as serviceWorker from './serviceWorker'
import CustomRouteCreateNewCarConfiguration from './CustomRouteCreateNewCarConfiguration'
import translatesInbox from './translate/inbox.json'
import translatesSearch from './translate/search.json'
import './index.css'
import CarFilterCustomerMap from './customer/components/CarFilterCustomerMap'
import Car from './customer/components/car-filter-configuration/icons/Car'
import {QueryClient, QueryClientProvider} from 'react-query'

require('@babel/register')
require('babel-polyfill')

const { featureToggles = [] } = window.stacc_env
const customerCarConfigurationEnabled = featureToggles.includes(
  'customerCarConfiguration',
)

const skin = {
  branding: customerSetup.Branding,
  customerIcon: customerSetup.CustomerIcon,
  mainLogo: customerSetup.MainLogo,
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  }
});

const customTranslates = [
  {
    app: APPS.Inbox,
    language: 'no',
    translates: translatesInbox,
  },
  {
    app: APPS.Search,
    language: 'no',
    translates: translatesSearch,
  },
]

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <CaseManager
      configuration={(user) => ({
        skin,
        apps: [
          APPS.Inbox,
          APPS.Search,
          APPS.Process,
          APPS.Decisions,
          APPS.Roles,
        ],
        customComponents: customerSetup.CustomComponents,
        inboxConfiguration: {
          searchFields: customerSetup.SearchFields,
          queuesPollingEnabled: false,
          features: [],
          startableProcesses: [],
        },
        searchConfiguration: {
          searchFields: [
            {
              name: 'Budsjettnummer',
              value: 'data.budgetId',
              queryOperator: 'contains',
            },
            {
              name: 'Firmanavn',
              value: 'data.budgetData.companyName',
              process: ['signing', 'car-configuration'],
              queryOperator: 'contains',
            },
            {
              name: 'Firmanavn',
              value: 'data.budgetData.companyContactName',
              process: ['budget-selection'],
              queryOperator: 'contains',
            },
          ],
          searchResultFields: [
            {
              name: 'Budsjettnummer',
              value: 'data.budgetId',
            },
            {
              name: 'Firmanavn',
              value: 'data.budgetData.companyName',
              process: ['signing', 'car-configuration'],
            },
            {
              name: 'Firmanavn',
              value: 'data.budgetData.companyContactName',
              process: ['budget-selection'],
            },
          ],
          features: [],
        },
        loginConfiguration: {},
        customRoutes: [
          {
            path: '/create-car-configuration',
            component: () => (
              <CustomRouteCreateNewCarConfiguration user={user} />
            ),
          },
          ...(customerCarConfigurationEnabled
            ? [
                {
                  path: '/car-filter',
                  component: () => <CarFilterCustomerMap />,
                  navigation: {
                    title: 'Bilfiltre',
                    icon: () => <Car />,
                    startLink: '/car-filter',
                  },
                },
              ]
            : []),
        ],
        translates: customTranslates,
        language: 'no',
      })}
    />
    ,
  </QueryClientProvider>,
  document.getElementById('root'),
)
serviceWorker.unregister()
