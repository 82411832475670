import axios from "axios";

const generatePostBody = (callType, selections, vehicleType) => {
  const lcvDatabaseName = "NSCN_CS2002";
  const carDatabaseName = "SSCN_CS2002";

  let databaseName;
  switch (vehicleType) {
    case "car":
      databaseName = carDatabaseName;
      break;
    case "lightCommercialVehicle":
      databaseName = lcvDatabaseName;
      break;
    default:
      databaseName = carDatabaseName;
  }

  if (callType !== "vehiclesbuilder") {
    return {
      config: {
        applyInclExcl: false,
        applyInclExclFor: `${callType}`,
        includeImages: true,
        includeTranslations: true,
        includeDetails: false
      },
      input: [
        {
          databaseName,
          selections
        }
      ]
    };
  } else {
    return {
      userDateTime: new Date(),
      benchmarkVehicleId: selections,
      markets: [
        {
          databaseName,
          vehicles: [
            {
              vehicleId: selections
            }
          ]
        }
      ]
    };
  }
};

export const getMakes = (vehicleType, callback) => {
  const body = generatePostBody("makes", [], vehicleType);
  axios
    .post("/api/jato/makes", body)
    .then(res => {
      callback(res.data[0].selections);
    })
    .catch(err => {
      console.debug("Could not fetch makes from jato, returning emptylist");
      callback([]);
    });
};

export const getModels = (make, vehicleType, callback) => {
  const body = generatePostBody("models", [{ make }], vehicleType);
  axios
    .post("/api/jato/models", body)
    .then(res => {
      callback(res.data[0].selections);
    })
    .catch(err => {
      console.debug("Could not fetch models from jato, returning emptylist.");
      callback([]);
    });
};

export const getBodies = (make, model, modelYear, vehicleType, callback) => {
  const selections = [{ make, model, modelYear }];
  const body = generatePostBody("bodies", selections, vehicleType);

  axios
    .post("/api/jato/bodies", body)
    .then(res => {
      callback(res.data[0].selections);
    })
    .catch(err => {
      console.debug("Could not fetch bodies from jato, returning emptylist");
      callback([]);
    });
};

export const getEngines = (make, model, modelYear, bodyCode, doors, drivenWheels, vehicleType, callback) => {
  const selections = [{ make, model, modelYear, bodyCode, doors, drivenWheels }];
  const body = generatePostBody("vehicles", selections, vehicleType);

  axios
    .post("/api/jato/vehicles", body)
    .then(res => {
      callback(res.data[0].selections);
    })
    .catch(err => {
      console.debug("Could not fetch engines from jato, returning emptylist");
      callback([]);
    });
};

export const getVehiclesBuilder = (vehicleId, vehicleType, callback) => {
  const body = generatePostBody("vehiclesbuilder", vehicleId, vehicleType);
  axios
    .post("/api/jato/vehiclesbuilder", body)
    .then(res => {
      callback(res.data);
    })
    .catch(err => {
      console.debug("Could not fetch vehiclesbuilder from jato, returning null");
      callback(null);
    });
};

export const getStandardEquipment = (vehicleId, callback) => {
  axios
    .post("/api/jato/standardEquipment?mapped=true", {
      vehicleId,
      "languageId": "23" //Norsk
    })
    .then(res => {
      callback(res.data);
    })
    .catch(err => {
      console.debug("Could not fetch standardEquipment from jato, returning empty list");
      callback([]);
    });
};
